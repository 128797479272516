import React, { Component } from 'react';
import { Input } from 'reactstrap';
import '../Hamburger.css';
import './break.css';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);

export default class SessionItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hover: "",
            isLive: "",
            checked: false,
        }
        if (props.item.isLive === true) {
            this.state.isLive="live-border"
        }
        if (props.item.saleId !== "") {
            this.state.checked=true
        }
    }
    mouseEnter = () => {
        this.setState({hover: "darken "})
    }
    mouseLeave = () => {
        this.setState({hover: ""})
    }
    getCheckBox = () => {
        if (this.state.checked) {
            return (
                <Input type="checkbox" checked disabled={true} />
            )
        }
        return(
            <Input type="checkbox"  disabled={true} />
        )
    }
    getDate = (dt) => {
        let date = new Date(dt)
        return "Session on " +(date.getMonth()+1)+"/"+date.getDate()+"/"+ date.getFullYear()
    }
    render() {
        let item = this.props.item
        let id = item.id
        return (
            <tr key={id+"P"} 
                value={id} 
                onClick={() => this.props.goToSession(id, item)} 
                className={this.state.hover +"clickable " +this.state.isLive}
                onMouseEnter={this.mouseEnter}
                onMouseLeave={this.mouseLeave}
            >
                <td >{id}</td>
                <td>{this.getDate(item.date)}</td>
                <td>{item.salesRep}</td>
                <td>{this.getCheckBox()}</td>
            </tr>
        )
    }

}