import React, { useEffect, useState } from "react";
import InfoGrid from "./InfoGrid";
import RepackTabs from "./RepackTabs";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import mockData from "./MockData";

interface RepackTypeCounts {
  [key: string]: number; 
}

const RepackViewer: React.FC = () => {
  const [headerVars, setHeaderVars] = useState({
    totalRepacks: 0,
    typeCounts: {} as RepackTypeCounts 
  });

  useEffect(() => {
    
    const counts = mockData.reduce((acc, repack) => {
      acc.totalRepacks++;
      const { type } = repack;

      if (!acc.typeCounts[type]) {
        acc.typeCounts[type] = 1; 
      } else {
        acc.typeCounts[type]++; 
      }

      return acc;
    }, { totalRepacks: 0, typeCounts: {} as RepackTypeCounts });

    setHeaderVars(counts);
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4>{"Repacks"}</h4>
        <Button variant="contained" component={Link} to="/dashboard/repack/create">
          Create a Repack
        </Button>
      </div>
      <InfoGrid
        totalRepacks={headerVars.totalRepacks}
        typeCounts={headerVars.typeCounts}
      />
      <RepackTabs repacks={mockData} />
    </div>
  );
};

export default RepackViewer;
