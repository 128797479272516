import React, { Component } from 'react';
import {useNavigate} from "react-router-dom";
import { Button, Input, InputGroup, Table} from 'reactstrap';
import FetchModule from "../../Modules/FetchModule";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);
const fetchData = new FetchModule();


export default class PantheonBuilderMain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuChanged: "",
            menuOpened: false,
            sessions: null,
        }
        localStorage.getItem('user')
    }

    async componentDidMount() {
        const response = await fetchData.fetchResponseAuthed("/Pantheon/get/series", "GET")
        if (response.status == 200) {
            const data = await response.json()
            this.updateState("sessions", data)
        }
    }

    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    onClick = (event) => {
        this.props.navigate("/dashboard/pantheon/series/"+event.target.value+"/create")
    }
    getDisplayItem = (product) => {
        var session = this.state.sessions.series[product.id]
        return (
            <tr key={product.id}>
                <td>{session.id}</td>
                <td>{product.name}</td>
                <td>
                    <Button color="primary"
                        onClick={this.onClick}
                        value={this.state.sessions.series[product.id].id}
                    >GO</Button>
                </td>
            </tr>
        )
    }
    getData = () => {
        if (this.state.sessions != null) {
            var prods = this.state.sessions.products
            return prods.map(product => this.getDisplayItem(product))
        }
    }
    render() {
        return (
                <div style={{
                    height: '98%',
                    margin: '0.5em'
                }}>
                    <div className="card-header">
                        Pantheon Series:
                    </div>
                    <div className="card-body">
                        <InputGroup className="whole-search" >
                            <div className="fit-search search-bar">
                                <Input className="fit-search" id="searchText" 
                                    value={this.state.searchText}
                                    onChange={this.textChange}
                                    placeholder="Search"
                                />
                                <FontAwesomeIcon onClick={this.clearText} className="search-box-clear" icon="xmark" />
                            </div>
                        </InputGroup>
                         <Table className="fixTable" style={{height: '92%'}}>
                            <thead>
                                <tr className="thead-fix">
                                    <th>Series</th>
                                    <th>Product</th>
                                    <th>GO</th>
                                </tr>
                            </thead>
                            <tbody className="tableBody">
                                {this.getData()}
                            </tbody>
                        </Table>
                    </div>
                </div>
        )
    }

}
