import FetchModule from "../../Modules/FetchModule";
import React from "react";

const fetchData = new FetchModule();

export default function useFetchPromise() {
  return React.useCallback(
    (link, type, data) =>
      new Promise((resolve, reject) => {
        fetchData.muiFetch(link, type, data).then((response) => {
          if (response.status === 200) {
            resolve(response);
          } else {
            response.text().then((text) => {
              console.log("Error when saving", text);
              reject(new Error(`${text || response}`));
            });
          }
        });
      }),
    []
  );
}