import PantheonMainPage from "../Dash/PantheonPage/PantheonMainPage"
import { Box, Card, Container, Grid } from '@mui/material';
import { DashboardLayout } from '../Components/DashboardComponents/DashboardLayout';




export const PantheonPage = (props) => {



    return (
        <Box sx={{
            width: '100%', 
            height: '100%', 
            }}>
            <PantheonMainPage type={props.type}/>
                    
        </Box>

    )
}