
import React, { Component, useContext, useEffect, } from 'react';
import {useLocation} from "react-router-dom";

import { useLocalStorage } from '../../../Utilities/useLocalStorage';
import InnerSidebarItem from './InnerSidebarItem';
import { NavigateContext } from "../../../Pages/ReactContexts/NavigateContext";

const Observer = ({value, didUpdate}) => {
    const location = useLocation();
    useEffect (() => {
        didUpdate(location)
    }, [location])
    return null;
}

export default function InnerSidebar(props) {
    const {items, defaultPageValue, pageLocalstorageKey} = props
    const [activePage, setActive] = useLocalStorage(pageLocalstorageKey, defaultPageValue)
    const {navigate} = useContext(NavigateContext)
    if(items[activePage] === undefined) {
        navigate(defaultPageValue)
        setActive(defaultPageValue)
    }
    function getBody() {
        if(items[activePage] !== undefined) {
            return (
                <>
                {items[activePage].element}
                </>
            )
        }
    }
    
    function getActive (val) {
        return activePage == val ? "active" : ""
    }
    function updated(thing) {
        let lis = thing["pathname"].split("/")
        setActive(lis[lis.length-1])
    }
    return (        
        <div className={"dashboard-page-flip"}>
            <Observer value={activePage} didUpdate={updated}/>
            <div className="dashboard-nav topbar" />
            <div className="dashboard-nav-flip" >
                <div className="dashboard-nav-left-flip">
                    {Object.values(items).map((item) => (
                        <InnerSidebarItem 
                            id={item.name}
                            key={item.name}
                            label={item.label}
                            getActive={getActive}
                        />
                    ))}
                </div>
                <div className="dashboard-nav-right-flip">
                    {getBody()}
                </div>
            </div>
        
        </div>
    )
}