import React, { Component } from 'react';
import '../../Hamburger.css';
import NewPantheon from './NewPantheon';
import LeftMenu from '../LeftMenu/LeftMenu'
import BoxesPantheon from './BoxesPantheon';

const navItems = [
    {
        name: "Boxes",
        link: "boxes"
    },
    {
        name: "Create New",
        link: "create"
    },
    //{
    //    name: "Settings",
    //    link: "settings"
    //}
]

export default class PantheonBuilderMain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuChanged: "",
            menuOpened: false,
            series: 0,
            activePage: "create"
        }
        let path = window.location.pathname
        let id = path.split('/')
        this.state.series = id[4]
        localStorage.getItem('user')
    }

    async componentDidMount() {
    }

    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    getBody = () => {
        
        if (this.state.activePage === "boxes") {
            return (
                <BoxesPantheon
                    series={this.state.series}
                />
            )
        }
        return (
            <NewPantheon 
                series={this.state.series}
            />
        )
    }
    render() {
        return (
            <LeftMenu 
                getBody={this.getBody}
                navItems={navItems}
                activePage={this.state.activePage}
                backPage="/dashboard/pantheon/all"
                updateState={this.updateState}
                location={4}
                navigate={this.props.navigate}
            />
        )
    }

}
