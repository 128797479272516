interface Visibility {
  id: boolean;
  player: boolean;
  purchase: boolean;
  received: boolean;
  year: boolean;
  manufacturer: boolean;
  series: boolean;
  setVariety: boolean;
  attributes: boolean;
  serial: boolean;
  grade: boolean;
  cardNumber: boolean;
  league: boolean;
  cost: boolean;
  fees: boolean;
  gradingFees: boolean;
  totalCost: boolean;
  comp: boolean;
  vendor: boolean;
  onFloor: boolean;
  sku: boolean;
  name: boolean;
  initials: boolean;
  status: boolean;
  isDear: boolean;
  gradeRec: boolean;
  gradeSent: boolean;
  purchasedBy: boolean;
  certNumber: boolean;
  inventoryId: boolean;
  description: boolean;
}

interface MasterDefault {
  order: string[];
  visibility: Visibility;
}

export default function MasterDefault(): MasterDefault  {
  return{
    order: [
      "inventoryId", "purchase", "received", "player", "year",
      "manufacturer", "series", "setVariety", "attributes", "serial", "grade",
      "cardNumber", "league", "cost", "fees", "gradingFees", "totalCost", "comp",
      "vendor", "initials", "sku", "name", "status", "isDear"
    ],
    visibility: {
      id: false,
      player: true,
      purchase: true,
      received: true,
      year: true,
      manufacturer: true,
      series: true,
      setVariety: true,
      attributes: true,
      serial: true,
      grade: true,
      cardNumber: true,
      league: true,
      cost: true,
      fees: true,
      gradingFees: true,
      totalCost: true,
      comp: true,
      vendor: true,
      onFloor: false,
      sku: true,
      name: true,
      initials: true,
      status: true,
      isDear: true,
      gradeRec: false,
      gradeSent: false,
      purchasedBy: false,
      certNumber: false,
      inventoryId: true,
      description: false,
    },
  }
};
