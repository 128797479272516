import React, { Component } from 'react';
import {Nav, NavItem, InputGroup, Input, NavLink, Row, Table,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, Label
} from "reactstrap";
import Product from './Product';
import './product.css';
import './horizontalScroll.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";

import {faChevronRight, faChevronLeft, faXmark} from '@fortawesome/free-solid-svg-icons';

import FetchModule from "../Modules/FetchModule";
//const dataFetch = require("../Modules/FetchData");
const api = require("../Modules/APIModules");
const fetchData = new FetchModule();
library.add(faChevronLeft, faChevronRight, faXmark);


export default class Navigation extends Component {
    constructor(props) {
        super(props) 
        this.state = {
            sports: [],
            leagues: [],
            leagueCheckBoxes: [],
            activeTab: "All",
            activeTabId: 1,
            nameDisplay: "none",
            priceDisplay: "none",
            releaseDisplay: "none",
            currentDropdown: "Name",
            displayState: 2,
            products: [],
            productsToShow: [],
            searchText: "",
            dropDownShow: false,
            boxChecked: true,
            caseChecked: true,
            supplyChecked: true,
            scrollAmount: 0,
            leftPaddle: "left-paddle paddle hidden",
            rightPaddle: "right-paddle paddle",
        }
    }

    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    // Sets up all the data that is used for the application.
    async componentDidMount() {
        // Sets up products
        const response = await fetchData.fetchResponse(this.props.link); // Uses helper method to call our API.
        const data = await response.json();
        data.forEach(prod => {
            let name = prod.name;
            if (name.includes("PREORDER")) {
                name = name.replace("PREORDER ", "") + " PREORDER"
            };

            if (prod.release != "") {
                var today = new Date();
                var day = today.getDate()
                var month = today.getMonth()+1
                var year = today.getFullYear().toString().slice(-2)
                if (day < 10) {
                    day = "0"+day.toString()
                }
                if (month < 10 ) {
                    month = "0" + month.toString()
                }
                var todayDate = year +month +day
                var dates = prod.release.split("/")
                var newDate = new Date(20+dates[2], dates[0], dates[1]) // year, month, day
                var newDates = dates[2] + dates[0] + dates[1];
                if(parseInt(newDates) < parseInt(todayDate)) {
                    prod.release = "";
                }
            }
            prod.name = name;
        })
        this.setState({products: data});
        // Sets up leagues
        const leagueResponse = await fetch(api.getLink() +'/Product/leagues')
        const leagueData = await leagueResponse.json();
        let leagueBoxes = {};
        let leagueMap = {};
        leagueData.map(league => {
            if (league.amount > 0) {
                leagueBoxes[league.name] = true;
                leagueMap[league.name] = league
                leagueMap[league.name]["display"] = true
            }
        });
        this.setState({leagueCheckBoxes: leagueBoxes})
        this.setState({leagues: leagueMap})
        // Set up the different sports
        const sportResponse =await fetch(api.getLink() +"/Product/sports")
        const sportData = await sportResponse.json();
        let sportStuff = {};
        sportStuff["All"] = {
            name: "All",
            amount: 1,
            leagues: ["all"],
            leagueAmonut: 1,
            checked: true,
            active: true,
        }
        sportData.map(sport => {
            sport["leagues"] = [];
            sportStuff[sport.name] = sport
            sportStuff[sport.name]["amount"] = 0
            sportStuff[sport.name]["leagueAmount"] = 0
            sportStuff[sport.name]["checked"] = true
            sportStuff[sport.name]["active"] = false
        })
        leagueData.forEach(league => {
            sportStuff[league.sport].leagues.push(league.name)
            sportStuff[league.sport]["amount"] = sportStuff[league.sport]["amount"] + league["amount"]
            sportStuff[league.sport]["leagueAmount"] = sportStuff[league.sport]["leagueAmount"] + 1
        })
        this.setState({sports: sportStuff}, () => {
            let sports = (Object.values(this.state.sports))
            sports.forEach(sport => {
                if (sport.amount > 0) {
                let na = sport.name
                data.push({
                    name: "",
                    key: sport.name,
                    price: "",
                    release: "",
                    league: "none",
                    priority: sport.priority
                })
            }
            })
        })
        var copiedProducts = [...this.state.products]
        copiedProducts.forEach(product=>{

            if(product.league === "Multi-Sport"){
                product.priority = sportData.filter(sport=>sport.name==="Multi")[0].priority

            }
            else{
                const foundSport = sportData.filter((l)=>{
                    return l.leagues.includes(product.league)
                })
                if(foundSport.length>0){
                    product.priority = foundSport[0].priority
                }
                else{
                    product.priority = 999
                }
            }

            
            
            
        })
        this.setState({products: copiedProducts})

        this.updateProductDisplay(this.state.searchText, this.state.currentSortBy)
    }
    // Logic for when a sport tab is changed.
    changeTab = (event) => {
        let sport = this.state.sports
        sport[this.state.activeTab].active = false
        this.setState({sports: sport})
        this.updateState("activeTab", event.target.id, () => {
            this.state.sports[event.target.id].active = true
            this.setState({activeTabId: sport[event.target.id].priority})
            if(sport != "All") {
                let leagues = sport[event.target.id].leagues
                let checkLeagues = this.state.leagueCheckBoxes            
                leagues.forEach(league => {
                    checkLeagues[league] = true
                })
                this.setState({leagueCheckBoxes: checkLeagues}, () =>{
                    this.updateProductDisplay(this.state.searchText, this.state.currentSortBy)
                })
            }
        })
    }
    // Displays a single sport nav
    displaySport =(sport) => {
        return (
            <NavItem className="hover" 
            key={sport.name}>
                <NavLink id={sport.name} 
                    active={this.state.sports[sport.name].active} 
                    onClick={this.changeTab}>{sport.name}
                </NavLink>
            </NavItem>
        );
    }
    // Displays the sport navs
    displaySportNavs = () => {
        let sports = (Object.values(this.state.sports))
        return sports.map(sport => this.displaySport(sport));
    
    }
    // The sort changes for the headers of the table.
    sortChange = (event) => {
        if (event.target.id == "nameDisplay" || event.target.id == "priceDisplay" || event.target.id == "releaseDisplay" ) {
            this.setState({currentSortBy: event.target.id})
            this.setState({nameDisplay: "none"})
            this.setState({releaseDisplay: "none"})
            this.setState({priceDisplay: "none"})
            if (event.target.id != this.state.currentSortBy) {
                this.setState({displayState: 0}, () => {
                    this.updateProductDisplay(this.state.searchText, event.target.id);
                    this.updateState(event.target.id, "down")
                })
            } else {
                this.setState({displayState: (this.state.displayState + 1) % 3}, () => {
                    this.updateProductDisplay(this.state.searchText, event.target.id);
                    if (this.state.displayState == 0) {
                        this.updateState(event.target.id, "down")
                    } else if (this.state.displayState == 1) {
                        this.updateState(event.target.id, "up")
                    } else {
                        this.updateState(event.target.id, "none")
                    }
                })
            }
        }
    }
    // Helper function to check if a league exists
    leagueExists = (element) => {
        if (this.state.leagues[element] == undefined || this.state.leagueCheckBoxes[element] == false) return false;
    
        if (this.state.activeTab == "All") return true
        return this.state.leagues[element].sport == this.state.activeTab;
    }
    // The Sorting/Searching/Filtering 
    // Updates based on the current filter choice, which products to show
    // All in real time!
    updateProductDisplay = (text, tarId) => {
            this.setState({productsToShow:
                this.state.products
                    .filter(product => {
                        if(this.state.activeTab != "All" && product.name == "") return;
                        if(!this.state.boxChecked && (product.name == "" || product.type.toLowerCase().includes("box"))) return;
                        if(!this.state.caseChecked && (product.name == "" || product.type.toLowerCase().includes("case"))) return;
                        if(!this.state.supplyChecked && product.type == "Supplies") return;

                        if(this.state.displayState == 2) {
                            if(this.state.sports[product.key] != undefined){
                                if(this.state.sports[product.key].leagueAmount > 0) return product;
                            }
                            else if(this.leagueExists(product.league || product.name== "") )return product;
                        }
                        else if (this.leagueExists(product.league)) return product;
                    })
                    .filter(product => {
                        var containsAll = true;
                        text.split(' ').forEach(word => {
                            if (!product.name.toLowerCase().includes(word.toLowerCase())) {
                                containsAll = false;
                            }
                        })
                        return containsAll ? product : null;
                    })
                    .sort((a, b) => {
                        if (this.state.displayState == 0) {
                            if(tarId == "priceDisplay") { // Descendings
                                return b.price - a.price
                            } 
                            let x = tarId == "nameDisplay" ? a.name : a.release +" " +a.name
                            let y = tarId == "nameDisplay" ? b.name : b.release +" " + b.name
                            return x == y ? 0 : x < y ? 1 : -1
                        } else if(this.state.displayState == 1) { //Ascending
                            if(tarId == "priceDisplay") {
                                return a.price - b.price
                            } 
                            let x = tarId == "nameDisplay" ? a.name : a.release +" " +a.name
                            let y = tarId == "nameDisplay" ? b.name : b.release +" " +b.name
                            return x == y ? 0 : x > y ? 1 : -1
                        } // No sort involved.

                        else if(a.name === "" || b.name === ""){
                            return a.name === ""? -1:1
                        }
                        
                        let x = a.priority 
                        let y = b.priotity
                        return x == y ? 0 : x > y ? 1 : -1;
                    })
            })
        }
    // Displays a single product within the table
    displayProduct = (product) => {
        if(product["name"] == "" && this.state.sports[product.key].leagueAmount > 0) {
            return (
                <Product product={product} price="" key={product["key"]}/>
            );
        } else if (product["name"] != "") {
        return (
            <Product product={product} key={product["name"]}/>
        );
        }
    }
    // Gets all the products that need to be shown in the table
    getData = () => {
        return this.state.productsToShow.map(product => this.displayProduct(product))
    }
    // When text is changed, this fires.
    textChange = (event) => {
        this.setState({searchText: event.target.value})
        this.updateProductDisplay(event.target.value, this.state.currentSortBy)
    }
    // Whenever a league is selected/unselected in the code this
    // Is what changes
    leagueChange = (league, leagues, sports, val) => {
        if (val) {
            sports[this.state.leagues[league]["sport"]]["leagueAmount"] =
            sports[this.state.leagues[league]["sport"]]["leagueAmount"] +1
        } else {
            sports[this.state.leagues[league]["sport"]]["leagueAmount"] =
            sports[this.state.leagues[league]["sport"]]["leagueAmount"] -1
            if (sports[this.state.leagues[league]["sport"]]["leagueAmount"] < 0) {
                sports[this.state.leagues[league]["sport"]]["leagueAmount"] = 0
            }
        }
        leagues[league] = val
    }
    // Checks if a change to the checkboxes was made
    checkChange =(event) => {
        const leagues = this.state.leagueCheckBoxes
        const sports = this.state.sports
        this.leagueChange(event.target.value, leagues, sports, !leagues[event.target.value])
        this.setState({leagueCheckBoxes: leagues}, () => {
            this.updateProductDisplay(this.state.searchText, this.state.currentSortBy)
        })
    }
    // Gets all the leagues for a particular sport.
    getLeagues = (league) => {
        if (this.state.leagues[league].amount > 0 && league != "Supplies") {
            return (
                <Label key={(Math.random() + 1).toString(36).substring(7)} 
                       check>
                    <Input 
                        key={(Math.random() + 1).toString(36).substring(7)}
                        checked={this.state.leagueCheckBoxes[league]} 
                        value={league} 
                        onChange={this.checkChange} type="checkbox"/>
                        {league}
                </Label>
            )
        }
    }
    // Handles the scrolling for the list of sports when in a smaller window
    // Has dynamic css paddle with some arrows for indication that it is scrollable.
    handleScroll = (event) => {
        const scrollX = window.scrollX
        const element = event.target
        if(element.scrollLeft < 10) { // element is all the way to the left
            this.setState({leftPaddle: "left-paddle paddle hidden"})
            this.setState({rightPaddle: "right-paddle paddle"})
        } else if (element.scrollWidth -element.scrollLeft < element.clientWidth + 10) { // element is to the right
            this.setState({leftPaddle: "left-paddle paddle"})
            this.setState({rightPaddle: "right-paddle paddle hidden"})
        } else {
            this.setState({leftPaddle: "left-paddle paddle"})
            this.setState({rightPaddle: "right-paddle paddle"})
        }
    }
    // Just setups all the league displays for the navbar.
    getDisplayLeagues = () => {
        if(this.state.activeTab != "All")
            return this.state.sports[this.state.activeTab].leagues.map(league => this.getLeagues(league))

    }
    // Helper function that is used to show or not show the dropdown for the filters.
    toggle = () => {
        this.setState({dropdownShow: !this.state.dropdownShow})
    }
    checkboxChange = (event) => {
        this.updateState(event.target.value, !this.state[event.target.value], () => {
            this.updateProductDisplay(this.state.searchText, this.state.currentSortBy)
        })
    }
    // Clears the text on the search bar
    clearText = () => {
        this.setState({searchText: ""}, () =>{
            this.updateProductDisplay(this.state.searchText, this.state.currentSortBy);
        })
    }
    render() {
        return (
        <div className="dashBody">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <InputGroup className="whole-search" >
                    <Dropdown
                        className="fit-search"
                        isOpen={this.state.dropdownShow}
                        toggle={this.toggle}
                    >
                    <DropdownToggle caret><i className="fa fa-filter"/></DropdownToggle>
                        <DropdownMenu >
                            <DropdownItem header>Filters</DropdownItem>
                            <Container>
                                <Row>
                                    <Label key="box" check>
                                    <Input checked={this.state.boxChecked} 
                                        value={"boxChecked"}
                                        onChange={this.checkboxChange} 
                                        type="checkbox"/>
                                        Boxes
                                    </Label>

                                    <Label key="case" check>
                                    <Input checked={this.state.caseChecked} 
                                        value={"caseChecked"}
                                        onChange={this.checkboxChange} 
                                        type="checkbox"/>
                                        Cases
                                    </Label>
                                    <Label key="supplies" check>
                                    <Input checked={this.state.supplyChecked} 
                                        value={"supplyChecked"}
                                        onChange={this.checkboxChange} 
                                        type="checkbox"/>
                                        Supplies
                                    </Label>
                                    {this.getDisplayLeagues()}
                                </Row>
                            </Container>
                        </DropdownMenu>
                    </Dropdown>
                    <div className="fit-search search-bar">
                        <Input className="fit-search" id="searchText" 
                            value={this.state.searchText}
                            onChange={this.textChange}
                            placeholder="Search"
                        />
                          <FontAwesomeIcon onClick={this.clearText} className="search-box-clear" icon="xmark" />
                    </div>

                </InputGroup>
            <div className="scroll-wrapper">
                <Nav tabs className="xscroll" onScroll={this.handleScroll}>
                    {this.displaySportNavs()}
                </Nav>
                <div className="paddles">
                    <FontAwesomeIcon className={this.state.rightPaddle} icon="chevron-right" />
                    <FontAwesomeIcon className={this.state.leftPaddle} icon="chevron-left" />
                </div>
            </div>
            <Table className="fixTable" onClick={this.sortChange} size="sm" striped bordered
                style={{marginBottom: 0}}
            >
                <thead className="thead-fix" >
                    <tr>
                        <th 
                            className="fixedName"
                            id={"nameDisplay"}
                            style={{cursor: 'pointer'}}>
                                Name&nbsp;
                                <i  className={"arrow " +this.state.nameDisplay} />
                        </th>
                        <th 
                            className="fixedRelease"
                            id={"releaseDisplay"} 
                            style={{cursor: 'pointer'}}>
                                Release&nbsp;                
                                <i  className={"arrow " +this.state.releaseDisplay} />
                        </th>
                        <th id={"priceDisplay"}  
                            className="fixedPrice"
                            style={{cursor: 'pointer'}}>
                                Price&nbsp;
                                <i  className={"arrow " +this.state.priceDisplay} /> 
                        </th>
                    </tr>
                </thead>
                <tbody className="tablebody">
                    {this.getData()}
                </tbody>
            </Table>
        </div>
        )

    }


}