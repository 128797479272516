import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { Input, InputGroup, Dropdown, DropdownToggle, Table,
    Modal, ModalHeader, ModalBody, ModalFooter, Label,
} from 'reactstrap';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams  } from '@mui/x-data-grid';
import '../Hamburger.css';
import '../ReportManager/ProductList.css';
import FetchModule from "../../Modules/FetchModule";
import {
    GridRowModes,
    DataGridPro,
    GridToolbarContainer,
    GridActionsCellItem,
  } from '@mui/x-data-grid-pro';
import { Card } from '@mui/material';

  const fetchData = new FetchModule();

    function EditToolbar(props) {
        const { setRows, setRowModesModel } = props;
      
        const handleClick = () => {
          const id = 4;
          setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
          setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
          }));
        };
      
        return (
          <GridToolbarContainer>

          </GridToolbarContainer>
        );
      }
      
      EditToolbar.propTypes = {
        setRowModesModel: PropTypes.func.isRequired,
        setRows: PropTypes.func.isRequired,
      };
export default function PackingPageMain(props) {
    const [menuOpen, changeMenu] = React.useState([]);
    const [boxes, setBoxes] = React.useState([])
    const [isBoxModalOpen, setBoxModalState] = React.useState(false)
    const [rows, setRows] = React.useState([])
    const [name, setName] = React.useState("")
    const [length, setLength] = React.useState(0)
    const [width, setWidth] = React.useState(0)
    const [height, setHeight] = React.useState(0)
    const [weight, setWeight] = React.useState(0)
    const [maxWeight, setMaxWeight] = React.useState(0)
    const [quantity, setQuantity] = React.useState(0)
    const [steps, setSteps] = React.useState(0)
    const [stepAmt, setStepAmt] = React.useState(0)
    const [disabled, setDisabled] = React.useState(0)


    React.useEffect(async function loadRows() {
        if(rows.length == 0) {
          var response = await fetchData.fetchResponseAuthed("/BinPacking/box/all", "GET")
          .then((res) => res.json())
          .then((data) => {
            return data
        });
            setBoxes(response)
          setRows(Object.values(response))
        }
      }, [])

    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowEditStart = (params, event) => {
      event.defaultMuiPrevented = true;
    };
  
    const handleRowEditStop = (params, event) => {
      event.defaultMuiPrevented = true;
    };
  
    const handleEditClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
  
    const handleSaveClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };
  
    const handleDeleteClick = (id) => () => {
        deleteBox(id)
      setRows(rows.filter((row) => row.id !== id));
    };
    async function saveProduct (box) {
        const response = await fetchData.promise('/BinPacking/box/update', true, "PUT", box)
        if (response.status == 200) {
            let data = await response.json()
            let newBoxes = boxes
            newBoxes[box.id] = data;
            setBoxes(boxes)
            return true;
        }
        return false;
    }
    async function saveNewBox() {
        
    }
    const handleCancelClick = (id) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    
        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
          setRows(rows.filter((row) => row.id !== id));
        }
    };
    const columns = [
        { field: 'name',
            headerName: 'Name',
            width: 150,
            editable: false,
            type: 'string'
        },
        { field: 'length',
            headerName: 'Length (in)',
            width: 100,
            editable: true,
            type: 'number'
        },
        { field: 'width',
            headerName: 'Width (in)',
            width: 100,
            editable: true,
            type: 'number'
        },
        { field: 'height',
            headerName: 'Height (in)',
            width: 100,
            editable: true,
            type: 'number'
        },
        { field: 'weight',
            headerName: 'Weight (oz)',
            width: 100,
            editable: true,
            type: 'number'
        },
        { field: 'quantity',
            headerName: 'Quantity',
            width: 100,
            editable: true,
            type: 'number'
        },
        { field: 'steps',
            headerName: 'Steps',
            width: 100,
            editable: true,
            type: 'number'
        },
        { field: 'stepAmt',
            headerName: 'Step amount',
            width: 100,
            editable: true,
            type: 'number'
        },
        { field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
              const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      
              if (isInEditMode) {
                return [
                  <GridActionsCellItem
                    icon={<SaveIcon />}
                    label="Save"
                    onClick={handleSaveClick(id)}
                  />,
                  <GridActionsCellItem
                    icon={<CancelIcon />}
                    label="Cancel"
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                  />,
                ];
              }
      
              return [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(id)}
                  color="inherit"
                />,
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={handleDeleteClick(id)}
                  color="inherit"
                />,
              ];
            },
          },
    ]
    const processRowUpdate = (newRow) => {
        if (saveProduct(newRow)) {
            newRow.name = newRow.length +"x"+newRow.width +"x" +newRow.height
            const updatedRow = { ...newRow, isNew: false };
            setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
            return updatedRow;
        }
      };
      async function deleteBox(boxId) {
        if (boxes.hasOwnProperty(boxId)) {
            let response = await fetchData.promise("/BinPacking/box/delete/"+boxId, true, 'DELETE')
            if (response.status == 200) {
                let newBoxes = boxes
                delete boxes[boxId];
                setBoxes(newBoxes);
                setRows(Object.values(newBoxes))
            }
        }
        return;
    }

    async function componentDidMount() {
        
    }
    function toggleOpenModal () {
        setBoxModalState(!isBoxModalOpen)
    }
    async function newBox() {
        if (!disabled) {
            setDisabled(true)
            let box = {
                name: length +"x"+width+"x"+height,
                length: length,
                width: width,
                height: height,
                weight: weight,
                quantity: quantity,
                steps: steps,
                stepAmt: stepAmt
            }
            let response = await fetchData.promise("/BinPacking/box/new", true, "POST", box)
            if (response.status == 200) {
                let myNewBox = await response.json()
                if (box != null) {
                    var id = myNewBox["id"]
                    setRows((oldRows) => [...oldRows, { ...myNewBox, isNew: false }]); 
                    setBoxModalState(false)
                }
            }
    
            setDisabled(false)
        }
    }

    function getBody() {
        return (
            <Card sx={{
                width: '100%',
                height: '100%'
            }}>
                <Button color="primary" startIcon={<AddIcon />} onClick={toggleOpenModal}>
              Add record
            </Button>
                <Box sx={{height: 400, width: '100%'}}>
                    <DataGridPro
                        pagination={true}
                        editMode="row"
                        rows={rows}
                        columns={columns}
                        experimentalFeatures={{newEditingApi: true}}
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        componentsProps={{
                            toolbar: { setRows, setRowModesModel },
                        }}
                        components={{
                            Toolbar: EditToolbar,
                        }}
                    />
                </Box>
                <Dialog onClose={toggleOpenModal} open={isBoxModalOpen} size="xl">
                            <ModalHeader toggle={toggleOpenModal}>New Box</ModalHeader>
                                <ModalBody>
                                    <InputGroup>
                                        <Label className="bold">Name:&nbsp;</Label>
                                        <Input value={length+"x"+width+"x"+height}
                                            statevalue='name'
                                            disabled={true}
                                            type="text"
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Label className="bold">Length:&nbsp;</Label>
                                        <Input value={length}
                                            statevalue='length'
                                            type="number"
                                            onChange={(e ) => {setLength(e.target.value)}}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Label className="bold">Width:&nbsp;</Label>
                                        <Input value={width}
                                            statevalue='width'
                                            type="number"
                                                onChange={(e ) => {setWidth(e.target.value)}}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Label className="bold">Height:&nbsp;</Label>
                                        <Input value={height}
                                            statevalue='height'
                                            type="number"
                                            onChange={(e ) => {setHeight(e.target.value)}}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Label className="bold">Weight:&nbsp;</Label>
                                        <Input value={weight}
                                            statevalue='weight'
                                            type="number"
                                            onChange={(e ) => {setWeight(e.target.value)}}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Label className="bold">Quantity:&nbsp;</Label>
                                        <Input value={quantity}
                                            statevalue='quantity'
                                            type="number"
                                            onChange={(e ) => {setQuantity(e.target.value)}}
                                        />
                                    </InputGroup>
                                    <h5>Optional:</h5>     
                                    <InputGroup>
                                        <Label className="bold">Steps:&nbsp;</Label>
                                        <Input value={steps}
                                            statevalue='steps'
                                            type="number"
                                            onChange={(e ) => {setSteps(e.target.value)}}
                                        />
                                    </InputGroup>    
                                    <InputGroup>
                                        <Label className="bold">Step Amt:&nbsp;</Label>
                                        <Input value={stepAmt}
                                            statevalue='stepAmt'
                                            type="number"
                                            onChange={(e ) => {setStepAmt(e.target.value)}}
                                        />
                                    </InputGroup>              
                                </ModalBody>
                            <ModalFooter>
                                <Button onClick={newBox} disabled={disabled}>Add</Button>
                            </ModalFooter>
                        </Dialog>
            </Card>
        )
    }

    return (
        <div >
            {getBody()}
        </div>
    )
}