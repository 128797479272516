import React, { useState, useEffect } from "react";
import FetchModule from "../../../Modules/FetchModule";
import MultiAutocomplete from "../AutocompleteComponents/MultiAutocomplete";
import FilterAutocomplete from "../AutocompleteComponents/FilterAutocomplete";
import StringFilter from "../AutocompleteComponents/StringFilter";

interface FilterProps {
  filterModel: any;
  setFilterModel: (model: any) => void;
  field: any;
  label: string;
  skuType: string;
}

type FilterOptionsType = {
  [name: string]: any;
};

const fetchModule = new FetchModule();

const useFetchGetSet = (
  url: string,
  type: string
): [any, React.Dispatch<React.SetStateAction<any>>] => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchModule.fetchResponseAuthed(url, type);
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [type, url]);

  return [data, setData];
};

const SkuFilter = (props: FilterProps) => {
  const { filterModel, setFilterModel, field, label, skuType } = props;
  const [selectedSkuValue, setSelectedSkuValue] = useState<string>("");

  const [data] = useFetchGetSet('/Card/all/skuitems', "GET");

  let fieldCapitalized = field.charAt(0).toUpperCase() + field.slice(1);
  if (field.toLowerCase() === "setvariety") {
    fieldCapitalized = "Set";
  }

  let options = [];

  if (data) {
    options = (data[field]?.items) || (data[fieldCapitalized]?.items) || [];
  }

  const CommonOperators = [
    {
      label: "equals",
      value: "equals",
      InputComponent: FilterAutocomplete,
      InputComponentProps: {
        id: field + "-equals-filter",
        filterModel: filterModel,
        setFilterModel: setFilterModel,
        field: field,
        operator: "equals",
        options: options,
        label: skuType,
      },
    },
    {
      label: "contains",
      value: "contains",
      InputComponent: StringFilter,
      InputComponentProps: {
        id: field + "-contains-filter",
        filterModel: filterModel,
        setFilterModel: setFilterModel,
        field: field,
        operator: "contains",
        label: skuType,
      },
    },
    {
      label: "starts with",
      value: "startsWith",
      InputComponent: StringFilter,
      InputComponentProps: {
        id: field + "-startsWith-filter",
        filterModel: filterModel,
        setFilterModel: setFilterModel,
        field: field,
        operator: "startsWith",
        label: skuType,
      },
    },
    {
      label: "ends with",
      value: "endsWith",
      InputComponent: StringFilter,
      InputComponentProps: {
        id: field + "-endsWith-filter",
        filterModel: filterModel,
        setFilterModel: setFilterModel,
        field: field,
        operator: "endsWith",
        label: skuType,
      },
    },
    {
      label: "is empty",
      value: "isEmpty",
      InputComponent: null,
    },
    {
      label: "is not empty",
      value: "isNotEmpty",
      InputComponent: null,
    },
    {
      label: "is any of",
      value: "isAnyOf",
      InputComponent: MultiAutocomplete,
      InputComponentProps: {
        id: field + "-is-any-of-filter",
        filterModel: filterModel,
        setFilterModel: setFilterModel,
        field: field,
        options: options,
        label: skuType,
      },
    },
  ];

  const GradeOperators = [
    {
      label: "is",
      value: "=",
      InputComponent: FilterAutocomplete,
      InputComponentProps: {
        field: "grade",
        options,
        selectedSkuValue,
        setSelectedSkuValue,
        setFilterModel,
        filterModel,
        label: skuType,
      },
    },
    {
      label: "is not",
      value: "!=",
      InputComponent: FilterAutocomplete,
      InputComponentProps: {
        field: "grade",
        options,
        selectedSkuValue,
        setSelectedSkuValue,
        setFilterModel,
        filterModel,
        label: skuType,
      },
    },
    {
      label: "greater than",
      value: ">",
      InputComponent: FilterAutocomplete,
      InputComponentProps: {
        field: "grade",
        options,
        selectedSkuValue,
        setSelectedSkuValue,
        setFilterModel,
        filterModel,
        label: skuType,
      },
    },
    {
      label: "less than",
      value: "<",
      InputComponent: FilterAutocomplete,
      InputComponentProps: {
        field: "grade",
        options,
        selectedSkuValue,
        setSelectedSkuValue,
        setFilterModel,
        filterModel,
        label: skuType,
      },
    },
    {
      label: "greater than or equal to",
      value: ">=",
      InputComponent: FilterAutocomplete,
      InputComponentProps: {
        field: "grade",
        options,
        selectedSkuValue,
        setSelectedSkuValue,
        setFilterModel,
        filterModel,
        label: skuType,
      },
    },
    {
      label: "less than or equal to",
      value: "<=",
      InputComponent: FilterAutocomplete,
      InputComponentProps: {
        field: "grade",
        options,
        selectedSkuValue,
        setSelectedSkuValue,
        setFilterModel,
        filterModel,
        label: skuType,
      },
    },
    {
      label: "contains",
      value: "contains",
      InputComponent: StringFilter,
      InputComponentProps: {
        id: field + "-contains-filter",
        filterModel: filterModel,
        setFilterModel: setFilterModel,
        field: field,
        operator: "contains",
        label: skuType,
      },
    },
    {
      label: "does not contain",
      value: "notContains",
      InputComponent: StringFilter,
      InputComponentProps: {
        id: field + "-doesNotContain-filter",
        filterModel: filterModel,
        setFilterModel: setFilterModel,
        field: field,
        operator: "notContains",
        label: skuType,
      },
    },
    {
      label: "is raw",
      value: "isRaw",
      InputComponent: null,
    },
    {
      label: "is graded",
      value: "isGraded",
      InputComponent: null,
    },
  ];

  const Operators = [...CommonOperators];

  return {
    SkuFilter,
    GradeOperators,
    Operators,
  };
}

export default SkuFilter;