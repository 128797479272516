import { Alert, Box, Card, Container, Grid, Snackbar } from '@mui/material';
import { DashboardLayout } from '../Components/DashboardComponents/DashboardLayout';
import { LoginForm } from '../Components/LoginComponents/LoginForm';

import PackingPageMain from '../Dash/NewPackingPage/PackingPageMain';
import { useState } from 'react';

const LoginPage = (props) => {
    const [open,setOpen] = useState(true);
    const snackbar = {
        severity: "error",
        children: props.message
    }
    
    return(<Box sx={{
        width: '100%', 
        height: '100%', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
        }}
        backgroundColor="background.dark.900"

        >
        <div>
        <Snackbar
         open={open}
         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
         
         >
            <Alert {...snackbar}/>
        </Snackbar>
        <LoginForm />
        </div>
        
              
    </Box>)
};
  
  
  export default LoginPage;
  