import React, { Component, useEffect } from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import { Button, Input, Table, InputGroup} from 'reactstrap';
import '../Hamburger.css';
import './break.css';
import FetchModule from "../../Modules/FetchModule";
import NewBreak from "./NewBreak"
import BreakList from "./BreakList"
import GiveawayList from './GiveawayList';
import BreakSettings from './BreakSettings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import CreateBreaks from './CreateBreaks';
import Personals from './Personals.js'
library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);

const personals = Personals.personals

const fetchData = new FetchModule();
const Observer = ({value, didUpdate}) => {
    const location = useLocation();
    useEffect (() => {
        didUpdate(location)
    }, [location])
    return null;
}
export default class BreakScreens extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activePage: "create",
            currentProducts: {},
            products: {},
            productsToShow: [],
            searchText: "",
            league: "",
            breakType: "TR",
            session: null,
        }
        var item = localStorage.getItem('session')
        this.state.activePage = item === null ? 'create' : item

    }
    async componentDidMount() {
        let path = window.location.pathname
        let id = path.split('/')
        this.state.sessionId = id[3]
        const sessionResponse = await fetchData.fetchResponseAuthed("/Breaking/session/"+this.state.sessionId, "GET")
        const sesh = await sessionResponse.json()
        this.updateState('session', sesh)
    }
 // STUFF FOR PRODUCT UPDATES:
 updateState = (name, state, func) => {
    this.setState({
        [name]: state
    }, func)
}
    updated = (event) => {
        let page = event.pathname.split("/")[4]
        this.updateState("activePage", page)
    }
    onClick = (event) => {
        if (this.state.session.saleId != "" && event.target.id == "create") {

        } else {
        this.props.navigate(event.target.id)
        localStorage.setItem('session', event.target.id)
            this.updateState("activePage", event.target.id)
        }
    }
    getActive = (val) => {
        return this.state.activePage == val ? "active" : ""
    }

    getBody = () => {
        if (this.state.session != null) {
        if(this.state.activePage == "create" && this.state.session.saleId == '') {
            return(
                <CreateBreaks
                    session={this.state.session}
                    breakType="create"
                    breakState="create"
                    navigate={this.props.navigate}
                />
            )
        } else if (this.state.activePage == "create" && this.state.session.saleId != '') {
            this.props.navigate("/dashboard/session/" +this.state.sessionId+"/breaks")
        }
        else if (this.state.activePage == "giveaway") {
            return(
                <GiveawayList 
                    updateState={this.updateState}
                />
            )
        }  else if (this.state.activePage == "settings") {
            return (
                <BreakSettings />
            )
        }else if(this.state.activePage == "breaks") {
            return(
                <BreakList 
                    session={this.state.session}
                    navigate={this.props.navigate}/>
            )
        } else if(this.state.activePage == "personals") {
            return (
                <Personals
                    session={this.state.session}
                    navigate={this.props.navigate}
                />
            )
        }
    }
    }
    goBack = () => {
        this.props.navigate('/dashboard/session')
    }
    render() {
        return (
            <div className={"dashboard-page-flip"}>
                <Observer value={this.state.activePage} didUpdate={this.updated}/>
                <div className="dashboard-nav topbar" 
                    onMouseEnter={this.mouseEnter}
                    onMouseLeave={this.mouseLeave}
                >
                    <button className="back-button" onClick={this.goBack}>Go Back</button>
                </div>
                <div className="dashboard-nav-flip">
                    <div className="dashboard-nav-left-flip">
                    <div className={this.getActive("create") + " dashboard-nav-left-item "}
                            onClick={this.onClick}
                            id="create"
                            >Create Item</div>
                        <div className={this.getActive("breaks") + " dashboard-nav-left-item "}
                            onClick={this.onClick}
                            id="breaks"
                            >Breaks</div>
                        <div
                            className={this.getActive("personals") + " dashboard-nav-left-item "}
                            onClick={this.onClick}
                            id="personals"
                        >Personals
                        </div>
                        <div
                            className={this.getActive("giveaway") + " dashboard-nav-left-item "}
                            onClick={this.onClick}
                            id="giveaway"
                        > Giveaways
                        </div>
                        <div
                            className={this.getActive("settings") + " dashboard-nav-left-item "}
                            onClick={this.onClick}
                            id="settings"
                        >Settings
                        </div>
                    </div>
                    {this.getBody()}
                </div>
                
            </div>
        )
    }

}
