import React, {useState, useCallback} from "react"
import FetchModule from "../../Modules/FetchModule"

export default function useFileUploader(baseLink) {
    const [selectedFile, setSelectedFile] = useState(null)

    const fetchData = new FetchModule();

    const useFetchPromise = () => {
        return useCallback(
          (link, type, data) =>
            new Promise((resolve, reject) => {
              fetchData.muiFetchFormData(link, type, data).then((response) => {
                if (response.status == 200) {
                  resolve(response)
                } else {
                  reject(new Error("Error when saving"))
                }
              })
            })
        )
      }    
    const  fetchPromise = useFetchPromise()

    async function upload(endLink) {
        if (selectedFile == null) {
            return false;
        }
        await fetchPromise(baseLink + endLink, "POST", selectedFile)
        return true;
    }

    return {selectedFile, setSelectedFile, upload}
}