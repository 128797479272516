import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import FetchModule from "../../../Modules/FetchModule";
import useFetchPromise from "../../../Components/CommonUtility/useFetchPromise";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import { StagedBox } from "../Types/StagedBoxType";
import { RepackType } from "../Types/RepackTypeType";

export default function SeriesInfoPanel (props: { types: RepackType[]; setSelectedType: any; selectedType: string | RepackType; boxes: StagedBox[]; }){
  var types = props.types
  const setSelectedType = props.setSelectedType
  const selectedType = props.selectedType
  const boxes = props.boxes;
  const fetchData = new FetchModule();
  

  const handleChange = (event: { target: { value: any; }; }) => {
    setSelectedType(event.target.value);
  };
  const { user } = useUser();
  const fetchPromise = useFetchPromise();




  const handleStageSave = ()=>{

    var filteredBox = boxes.map((item: StagedBox)=>[
      item.items.map(({inventoryId})=>(inventoryId))
    ])


    fetchPromise("/Repack/construct/series", "POST", {
      Body: {boxes:filteredBox},
      RepackType:selectedType,
      UserId: user["id"],
    
    });
  }

  return (
    <div style={{padding: "15px", width: "50%"}}>
      <FormControl fullWidth >
        <InputLabel id="demo-simple-select-label">Choose an Option</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedType}
          label="Choose an Option"
          onChange={handleChange}
        >
          {types && types.map((item:any) => (
            <MenuItem key={item.id} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button onClick={handleStageSave}>Save</Button>
    </div>
    
  );

  
}