import React, { useState } from "react";
import {
  Button,
} from "@mui/material";

import { useUser } from "../CommonUtility/UserContext";
import { FileUploaderProvider } from "../FileUploader/FileUploaderContext";
import CardImportModalContent from "./CardImportModalContent";

export default function CardImportButton(props: any) {
  const [purchasedBy, setPurchasedBy] = useState("");
  const [open, setOpen] = useState(false);
  const options = props.users;
  const { user } = useUser();

  const handleClickOpen = () => {
    setOpen(true);
  };

 

  
  return (
    <>
      <FileUploaderProvider endpoint={`/Card/import/${purchasedBy}/${user["initials"]}`} accepts='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' maxFiles={1}>
        <CardImportModalContent open={open} setOpen={setOpen} users = {options} setPurchasedBy = {setPurchasedBy} purchasedBy = {purchasedBy}/>
      </FileUploaderProvider>
      <Button onClick={handleClickOpen}>Import Cards</Button>
    </>
  );
}
