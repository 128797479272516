import { useState } from "react";
// Utility function to help with using states within local storage
// so that a specific state is saved locally and persistant within a browser.
// Only works with JSON Formatted variables and not functions.
// could be editied for further functionality by using (not intended use):
// valuestore in setValue = value instanceof Function ? value(storedValue) : value;
export function useLocalStorage(key, initialValue) {
    
    const [storedValue, setStoredValue] = useState(() => {
        try {
            let item = window.localStorage.getItem(key)
            if (item === null || item === 'undefined') {
                window.localStorage.setItem(key, JSON.stringify(initialValue))
                item = initialValue
            }
            const parsedValue = JSON.parse(item || '{}');
            return parsedValue;
        } catch (error) {
            console.error('Error reading from local storage:', error);
            return initialValue;
        }
    })
    const setValue = (value) => {
        try {
            setStoredValue(value);
            window.localStorage.setItem(key, JSON.stringify(value))
        } catch (error) {
            console.error('Error writing to local storage:', error);
        }
    }
    return [storedValue, setValue]
}