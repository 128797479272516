import React, { useCallback } from "react";
import "../Hamburger.css";
import FetchModule from "../../Modules/FetchModule";
import { Button, ButtonGroup } from "reactstrap";
import { useState, useEffect } from "react";
import { GridRowModes } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import {
  Snackbar,
  Paper,
  Popper,
  Typography,
  Box,
  Alert,
  Backdrop,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { AutocompleteSkuItem } from "./AutocompleteComponents/AutocompleteSkuItem.js";
import CustomTextInput from "./CardsHomeComponents/CustomTextInput";
import StatusSelect from "./FilterComponents/CustomStatusFilter";
import AutocompleteAttributes from "./AutocompleteComponents/AttributesAutocomplete";
import "./singles.css";
// Buttons
import UploadChecklistButton from "./FooterComponents/UploadChecklistButton";
import SkuUploaderButton from "../../Components/UtilityButtons/SkuUploaderButton";

// Utility Functions
import { useLocalStorage } from "../../Utilities/useLocalStorage";
import useFetchPromise from "../../Components/CommonUtility/useFetchPromise";
import { useUser } from "../../Components/CommonUtility/UserContext";
import { AutocompletePlayer } from "./AutocompleteComponents/AutocompletePlayer";
import DataGridComponent from "./CardsHomeComponents/DataGridComponent";
import CardActionsInputGroup from "./ToolbarComponents/CardActionsInputGroup";
import CustomDateInput from "./CardsHomeComponents/CustomDateInput.js";
import CustomNumberInput from "./CardsHomeComponents/CustomNumberInput.js";
import AutocompleteUser from "./AutocompleteComponents/AutocompleteUser.js";

import { useRepackStore } from "../../Utilities/RepackCardStore";
import DashboardDefault from "./CardOrderDefaults/DashboardDefault";
import PaginationMutator from "../../Utilities/PaginationMutator";
import SimpleAutocomplete from "./AutocompleteComponents/SimpleAutocomplete";
import { useFeatureFlags } from "../../Contexts/FeatureFlagContexts/FeatureFlagContext";

const fetchData = new FetchModule();
// Helper functions:
const useFetchGet = (url, type) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetchData
      .fetchResponseAuthed(url, type)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [type, url]);
  return [data];
};

const useFetchGetSet = (url, type) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetchData
      .fetchResponseAuthed(url, type)
      .then((res) => res.json())
      .then((data) => setData(data))
      .catch((error) => {
        // Handle errors here if needed
        console.error('Error fetching data:', error);
      });
  }, [type, url]);

  // Return both data and setData
  return [data, setData];
};

/*
    This class is used for all the single cards. It's the entire page
    for the /singlecards page and uses MUI to display the data
    in a way that makes sense and is customizable nad saves any changes
    local storage so that you can always come back to the same page you
    left on a bresponseer when you are changing column sizes, filters, etc.
  */
export default function SinglesPage(props) {
  const { flags } = useFeatureFlags();
  const repackOn = flags.repack;
  const [
    selectedForRepack,
    addToRepackStoreRepackVersion,
    clearRepackStore,
    removeFromRepackStoreRepackVersion,
    addToRepackStore,
    removeFromRepackStore,
  ] = useRepackStore();
  const { user } = useUser();
  const [setModalOpen] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [autoDeselect] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const fetchPromise = useFetchPromise();
  const [refresh, setRefresh] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 1,
    page: 1,
    pageSize: 750,
    lastEditWasManual: false,
  });
  // Setting the filter modes a JS
  const [filterModel, setFilterModel] = useLocalStorage("cardFilterOptions", {
    items: [],
    quickFilterValues: [],
  });
  const verifyFilterModel = (items)=>{
    return items.filter(filterItem=>{
    if(filterItem.operator === "isEmpty" ||
    filterItem.operator === "isNotEmpty"  ||
    filterItem.operator === "isGraded" ||
    filterItem.operator === "isRaw"){
      return true;
    }

    if(filterItem.field === 'inventoryId' && filterItem.value === ""){
      return false;
    }
    return !(filterItem.value === undefined || filterItem.value === null)
  })
  }
  const [sortModel, setSortModel] = useLocalStorage("cardSortOptions", [
    {
      field: "",
      sort: "",
    },
  ]);

  const [headCard,setHeadCard] = useState(null)

  const resetSort = React.useCallback(() => {
    setSortModel([]);
  }, [setSortModel]);

  const GetPendingLastCard = async () => {
    setIsJump(true)
    const verifiedFilterModel = verifyFilterModel(filterModel.items)
    try {
      const responsePromise = await fetchPromise(
        "/Card/getlastcardpage",
        "POST",
        {
          page: pageState.page,
          pageSize: pageState.pageSize,
          filterModel: {...filterModel, items:verifiedFilterModel},
          sortModel: sortModel,
          jumptype: "pending",
        }
      );

      if (!responsePromise.ok) {
        throw new Error("Fetch request failed");
      }

      resetSort();
      const responseData = await responsePromise.json();
      setRows(responseData.items);
      setRowCount(responseData.totalItems);

      setPageState(PaginationMutator.setPageTo(pageState,responseData.currentPage))
      setHeadCard(responseData.lastId);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getRawLastCard = React.useCallback(async () => {
    try {
      setIsJump(true)
      const verifiedFilterModel = verifyFilterModel(filterModel.items)
      const responsePromise = await fetchPromise(
        "/Card/getlastcardpage",
        "POST",
        {
          page: pageState.page,
          pageSize: pageState.pageSize,
          filterModel: {...filterModel, items:verifiedFilterModel},
          sortModel: sortModel,
          jumptype: "raw",
        }
      );

      if (!responsePromise.ok) {
        throw new Error("Fetch request failed");
      }

      resetSort();
      const responseData = await responsePromise.json();
      setRows(responseData.items);
      setRowCount(responseData.totalItems);
      setPageState(PaginationMutator.setPageTo(pageState,responseData.currentPage))
      setHeadCard(responseData.lastId);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }, [
    pageState,
    fetchPromise,
    filterModel,
    refresh,
    resetSort,
    sortModel,
  ]);
  const getGradedLastCard = React.useCallback(async () => {
    try {
      setIsJump(true)
      const verifiedFilterModel = verifyFilterModel(filterModel.items)
      const responsePromise = await fetchPromise(
        "/Card/getlastcardpage",
        "POST",
        {
          page: pageState.page,
          pageSize: pageState.pageSize,
          filterModel: {...filterModel, items:verifiedFilterModel},
          sortModel: sortModel,
          jumptype: "graded",
        }
      );

      if (!responsePromise.ok) {
        throw new Error("Fetch request failed");
      }
      resetSort();
      const responseData = await responsePromise.json();
      setRows(responseData.items);
      setRowCount(responseData.totalItems);
      setPageState(PaginationMutator.setPageTo(pageState,responseData.currentPage))
      setHeadCard(responseData.lastId);
      //setResponse(responseData);
      
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }, [
    pageState,
    fetchPromise,
    filterModel,
    refresh,
    resetSort,
    sortModel,
  ]);

  const getRetailLastCard = React.useCallback(async () => {
    try {
      setIsJump(true)
      const responsePromise = await fetchPromise(
        "/Card/getlastcardpage",
        "POST",
        {
          page: pageState.page,
          pageSize: pageState.pageSize,
          filterModel: filterModel,
          sortModel: sortModel,
          jumptype: "retail",
        }
      );

      if (!responsePromise.ok) {
        throw new Error("Fetch request failed");
      }
      resetSort();
      const responseData = await responsePromise.json();
      setRows(responseData.items);
      setRowCount(responseData.totalItems);
      setPageState(PaginationMutator.setPageTo(pageState,responseData.currentPage))
      setHeadCard(responseData.lastId);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }, [
    fetchPromise,
    filterModel,
    pageState,
    refresh,
    resetSort,
    sortModel,
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('card'); // Replace 'myParam' with the name of your parameter
  
    if (myParam) {
      setIsJump(true)
      const responsePromise = fetchPromise(
        "/Card/getlastcardpage",
        "POST",
        {
          page: pageState.page,
          pageSize: pageState.pageSize,
          filterModel: {
            items: [],
            quickFilterValues: [],
          },
          sortModel: sortModel,
          jumptype: myParam,
        }
      ).then((res) => res.json().then((responseData) => {
        resetSort();
        setRows(responseData.items);
        setRowCount(responseData.totalItems);
        setPageState(PaginationMutator.setPageTo(pageState,responseData.currentPage))
        setTimeout(() => {
          setHeadCard(responseData.lastId);
          urlParams.delete('card');
          window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
        }, 100);
      }))
  
  
  
      
      
    }
    
  },[
    fetchPromise,
    filterModel,
    pageState,
    refresh,
    resetSort,
    sortModel,
  ])


  const onFilterChange = React.useCallback(
    (filterModel) => {
      setFilterModel(filterModel);
      setRefresh(!refresh);
    },
    [refresh, setFilterModel]
  );

  const handleSortModelChange = React.useCallback(
    (sortModel) => {
      setSortModel(sortModel);
      setRefresh(!refresh);
    },
    [refresh, setSortModel]
  );

  const [isJump,setIsJump]  = useState(false)


  

  // Helper function to fetch data asynchronously
  const fetchDataAsync = React.useCallback(async () => {
    try {
      const verifiedFilterModel = verifyFilterModel(filterModel.items)
    
      
      const responsePromise = await fetchPromise(
        "/Card/all/" + pageState.page + "/" + pageState.pageSize,
        "POST",
        {
          page: pageState.page,
          pageSize: pageState.pageSize,
          filterModel: {...filterModel, items:verifiedFilterModel},
          sortModel: sortModel,
        }
      );

      if (!responsePromise.ok) {
        throw new Error("Fetch request failed");
      }

      const responseData = await responsePromise.json();
      setResponse(responseData);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }, [fetchPromise, filterModel, pageState, sortModel]);

  React.useEffect(() => {
    if(!isJump){

      fetchDataAsync();
    }
  }, [fetchDataAsync, refresh]);

  const [response, setResponse] = React.useState({});

  const handlePageChange = (params) => {
    setPageState(PaginationMutator.setPageTo(pageState,params));
    setRefresh(!refresh);
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = event?.target?.value ?? event;
    setPageState(PaginationMutator.setPageTo(pageState,1))
    setPageState(PaginationMutator.setPageSize(pageState,newPageSize))
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (response && response.items) {
      if(!isJump){
        setRows(response.items);
        setRowCount(response.totalItems);
      }
      
      setPageState(PaginationMutator.setTotal(pageState,response.totalItems))
      setResponse(null);
    }
  }, [response]);

  useEffect(() => {
    setRowCount((prevRowCount) =>
      rowCount !== undefined ? rowCount : prevRowCount
    );
  }, [rowCount]);

  // Snack bar variables
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const [columnWidths, setColumnWidths] = useLocalStorage("cardColumnWidths", DashboardDefault().width);

  // Doing the same thing but with column visibility
  const [columnFilterDisplayModel, setColumnFilter] = useLocalStorage(
    "columnFilterOptions",
    DashboardDefault().visibility
  );
  function setColumnVisibility(newModel) {
    setColumnFilter(newModel);
  }

  const [dataFields, setDataFields] = useFetchGetSet("/Card/all/skuitems", "GET");
  const [filterList,setFilterList] = useFetchGetSet("/Card/get/allfilters", "GET");
  const [statusTypes] = useFetchGet("/Card/all/cardstatustypes", "GET");
  const [players] = useFetchGet("/Card/all/players", "GET");
  const [vendors, setVendors] = useFetchGetSet("/Card/all/vendors", "GET");
  const [users] = useFetchGet("/Card/all/users", "GET");

  const [rowState, setRowState] = React.useState({});
  const [cardsToAdd, setCards] = React.useState(1);
  const [rowChanged, setRowChanged] = React.useState(false);
  const [updatedValues, setUpdatedValues] = React.useState({}); // Lift state up

  const handleRowEditStart = (params, event) => {
    setRowState(params.row);
  };

  function findRowById(array, rowId) {
    for (let i = 0; i < array.length; i++) {
      const currentObject = array[i];
      if (currentObject["inventoryId"] === rowId) {
        return currentObject;
      }
    }
    return null;
  }

  React.useEffect(() => {
    const valuesChanged =
      JSON.stringify(rowState) !== JSON.stringify(updatedValues);
    setRowChanged(valuesChanged);
  }, [rowState, updatedValues]);

  const handleRowEditStop = (params, event,isScrolling) => {
    

    if(isScrolling){
      event.defaultMuiPrevented = true;
      return;
    }
    const rowId = params.row.inventoryId;
    const row = findRowById(rows, rowId);
    setUpdatedValues(params.row);

    if (rowChanged) {
      event.defaultMuiPrevented = false;
    } else {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleEditClick = React.useCallback(
    (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel]
  );

  const handleSaveClick = React.useCallback(
    (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const handleMoveGradeClick = React.useCallback(
    (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, myType: "moveGrade" },
      });
    },
    [rowModesModel],
    getGradedLastCard
  );

  const handleMoveRawClick = React.useCallback(
    (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, myType: "moveRaw" },
      });
    },
    [rowModesModel],
    getRawLastCard
  );

  const handleCancelClick = React.useCallback(
    (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    },
    [rowModesModel, rows]
  );
  
  const [warningOpen, setWarningOpen] = React.useState(false);
  const [resolveOnClose, setResolveOnClose] = useState(null);
  const [rejectOnCancel, setRejectOnCancel] = useState(null);
  const [nextInvId, setNextInvId] = useState(false);

  const interpretStatusChange = (oldCard,newCard)=>{
    var retString = null
    if(oldCard.inventoryId>20000000 &&oldCard.inventoryId< 30000000&& newCard.status.value === "InInventory" && (oldCard.status.value === "PendingConfirmation" || oldCard.status.value === "")){
      if(newCard.grade.value === "raw" || newCard.grade.value === "null" || newCard.grade.value === ""){
        retString =  "moveRaw"
      }
      else{
        retString =  "moveGrade"
        
      }
      
    }
    else if(oldCard.inventoryId<20000000 && oldCard.inventoryId>10000000 && oldCard.grade?.value === "" && !(newCard.grade?.value === "") ){
      retString = "moveGrade"
    }
    return retString
  }

  const [moveCardDialog,setMoveCardDialog] = React.useState("")

  const MoveCardConfirmationModal = useCallback((props) => {
    return (
      <Dialog open={warningOpen} onClose={()=>{
        setWarningOpen(false);
        if (resolveOnClose) {
            resolveOnClose();
            setResolveOnClose(null);
        }
    }}>
        <DialogTitle>{"You are about to automatically move a card"}</DialogTitle>
        <DialogContent>
            {moveCardDialog}
        </DialogContent>
        <DialogActions>
        <Button onClick = {()=>{
              setWarningOpen(false);
              if (resolveOnClose) {
                  resolveOnClose();
                  setResolveOnClose(null);
              }
          }}>Okay</Button>
          <Button onClick = {()=>{
              setWarningOpen(false);
              if (rejectOnCancel) {
                  rejectOnCancel();
                  setRejectOnCancel(null);
              }
          }}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  },[warningOpen,moveCardDialog,nextInvId]) 

  const manageConfirmationMessage = (moveType,data)=>{
    if(moveType === "moveGrade"){
      setMoveCardDialog("You are about to move a card from pending to graded. This will automatically update the card in the inventory system from its current inventory id to "+data+". Are you sure you want to do this?")
    }
    else if(moveType === "moveRaw"){
      setMoveCardDialog("You are about to move a card from pending to raw. This will automatically update the card in the inventory system from its current inventory id to "+data+". Are you sure you want to do this?")
    }
  }
  const processRowUpdate = React.useCallback(
    async (newRow,oldRow) => {

      if (!open) {
        setOpen(true);
        const updatedRow = { ...newRow, isNew: false };
        updatedRow.totalCost =
          updatedRow.cost + updatedRow.fees + updatedRow.gradingFees;
        var message = "";
        var skipto = () => {};
        var refRow = rowModesModel[newRow.id];
        var isMovingFromPending = interpretStatusChange(oldRow,newRow);
        if(!(isMovingFromPending === null || isMovingFromPending === undefined)){
          const res = await fetchData.fetchResponseAuthed("/Card/get/NextInvId/"+isMovingFromPending, "GET");
          const data = await res.json();
          setNextInvId(data);
          manageConfirmationMessage(isMovingFromPending,data)
          setWarningOpen(true);
          try{
            await new Promise((resolve,reject) => {
              setResolveOnClose(() => resolve);
              setRejectOnCancel(() => reject);
            });
          }
          catch {
            setRows(rows.map((row) => (row.id === oldRow.id ? oldRow : row)));
            setOpen(false);
            return oldRow;
            
        }
          

        }
        if (
          rowModesModel[newRow.id] &&
          rowModesModel[newRow.id]["mode"] === "view" &&
          !("myType" in refRow)
        ) {
          await fetchPromise("/Card/update", "PUT", {
            body: updatedRow,
            UserId: user["id"],
            Guid: newRow.publicId,
            JumpType: isMovingFromPending
          });
          setRefresh(!refresh);
          message = "User successfully saved";
        } else if (rowModesModel[newRow.id]["myType"].includes("move")) {
          await fetchPromise("/Card/update/MoveCard", "POST", {
            Body: {
              card: updatedRow,
              type: rowModesModel[newRow.id]["myType"],
            },
            UserId: user["id"],
            Guid: newRow.publicId,
          });
          if (newRow.row?.inventoryId >= 20000000) {
            skipto = getRawLastCard;
          } else {
            skipto = getGradedLastCard;
          }
          message = "User successfully moved card";
        } else if (rowModesModel[newRow.id]) {
          await fetchPromise("/Card/update", "PUT", {
            Body: updatedRow,
            UserId: user["id"],
            Guid: newRow.publicId,
          });
          setRefresh(!refresh);
          message = "User successfully saved";
        }
        setSnackbar({ children: message, severity: "success" });
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        setOpen(false);
        skipto();
        return updatedRow;
      }
    },
    [
      fetchPromise,
      getGradedLastCard,
      getRawLastCard,
      open,
      refresh,
      rowModesModel,
      rows,
      user,
    ]
  );

  //todo: cannot read props of undef set here
  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({
      children: "Error: " + error,
      severity: "error",
    });
    setOpen(false);
  }, []);

  const addNewSKU = React.useCallback(
    (field, item) => {
      setDataFields((prevVals) => {
        const vals = { ...prevVals };
        vals[field]["items"].push(item);
        return vals;
      });
    },
    [setDataFields]
  );

  const renderSimpleAutocomplete = React.useCallback((params) => {
    return (
      <SimpleAutocomplete 
        {...params} 
        width={columnWidths[params.field]}
        options={vendors}
        setOptions={setVendors}
      />
    );
  });

  const renderCustomTextInput = React.useCallback((params) => {
    return <CustomTextInput {...params} />;
  });

  const renderCustomNumberInput = React.useCallback((params) => {
    return <CustomNumberInput {...params} />;
  });

  const renderAutocompleteCellSkuItem = React.useCallback(
    (params) => {
      return (
        <AutocompleteSkuItem
          {...params}
          width={columnWidths[params.field]}
          dataFields={dataFields}
          setDataFields={setDataFields}
          addNewSKU={addNewSKU}
          setModalOpen={setModalOpen}
        />
      );
    },
    [addNewSKU, columnWidths, dataFields, setDataFields, setModalOpen]
  );

  const renderCustomDate = React.useCallback((params) => {
    return <CustomDateInput {...params} setSnackbar={setSnackbar} />;
  });
  const renderCustomUser = React.useCallback((params) => {
    return (
      <AutocompleteUser
        datafields={users}
        {...params}
        setSnackbar={setSnackbar}
      />
    );
  });
  const renderAutoCompleteCellPlayer = React.useCallback(
    (params) => {
      return (
        <AutocompletePlayer
          style={{ fontSize: "14px" }}
          {...params}
          width = {columnWidths[params.field]}
          dataFields={players}
          addNewSKU={addNewSKU}
          setModalOpen={setModalOpen}
        />
      );
    },
    [addNewSKU, columnWidths, players, setModalOpen]
  );

  const renderCert = (params) => {
    const label = params.value ? params.value : "";
    const isPSA = params.row.grade.label.includes("PSA");
    const certClick = (event) => {
      window.open(
        "https://www.psacard.com/cert/" + label,
        "_blank",
        "noopener,noreferrer"
      );
    };

    return (
      <Typography
        onClick={isPSA ? certClick : null}
        style={{ fontSize: "14px" }}
      >
        {label}
      </Typography>
    );
  };

  const renderUser = (params) => {
    const label = params.value ? params.value : "";
    return <Typography style={{ fontSize: "14px" }}>{label}</Typography>;
  };

  const renderACDisplayCell = (params) => {
    const label = params.value ? params.value.label : "";
    const width = columnWidths[params.field]

    return <Typography style={{ fontSize: "14px", width: width }}>{label}</Typography>;
  };

  const renderSelectCell = React.useCallback(
    (params) => {
      return (
        <StatusSelect
          width={columnWidths[params.field]}
          style={{ fontSize: "14px" }}
          {...params}
          statusTypes={statusTypes}
          onKeyDown={(event) => {
            if (event.key === 'Tab') {
              event.target.blur();
            }
          }}
          
        />
      );
    },
    [statusTypes]
  );

  const renderAttributesSelectCell = React.useCallback((params) => {
    const options = [
      { value: "RC", label: "RC" },
      { value: "Auto", label: "Auto" },
      { value: "Auto RC", label: "Auto RC" },
      { value: "1st", label: "1st" },
      { value: "1st Auto", label: "1st Auto" },
      { value: "", label: "Empty" },
    ];

    return (
      <AutocompleteAttributes
        style={{ fontSize: "14px" }}
        {...params}
        options={options}
      />
    );
  }, []);

  const renderSelectDisplayCell = (params) => {
    const label = params.value.label ? params.value.label : "";
    return (
      <Typography style={{ fontSize: "14px" }}>{label ? label : ""}</Typography>
    );
  };

  const renderAttributesSelectDisplayCell = (params) => {
    const value = params.value ? params.value : "";
    return (
      <Typography style={{ fontSize: "14px" }}>{value ? value : ""}</Typography>
    );
  };

  function changeColumnWidth(event) {
    const { field: columnId, width: newWidth } = event.colDef;
    setColumnWidths({
      ...columnWidths,
      [columnId]: newWidth,
    });
  }

  const changeStatusOnLiveCells = (refRows,status)=>{

    if(filterModel.items.some(item => item.field === 'status')){
      // This is the case where the items will be removed from the filter model
      setRows(rows.filter((row) => {
        return !refRows.includes(row.id)
      }))
    }

    else{

      //This is the case where the items are modified and still rendered
      setRows(rows.map((row) => {
        if(refRows.includes(row.id)){
          return {...row, status: status}
        }
        return row;
      }))
      setSelectedRows(selectedRows.map((row) => {
          return {...row, status: status}
      }))
    }
    
    
  }

  async function createRecords(type) {
    if (!open) {
      setOpen(true);
      const response = await fetchPromise("/Card/new/" + cardsToAdd, "POST", {
        userId: user["id"],
        body: {
          type: type,
        },
      });

      var goTo = () => {};
      if (type === "raw") {
        goTo = getRawLastCard;
      } else if (type === "grade") {
        goTo = getGradedLastCard;
      } else if (type === "pending") {
        goTo = GetPendingLastCard;
      }
      else if(type === 'retail'){
        goTo = getRetailLastCard;
      }

      if (response.status === 200) {
        const data = await response.json();
        setSnackbar({
          children: "Added " + data.length + " new records",
          severity: "success",
        });
        goTo();
      } else {
        setSnackbar({
          children: "Failed to add new records",
          severity: "error",
        });
      }
      setOpen(false);
    }
  }

  function deselect() {
    if (autoDeselect) {
      setSelectedRows([]);
      setSelectionModel([]);
    }
  }

  // Verifies a cards record & creates a new card if card is not found within inventory system.
  async function verifyRecord() {
    if (!open) {
      setOpen(true);
      const response = {};
      if (response.status === 200) {
        setSnackbar({
          children: "Successfully added to dear",
          severity: "success",
        });
        const data = await response.json();
        processRowUpdate(data);
        var newRows = [];
        newRows.push(data);
        setRows(rows.map((row) => (row.id === data.id ? data : row)));
        deselect();
        setOpen(false);
        return data;
      } else {
        setSnackbar({ children: "Failed to add to dear", severity: "error" });
      }
      setOpen(false);
    }
    return null;
  }

  // Creates a new pantheon
  async function createPantheon() {
    if (!open) {
      setOpen(true);
      var data = [];
      selectedRows.forEach((row) => {
        data.push({
          ProductID: row.productId,
          Quantity: 1,
          BatchSN: row.id.toString(),
        });
      });
      const response = await fetchPromise("/Pantheon/new/repack", "POST", {
        body: { selectedRows },
      });
      /*
      if (response.status === 200) {
        const d = await response.json();
        var ourData = {};
        d.forEach((item) => {
          ourData[item.id] = item;
        });
        setRows(rows.map((row) => (ourData[row.id] ? ourData[row.id] : row)));
        deselect();
        setSnackbar({
          children: "Successfully created a new pantheon assembly in dear",
          severity: "success",
        });
      } else {
        setSnackbar({
          children: "Failed to create a pantheon",
          severity: "error",
        });
      }
      setOpen(false);
      */
    }
  }

  // Function that returns the total price of a string
  // of a price in format: $100.00
  function getTotalPrice() {
    var amt = 0;
    selectedRows.forEach((row) => {
      amt += row.cost + row.fees + row.gradingFees;
    });
    return "$" + amt.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getTotalComp() {
    var amt = 0;
    selectedRows.forEach((row) => {
      amt += row.comp;
    });
    return "$" + amt.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  var updatePagination = null;

  function setJumpPage(updateFunction) {
    updatePagination = updateFunction;
  }
  

  return (
    <Box
      className="data-grid"
      sx={{
        height: "100%",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
          fontSize: 5,
        },
      }}
    >
      <CardActionsInputGroup
        cardsToAdd={cardsToAdd}
        setCards={setCards}
        createRecords={createRecords}
        verifyRecord={verifyRecord}
        setOpen={setOpen}
        fetchPromise={fetchPromise}
        selectedRows={selectedRows}
        open={open}
        setSnackbar={setSnackbar}
        rows={rows}
        setRows={setRows}
        deselect={deselect}
        setSelectedRows={setSelectedRows}
        createPantheon={createPantheon}
        repackOn={repackOn}
        addToRepackStore={addToRepackStore}
        selectedForRepack={selectedForRepack}
        removeFromRepackStore={removeFromRepackStore}
        clearRepackStore={clearRepackStore}
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        refresh={refresh}
        setRefresh={setRefresh}
        users={users}
      />

      <DataGridComponent
        rows={rows}
        pageState={pageState}
        fetchDataAsync={fetchDataAsync}
        rowCount={rowCount}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        onFilterChange={onFilterChange}
        filterModel={filterModel}
        changeColumnWidth={changeColumnWidth}
        columnFilterDisplayModel={columnFilterDisplayModel}
        handleSortModelChange={handleSortModelChange}
        sortModel={sortModel}
        handleRowEditStart={handleRowEditStart}
        handleRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        handleProcessRowUpdateError={handleProcessRowUpdateError}
        rowSelectionModel={selectionModel}
        setSelectedRows={setSelectedRows}
        setSelectionModel={setSelectionModel}
        handleRowModesModelChange={handleRowModesModelChange}
        selectedRows={selectedRows}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
          setSelectedRows(selectedRows);
          setSelectionModel(ids);
        }}
        getGradedLastCard={getGradedLastCard}
        getRawLastCard={getRawLastCard}
        GetPendingLastCard={GetPendingLastCard}
        getRetailLastCard={getRetailLastCard}
        getTotalPrice={getTotalPrice}
        getTotalComp={getTotalComp}
        rowModesModel={rowModesModel}
        setColumnVisibility={setColumnVisibility}
        setFilterModel={setFilterModel}
        refresh={refresh}
        setRefresh={setRefresh}
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        columnWidths={columnWidths}
        handleSaveClick={handleSaveClick}
        handleMoveGradeClick={handleMoveGradeClick}
        handleMoveRawClick={handleMoveRawClick}
        handleCancelClick={handleCancelClick}
        handleEditClick={handleEditClick}
        renderAutoCompleteCellPlayer={renderAutoCompleteCellPlayer}
        renderACDisplayCell={renderACDisplayCell}
        renderAutocompleteCellSkuItem={renderAutocompleteCellSkuItem}
        renderAttributesSelectCell={renderAttributesSelectCell}
        renderAttributesSelectDisplayCell={renderAttributesSelectDisplayCell}
        renderSelectCell={renderSelectCell}
        renderSelectDisplayCell={renderSelectDisplayCell}
        renderCellExpand={renderCellExpand}
        renderCustomTextInput={renderCustomTextInput}
        renderSimpleAutocomplete={renderSimpleAutocomplete}
        renderCustomDate={renderCustomDate}
        renderAutoCompleteUser={renderCustomUser}
        renderUser={renderUser}
        renderCert={renderCert}
        setJumpPage={setJumpPage}
        renderCustomNumberInput={renderCustomNumberInput}
        players={players}
        dataFields={dataFields}
        setDataFields={setDataFields}
        statusTypes={statusTypes}
        selectedForRepack={selectedForRepack}
        users={users}
        MoveCardConfirmationModal={MoveCardConfirmationModal}
        headCard = {headCard}
        changeStatusOnLiveCells={changeStatusOnLiveCells}
        setIsJump = {setIsJump}
        filterList = {filterList}
        setFilterList = {setFilterList}

      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={10000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ButtonGroup>
        <SkuUploaderButton />
        <UploadChecklistButton />
      </ButtonGroup>
    </Box>
  );
}

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: "100%",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
    />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string,
};
