import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import useFetchPromise from "../../../Components/CommonUtility/useFetchPromise";
import { useUser } from "../../../Components/CommonUtility/UserContext";

interface FilterItem {
  columnField: string;
  field: string;
  operator: string;
  value: string;
}

interface FilterModel {
  items: FilterItem[];
}

interface Props {
  filterModel: FilterModel;
  snackbar: any;
  setSnackbar: (snackbar: { open: boolean; children: React.ReactNode; severity: string }) => void;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
}

const FilterAndSaveButton: React.FC<Props> = (props) => {
  const { user } = useUser();
  const [filterModel] = useState<FilterModel>(props.filterModel);
  const [textInput, setTextInput] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const fetchPromise = useFetchPromise();

  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextInput(event.target.value);
  };

  const handleSaveFilter = async () => {
    const stringFilter = JSON.stringify(filterModel);
    let severity = "success";
    let snackbar = "Filter Saved";
    try {
      const response = await fetchPromise("/Card/save/filter", "POST", {
        userId: user["id"],
        body: {
          filter: stringFilter,
          name: textInput,
        },
      });
      const responseMessage = await response.json();
      if (responseMessage.message) {
        severity = "error";
        snackbar = responseMessage.message;
      }
      props.setSnackbar({ open: true, children: snackbar, severity: severity });
      props.setRefresh(!props.refresh);
      setOpenModal(false);
    } catch (e: any) {
      const errorMessage = e.message || e.toString();
      props.setSnackbar({
        open: true,
        children: errorMessage,
        severity: "error",
      });
      console.error(e);
    }
  };
  
  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "row",
        maxHeight: "100px",
      }}
    >
      <Button
        startIcon={<SaveIcon />}
        onClick={() => setOpenModal(true)}
        style={{
          textAlign: "center",
          fontSize: "0.8125rem",
          maxHeight: "30px",
        }}
      >
        Save Filter
      </Button>

      <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="sm" fullWidth={true}>
        <DialogTitle>Enter Filter Name</DialogTitle>
        <DialogContent>
          <List>
            {filterModel.items.map((filterItem) => (
              <ListItem key={filterItem.columnField}>
                <ListItemText
                  primary={`Column Field: ${filterItem.field}`}
                  secondary={`Operator Value: ${filterItem.operator}, Value: ${filterItem.value}`}
                />
              </ListItem>
            ))}
          </List>
          <TextField
            label="Filter Name"
            value={textInput}
            fullWidth
            onChange={handleTextInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveFilter} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FilterAndSaveButton;
