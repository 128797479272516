const mockData = [
    {
      id: 0,
      serialNumber: 'SN12345',
      type: 'Prophecy',
      status: 'Reserved',
      series: 1001,
      profit: 1500,
      location: 'College Ave',
      containsUnicorn: false,
      details: [
        { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
        { id: 2, name: "Card B", comp: 300, totalCost: 800 },
        { id: 3, name: "Card C", comp: 200, totalCost: 600 },
        { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
        { id: 5, name: "Card B", comp: 300, totalCost: 800 },
        { id: 6, name: "Card C", comp: 200, totalCost: 600 },
      ],
    },
    {
      id: 1,
      serialNumber: 'SN12345',
      type: 'Prophecy',
      status: 'Reserved',
      series: 1001,
      profit: 1500,
      location: 'College Ave',
      containsUnicorn: false,
      details: [
        { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
        { id: 2, name: "Card B", comp: 300, totalCost: 800 },
        { id: 3, name: "Card C", comp: 200, totalCost: 600 },
        { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
        { id: 5, name: "Card B", comp: 300, totalCost: 800 },
        { id: 6, name: "Card C", comp: 200, totalCost: 600 },
      ],
    },
    {
      id: 2,
      serialNumber: 'SN67890',
      type: 'Hero',
      status: 'Packed',
      series: 2002,
      profit: 1200,
      location: 'Arlington St',
      containsUnicorn: true,
      details: [
        { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
        { id: 2, name: "Card B", comp: 300, totalCost: 800 },
        { id: 3, name: "Card C", comp: 200, totalCost: 600 },
        { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
        { id: 5, name: "Card B", comp: 300, totalCost: 800 },
        { id: 6, name: "Card C", comp: 200, totalCost: 600 },
      ],
    },
    {
      id: 3,
      serialNumber: 'SN54321',
      type: 'Pantheon',
      status: 'Allocated to WTH2',
      series: 3003,
      profit: 900,
      location: 'Warehouse 2',
      containsUnicorn: false,
      details: [
        { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
        { id: 2, name: "Card B", comp: 300, totalCost: 800 },
        { id: 3, name: "Card C", comp: 200, totalCost: 600 },
        { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
        { id: 5, name: "Card B", comp: 300, totalCost: 800 },
        { id: 6, name: "Card C", comp: 200, totalCost: 600 },
      ],
    },
    {
      id: 4,
      serialNumber: 'SN24680',
      type: 'Sabre',
      status: 'Allocated to WTH1',
      series: 4004,
      profit: 3000,
      location: 'College Ave',
      containsUnicorn: true,
      details: [
        { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
        { id: 2, name: "Card B", comp: 300, totalCost: 800 },
        { id: 3, name: "Card C", comp: 200, totalCost: 600 },
        { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
        { id: 5, name: "Card B", comp: 300, totalCost: 800 },
        { id: 6, name: "Card C", comp: 200, totalCost: 600 },
      ],
    },
    {
      id: 5,
      serialNumber: 'SN13579',
      type: 'Hero',
      status: 'Packed',
      series: 5005,
      profit: 1200,
      location: 'Warehouse 1',
      containsUnicorn: false,
      details: [
        { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
        { id: 2, name: "Card B", comp: 300, totalCost: 800 },
        { id: 3, name: "Card C", comp: 200, totalCost: 600 },
        { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
        { id: 5, name: "Card B", comp: 300, totalCost: 800 },
        { id: 6, name: "Card C", comp: 200, totalCost: 600 },
      ],
    },
    // Additional mock entries
    {
      id: 6,
      serialNumber: 'SN97531',
      type: 'Hero',
      status: 'Packed',
      series: 5005,
      profit: 1300,
      location: 'Warehouse 1',
      containsUnicorn: true,
      details: [
        { id: 1, name: "Card X", comp: 600, totalCost: 1100 },
        { id: 2, name: "Card Y", comp: 400, totalCost: 900 },
        { id: 3, name: "Card Z", comp: 300, totalCost: 700 },
        { id: 4, name: "Card X", comp: 600, totalCost: 1100 },
        { id: 5, name: "Card Y", comp: 400, totalCost: 900 },
        { id: 6, name: "Card Z", comp: 300, totalCost: 700 },
      ],
    },
    {
      id: 7,
      serialNumber: 'SN24681',
      type: 'Prophecy',
      status: 'Reserved',
      series: 1001,
      profit: 1600,
      location: 'College Ave',
      containsUnicorn: false,
      details: [
        { id: 1, name: "Card A", comp: 600, totalCost: 1100 },
        { id: 2, name: "Card B", comp: 400, totalCost: 900 },
        { id: 3, name: "Card C", comp: 300, totalCost: 700 },
        { id: 4, name: "Card A", comp: 600, totalCost: 1100 },
        { id: 5, name: "Card B", comp: 400, totalCost: 900 },
        { id: 6, name: "Card C", comp: 300, totalCost: 700 },
      ],
    },
    {
      id: 8,
      serialNumber: 'SN67891',
      type: 'Hero',
      status: 'Packed',
      series: 2002,
      profit: 1500,
      location: 'Arlington St',
      containsUnicorn: true,
      details: [
        { id: 1, name: "Card X", comp: 700, totalCost: 1200 },
        { id: 2, name: "Card Y", comp: 500, totalCost: 1000 },
        { id: 3, name: "Card Z", comp: 400, totalCost: 800 },
        { id: 4, name: "Card X", comp: 700, totalCost: 1200 },
        { id: 5, name: "Card Y", comp: 500, totalCost: 1000 },
        { id: 6, name: "Card Z", comp: 400, totalCost: 800 },
      ],
    },
    {
      id: 9,
      serialNumber: 'SN98765',
      type: 'Sabre',
      status: 'Allocated to WTH2',
      series: 4004,
      profit: 2800,
      location: 'Warehouse 2',
      containsUnicorn: true,
      details: [
        { id: 1, name: "Card X", comp: 800, totalCost: 1300 },
        { id: 2, name: "Card Y", comp: 600, totalCost: 1100 },
        { id: 3, name: "Card Z", comp: 500, totalCost: 900 },
        { id: 4, name: "Card X", comp: 800, totalCost: 1300 },
        { id: 5, name: "Card Y", comp: 600, totalCost: 1100 },
        { id: 6, name: "Card Z", comp: 500, totalCost: 900 },
      ],
    },
    {
        id: 10,
        serialNumber: 'SN12345',
        type: 'Prophecy',
        status: 'Reserved',
        series: 1001,
        profit: 1500,
        location: 'College Ave',
        containsUnicorn: false,
        details: [
          { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 2, name: "Card B", comp: 300, totalCost: 800 },
          { id: 3, name: "Card C", comp: 200, totalCost: 600 },
          { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 5, name: "Card B", comp: 300, totalCost: 800 },
          { id: 6, name: "Card C", comp: 200, totalCost: 600 },
        ],
      },
      {
        id: 11,
        serialNumber: 'SN12345',
        type: 'Prophecy',
        status: 'Reserved',
        series: 1001,
        profit: 1500,
        location: 'College Ave',
        containsUnicorn: false,
        details: [
          { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 2, name: "Card B", comp: 300, totalCost: 800 },
          { id: 3, name: "Card C", comp: 200, totalCost: 600 },
          { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 5, name: "Card B", comp: 300, totalCost: 800 },
          { id: 6, name: "Card C", comp: 200, totalCost: 600 },
        ],
      },
      {
        id: 12,
        serialNumber: 'SN67890',
        type: 'Hero',
        status: 'Packed',
        series: 2002,
        profit: 1200,
        location: 'Arlington St',
        containsUnicorn: true,
        details: [
          { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 2, name: "Card B", comp: 300, totalCost: 800 },
          { id: 3, name: "Card C", comp: 200, totalCost: 600 },
          { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 5, name: "Card B", comp: 300, totalCost: 800 },
          { id: 6, name: "Card C", comp: 200, totalCost: 600 },
        ],
      },
      {
        id: 13,
        serialNumber: 'SN54321',
        type: 'Pantheon',
        status: 'Allocated to WTH2',
        series: 3003,
        profit: 900,
        location: 'Warehouse 2',
        containsUnicorn: false,
        details: [
          { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 2, name: "Card B", comp: 300, totalCost: 800 },
          { id: 3, name: "Card C", comp: 200, totalCost: 600 },
          { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 5, name: "Card B", comp: 300, totalCost: 800 },
          { id: 6, name: "Card C", comp: 200, totalCost: 600 },
        ],
      },
      {
        id: 14,
        serialNumber: 'SN24680',
        type: 'Sabre',
        status: 'Allocated to WTH1',
        series: 4004,
        profit: 3000,
        location: 'College Ave',
        containsUnicorn: true,
        details: [
          { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 2, name: "Card B", comp: 300, totalCost: 800 },
          { id: 3, name: "Card C", comp: 200, totalCost: 600 },
          { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 5, name: "Card B", comp: 300, totalCost: 800 },
          { id: 6, name: "Card C", comp: 200, totalCost: 600 },
        ],
      },
      {
        id: 15,
        serialNumber: 'SN13579',
        type: 'Hero',
        status: 'Packed',
        series: 5005,
        profit: 1200,
        location: 'Warehouse 1',
        containsUnicorn: false,
        details: [
          { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 2, name: "Card B", comp: 300, totalCost: 800 },
          { id: 3, name: "Card C", comp: 200, totalCost: 600 },
          { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 5, name: "Card B", comp: 300, totalCost: 800 },
          { id: 6, name: "Card C", comp: 200, totalCost: 600 },
        ],
      },
      {
        id: 16,
        serialNumber: 'SN97531',
        type: 'Hero',
        status: 'Packed',
        series: 5005,
        profit: 1300,
        location: 'Warehouse 1',
        containsUnicorn: true,
        details: [
          { id: 1, name: "Card X", comp: 600, totalCost: 1100 },
          { id: 2, name: "Card Y", comp: 400, totalCost: 900 },
          { id: 3, name: "Card Z", comp: 300, totalCost: 700 },
          { id: 4, name: "Card X", comp: 600, totalCost: 1100 },
          { id: 5, name: "Card Y", comp: 400, totalCost: 900 },
          { id: 6, name: "Card Z", comp: 300, totalCost: 700 },
        ],
      },
      {
        id: 17,
        serialNumber: 'SN24681',
        type: 'Prophecy',
        status: 'Reserved',
        series: 1001,
        profit: 1600,
        location: 'College Ave',
        containsUnicorn: false,
        details: [
          { id: 1, name: "Card A", comp: 600, totalCost: 1100 },
          { id: 2, name: "Card B", comp: 400, totalCost: 900 },
          { id: 3, name: "Card C", comp: 300, totalCost: 700 },
          { id: 4, name: "Card A", comp: 600, totalCost: 1100 },
          { id: 5, name: "Card B", comp: 400, totalCost: 900 },
          { id: 6, name: "Card C", comp: 300, totalCost: 700 },
        ],
      },
      {
        id: 18,
        serialNumber: 'SN67891',
        type: 'Hero',
        status: 'Packed',
        series: 2002,
        profit: 1500,
        location: 'Arlington St',
        containsUnicorn: true,
        details: [
          { id: 1, name: "Card X", comp: 700, totalCost: 1200 },
          { id: 2, name: "Card Y", comp: 500, totalCost: 1000 },
          { id: 3, name: "Card Z", comp: 400, totalCost: 800 },
          { id: 4, name: "Card X", comp: 700, totalCost: 1200 },
          { id: 5, name: "Card Y", comp: 500, totalCost: 1000 },
          { id: 6, name: "Card Z", comp: 400, totalCost: 800 },
        ],
      },
      {
        id: 19,
        serialNumber: 'SN98765',
        type: 'Sabre',
        status: 'Allocated to WTH2',
        series: 4004,
        profit: 2800,
        location: 'Warehouse 2',
        containsUnicorn: true,
        details: [
          { id: 1, name: "Card X", comp: 800, totalCost: 1300 },
          { id: 2, name: "Card Y", comp: 600, totalCost: 1100 },
          { id: 3, name: "Card Z", comp: 500, totalCost: 900 },
          { id: 4, name: "Card X", comp: 800, totalCost: 1300 },
          { id: 5, name: "Card Y", comp: 600, totalCost: 1100 },
          { id: 6, name: "Card Z", comp: 500, totalCost: 900 },
        ],
      },
      {
        id: 20,
        serialNumber: 'SN12345',
        type: 'Prophecy',
        status: 'Reserved',
        series: 1001,
        profit: 1500,
        location: 'College Ave',
        containsUnicorn: false,
        details: [
          { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 2, name: "Card B", comp: 300, totalCost: 800 },
          { id: 3, name: "Card C", comp: 200, totalCost: 600 },
          { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 5, name: "Card B", comp: 300, totalCost: 800 },
          { id: 6, name: "Card C", comp: 200, totalCost: 600 },
        ],
      },
      {
        id: 21,
        serialNumber: 'SN12345',
        type: 'Prophecy',
        status: 'Reserved',
        series: 1001,
        profit: 1500,
        location: 'College Ave',
        containsUnicorn: false,
        details: [
          { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 2, name: "Card B", comp: 300, totalCost: 800 },
          { id: 3, name: "Card C", comp: 200, totalCost: 600 },
          { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 5, name: "Card B", comp: 300, totalCost: 800 },
          { id: 6, name: "Card C", comp: 200, totalCost: 600 },
        ],
      },
      {
        id: 22,
        serialNumber: 'SN67890',
        type: 'Hero',
        status: 'Packed',
        series: 2002,
        profit: 1200,
        location: 'Arlington St',
        containsUnicorn: true,
        details: [
          { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 2, name: "Card B", comp: 300, totalCost: 800 },
          { id: 3, name: "Card C", comp: 200, totalCost: 600 },
          { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 5, name: "Card B", comp: 300, totalCost: 800 },
          { id: 6, name: "Card C", comp: 200, totalCost: 600 },
        ],
      },
      {
        id: 23,
        serialNumber: 'SN54321',
        type: 'Pantheon',
        status: 'Allocated to WTH2',
        series: 3003,
        profit: 900,
        location: 'Warehouse 2',
        containsUnicorn: false,
        details: [
          { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 2, name: "Card B", comp: 300, totalCost: 800 },
          { id: 3, name: "Card C", comp: 200, totalCost: 600 },
          { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 5, name: "Card B", comp: 300, totalCost: 800 },
          { id: 6, name: "Card C", comp: 200, totalCost: 600 },
        ],
      },
      {
        id: 24,
        serialNumber: 'SN24680',
        type: 'Sabre',
        status: 'Allocated to WTH1',
        series: 4004,
        profit: 3000,
        location: 'College Ave',
        containsUnicorn: true,
        details: [
          { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 2, name: "Card B", comp: 300, totalCost: 800 },
          { id: 3, name: "Card C", comp: 200, totalCost: 600 },
          { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 5, name: "Card B", comp: 300, totalCost: 800 },
          { id: 6, name: "Card C", comp: 200, totalCost: 600 },
        ],
      },
      {
        id: 25,
        serialNumber: 'SN13579',
        type: 'Hero',
        status: 'Packed',
        series: 5005,
        profit: 1200,
        location: 'Warehouse 1',
        containsUnicorn: false,
        details: [
          { id: 1, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 2, name: "Card B", comp: 300, totalCost: 800 },
          { id: 3, name: "Card C", comp: 200, totalCost: 600 },
          { id: 4, name: "Card A", comp: 500, totalCost: 1000 },
          { id: 5, name: "Card B", comp: 300, totalCost: 800 },
          { id: 6, name: "Card C", comp: 200, totalCost: 600 },
        ],
      },
      {
        id: 26,
        serialNumber: 'SN97531',
        type: 'Hero',
        status: 'Packed',
        series: 5005,
        profit: 1300,
        location: 'Warehouse 1',
        containsUnicorn: true,
        details: [
          { id: 1, name: "Card X", comp: 600, totalCost: 1100 },
          { id: 2, name: "Card Y", comp: 400, totalCost: 900 },
          { id: 3, name: "Card Z", comp: 300, totalCost: 700 },
          { id: 4, name: "Card X", comp: 600, totalCost: 1100 },
          { id: 5, name: "Card Y", comp: 400, totalCost: 900 },
          { id: 6, name: "Card Z", comp: 300, totalCost: 700 },
        ],
      },
      {
        id: 27,
        serialNumber: 'SN24681',
        type: 'Prophecy',
        status: 'Reserved',
        series: 1001,
        profit: 1600,
        location: 'College Ave',
        containsUnicorn: false,
        details: [
          { id: 1, name: "Card A", comp: 600, totalCost: 1100 },
          { id: 2, name: "Card B", comp: 400, totalCost: 900 },
          { id: 3, name: "Card C", comp: 300, totalCost: 700 },
          { id: 4, name: "Card A", comp: 600, totalCost: 1100 },
          { id: 5, name: "Card B", comp: 400, totalCost: 900 },
          { id: 6, name: "Card C", comp: 300, totalCost: 700 },
        ],
      },
      {
        id: 28,
        serialNumber: 'SN67891',
        type: 'Hero',
        status: 'Packed',
        series: 2002,
        profit: 1500,
        location: 'Arlington St',
        containsUnicorn: true,
        details: [
          { id: 1, name: "Card X", comp: 700, totalCost: 1200 },
          { id: 2, name: "Card Y", comp: 500, totalCost: 1000 },
          { id: 3, name: "Card Z", comp: 400, totalCost: 800 },
          { id: 4, name: "Card X", comp: 700, totalCost: 1200 },
          { id: 5, name: "Card Y", comp: 500, totalCost: 1000 },
          { id: 6, name: "Card Z", comp: 400, totalCost: 800 },
        ],
      },
      {
        id: 29,
        serialNumber: 'SN98765',
        type: 'Sabre',
        status: 'Allocated to WTH2',
        series: 4004,
        profit: 2800,
        location: 'Warehouse 2',
        containsUnicorn: true,
        details: [
          { id: 1, name: "Card X", comp: 800, totalCost: 1300 },
          { id: 2, name: "Card Y", comp: 600, totalCost: 1100 },
          { id: 3, name: "Card Z", comp: 500, totalCost: 900 },
          { id: 4, name: "Card X", comp: 800, totalCost: 1300 },
          { id: 5, name: "Card Y", comp: 600, totalCost: 1100 },
          { id: 6, name: "Card Z", comp: 500, totalCost: 900 },
        ],
      },
  ];

export default mockData;
