import React, {useState, useEffect, useCallback} from "react"
import { Button, Dialog, DialogActions, DialogTitle, DialogContent,
    TextField, Select, MenuItem, Input, DialogContentText } from "@mui/material";

import useFileUploader from "../CommonUtility/useFileUploader";
import useFetch from "../CommonUtility/useFetch";

export default function SkuUploaderButton(props) {
    const {selectedFile, setSelectedFile, upload} = useFileUploader("/Upload/")
    const [open, setOpen] = useState(false);
    const {data: skuTypes, loading} = useFetch("/Card/all/skutypes")
    const [selectedValue, setSelectedValue] = useState()
    const handleClickOpen = () => {
        setOpen(true)
    }
    useEffect(() => {
      setSelectedValue(skuTypes[0])
    }, [skuTypes])
    
    const handleClose = () => {
        setOpen(false)
        setSelectedFile(null)        
        setSelectedValue()
    }
    const handleSubmit = () => {
        if (selectedValue != null && selectedValue != undefined) {
            upload("card/skutype/"+selectedValue.name)
        }
    }
    const renderOption = (item) => {
        return <MenuItem key={item.id} value={item.name} onClick={selectChange}>{item.name}</MenuItem>
    }
    const selectChange = (event) => {
        setSelectedValue(event.target.value)
    }
    const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};
    const getFileError = () => {
        return selectedFile == null ? true : false
    }
    const getSelectedError = () => {
        return selectedValue == null ? true : false
    }
    if (loading) return <></>

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Bulk Upload Skus</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter in a 2 column based CSV file where the first
                        header 
                    </DialogContentText>
                    <Select error={getSelectedError()} value={selectedValue} onChange={selectChange}>
                        {skuTypes.map(item => renderOption(item))}
                    </Select>
                    <Input error={getFileError() } onChange={changeHandler} type="file" name="file">Upload file</Input>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
            <Button 
                onClick={handleClickOpen}
                
            >Upload Skus</Button>
        </>
    )
}