import { PageState } from "../CommonTypes/PageStateType";


export default {
  setPageTo: (state: PageState, pageNum: number) => {
    state.page = pageNum;
    return state;
  },

  setPageSize: (state: PageState, pageSize: number) => {
    state.pageSize = pageSize;
    return state;
  },

  setTotal: (state: PageState, total: number) => {
    state.total = total;
    return state;
  },
};
