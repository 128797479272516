import React, { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { GridColDef } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridRowId } from "@mui/x-data-grid";
import RepackDetails from "./RepackDetails";

interface RepackDetails {
  id: number;
  name: string;
  comp: number;
  totalCost: number;
}

interface Repack {
  id: number;
  serialNumber: string;
  type: string;
  status: string;
  series: number;
  profit: number;
  location: string;
  containsUnicorn: boolean;
  details: RepackDetails[];
}

interface TabsProps {
  repacks: Repack[];
}

const RepackTabs: React.FC<TabsProps> = ({ repacks }) => {
  const [value, setValue] = useState("all");
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] =
    useState<GridRowId[]>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const columns: GridColDef[] = [
    { field: "serialNumber", headerName: "Serial Number", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "series", headerName: "Series", flex: 1 },
    { field: "id", headerName: "ID", flex: 1 },
    { field: "profit", headerName: "Profit", flex: 1 },
    { field: "location", headerName: "Location", flex: 1 },
    {
      field: "containsUnicorn",
      headerName: "Contains Unicorn",
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <CheckIcon style={{ color: "green" }} />
        ) : (
          <CloseIcon style={{ color: "red" }} />
        ),
    },
  ];

  const getUniqueTypes = () => {
    const typesSet = new Set<string>();
    repacks.forEach((item) => {
      typesSet.add(item.type);
    });
    return Array.from(typesSet);
  };

  const handleDetailPanelExpandedRowIdsChange = useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds
      );
    },
    []
  );

  const detailPanelContent = useCallback((row: any) => {
    return <RepackDetails details={row.row.details} />;
  }, []);

  const uniqueTypes = getUniqueTypes();

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {['all', ...uniqueTypes].map((type) => (
              <Tab key={type} label={type === 'all' ? 'All' : type} value={type} />
            ))}
          </TabList>
        </Box>
        {['all', ...uniqueTypes].map((type) => (
          <TabPanel key={type} value={type}>
            <div style={{ height: 600, width: "100%" }}>
              <DataGridPro
                rows={type === 'all' ? repacks : repacks.filter((item) => item.type === type)}
                columns={columns}
                onDetailPanelExpandedRowIdsChange={
                  handleDetailPanelExpandedRowIdsChange
                }
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                getDetailPanelContent={detailPanelContent}
                getDetailPanelHeight={({ row }) => "auto"}
              />
            </div>
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default RepackTabs;
