import { Box } from '@mui/material';
import RepackViewer from '../Dash/RepackPage/RepackViewer/RepackViewer';

const RepackPage = () => (
    <Box
        sx={{
            width: '100%',
            height: '100%',
            padding: '5px'
        }}
        >
        <RepackViewer/>

    </Box>
  );
  
  
  export default RepackPage;