import React, { useState } from "react";
import { FormControl, TextField } from "@material-ui/core";

interface StringFilterProps {
  filterModel: any;
  setFilterModel: any;
  refresh: any;
  setRefresh: any;
  id: string;
  field: string;
  operator: string;
}

const extractInitialValueFromFilter = (model: { items: { field: string, operator: string, value: any }[] }, field: string, operator: string) => {
    const filter = model.items.find((item) => item.field === field && item.operator === operator);
    return filter?.value || [];
};


const StringFilter: React.FC<StringFilterProps> = ({
  id,
  filterModel,
  setFilterModel,
  field,
  operator,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(extractInitialValueFromFilter(filterModel, field, operator));

  const handleChange = (event: React.ChangeEvent<any>) => {
    const val: string =
      typeof event.target.value === "string"
        ? event.target.value
        : event.target.value
        ? event.target.value.label
        : "";
    setSelectedValue(val);

    const updatedFilterModel = {
      ...filterModel,
      items: filterModel.items.map((item: any) => {
        if (item.field === field) {
          return {
            ...item,
            value: selectedValue,
          };
        }
        return item;
      }),
    };
    setFilterModel(updatedFilterModel);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value || "");
    handleChange(event);
  };

  return (
    <div>
      <FormControl>
        <TextField
          margin="dense"
          id={id}
          label="Value"
          type="text"
          variant="standard"
          style={{ fontSize: "0.75rem" }}
          onChange={handleInputChange}
          value={selectedValue}
        />
      </FormControl>
    </div>
  );
};

export default StringFilter;
