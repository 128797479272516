import React, { Component } from 'react';
import { Button, InputGroup, Input, Table} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../Hamburger.css';
import FetchModule from "../../../Modules/FetchModule";
import CurrentPantheonProducts from './CurrentPantheonProducts';
const fetchData = new FetchModule();

export default class PantheonBuilderMain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productsToShow: [],
            products: {},
            series: null,
            pantheonProduct: null,
            searchText: "",
            currentProducts: {},
            maxAmount: 5,
            currentSortBy: "price",
            sortBy: "price",
            displayState: 0,
            nameDisplay: "none",
            priceDisplay: "none",
            quantityDisplay: "none",

        }
        localStorage.getItem('user')
    }

    async componentDidMount() {
        const response = await fetchData.fetchResponseAuthed("/Pantheon/get/products/"+this.props.series, "GET")
        if (response.status === 200) {
            const data = await response.json()
            this.updateState('pantheonProduct', data.product)
            this.updateState('series', data.series)
            this.updateState('products', data.products, () => {this.updateProductDisplay()})
            var currentProds = {}
            var defaultVal = {}
            try {
                currentProds = JSON.parse(localStorage.getItem('pantheonProducts'))
                if (currentProds === 'undefined' || currentProds === null) {
                    localStorage.setItem('pantheonProducts', JSON.stringify(defaultVal))
                    currentProds = defaultVal
                }
            } catch (exc) {
                localStorage.setItem('pantheonProducts', JSON.stringify({}))
            }
            currentProds = JSON.parse(localStorage.getItem('pantheonProducts'))
            this.updateState("currentProducts", currentProds)
        }
    }
    updateProductDisplay = () => {
        let text = this.state.searchText
        var tarId = this.state.currentSortBy
        this.setState({productsToShow:
            Object.values(this.state.products)
                .filter(product => {
                    var containsAll = true;
                    text.split(' ').forEach(word => {
                        if (!product.name.toLowerCase().includes(word.toLowerCase())) {
                            containsAll = false;
                        }
                    })
                    text.split(' ').forEach(sku => {
                        if (sku.toLowerCase() == product.sku.toLowerCase()) {
                            containsAll = true;
                        }
                    })
                    return containsAll ? product : null;
                }).sort((a, b) => {
                    if (this.state.displayState === 0) {
                        if (this.state.currentSortBy === "priceDisplay") {
                            return b.averageCost - a.averageCost
                        }
                        let x = tarId === "nameDisplay" ? a.name : (a.onHand - a.allocated)
                        let y = tarId === "nameDisplay" ? b.name : (b.onHand - b.allocated)
                        return x === y ? 0 : x < y ? 1 : -1

                    } else if (this.state.displayState === 1) {
                        if (this.state.currentSortBy === "priceDisplay") {
                            return a.averageCost - b.averageCost
                        }
                        let x = tarId === "nameDisplay" ? a.name : (a.onHand - a.allocated)
                        let y = tarId === "nameDisplay" ? b.name : (b.onHand - b.allocated)
                        return x === y ? 0 : x > y ? 1 : -1
                    } 
                    let x = tarId === "nameDisplay" ? a.name : (a.onHand - a.allocated)
                    let y = tarId === "nameDisplay" ? b.name : (b.onHand - b.allocated)
                    return x === y ? 0 : x < y ? 1 : -1;
                })
        })
    }
    updateCurrentProducts = (prods) => {
        localStorage.setItem("pantheonProducts", JSON.stringify(prods))
        this.updateState('currentProducts', prods)
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }

    addProduct = (event) => {
        var prod = this.state.products[event.target.value]
        var prods = this.state.currentProducts
        var max = this.state.maxAmount
        var currentAmount = 0
        Object.values(this.state.currentProducts).forEach(prod => {
            currentAmount += prod.quantity;
        })
        if (currentAmount < max) {
            if (prods[event.target.value] !== undefined) {
                prods[event.target.value].quantity += 1
            } else {
                prods[event.target.value] = {
                    product: prod,
                    quantity: 1,
                }
            }
            if (prods[event.target.value].quantity > prod.onHand-prod.allocated){
                prods[event.target.value].quantity = prod.onHand - prod.allocated
            }
            this.updateCurrentProducts(prods)
        }
    }
    getProduct = (product) => {
        var leftOver = product.onHand - product.allocated
        var amt = product.averageCost.toFixed(2)
        return (
            <tr key={product.id}>
                <td>{product.name}</td>
                <td>{leftOver}</td>
                <td>${amt}</td>
                <td><Button color="primary"
                        value={product.id}
                        onClick={this.addProduct}
                    >+</Button>
                </td>
            </tr>
        )
    }
    getProducts = () => {
        if (this.state.products != null) {
            return this.state.productsToShow.map(product => this.getProduct(product))
        }
    }
    textChange = (event) => {
        this.updateState("searchText", event.target.value, () => {        
            this.updateProductDisplay();
        })
    }
    sortChange = (event) => {
        if (event.target.id === "nameDisplay" || event.target.id === "priceDisplay" || event.target.id === "quantityDisplay" ) {
            this.setState({currentSortBy: event.target.id})
            this.setState({nameDisplay: "none"})
            this.setState({quantityDisplay: "none"})
            this.setState({priceDisplay: "none"})
            if (event.target.id !== this.state.currentSortBy) {
                this.setState({displayState: 0}, () => {
                    this.updateProductDisplay(this.state.searchText, event.target.id);
                    this.updateState(event.target.id, "down")
                })
            } else {
                this.setState({displayState: (this.state.displayState + 1) % 3}, () => {
                    this.updateProductDisplay(this.state.searchText, event.target.id);
                    if (this.state.displayState === 0) {
                        this.updateState(event.target.id, "down")
                    } else if (this.state.displayState === 1) {
                        this.updateState(event.target.id, "up")
                    } else {
                        this.updateState(event.target.id, "none")
                    }
                })
            }
        }
    }
    getLeft =() => {
        return (
        <div className="main-card searchCard">
            <InputGroup className="whole-search" >
                <div className="fit-search search-bar">
                    <Input className="fit-search" id="searchText" 
                        value={this.state.searchText}
                        onChange={this.textChange}
                        placeholder="Search"
                    />
                    <FontAwesomeIcon onClick={this.clearText} className="search-box-clear" icon="xmark" />
                </div>
            </InputGroup>
            <Table className="fixTable"  onClick={this.sortChange} size="sm" bordered
                style={{marginBottom: 0}}>
                <thead>
                    <tr className="thead-fix">
                        <th style={{cursor: 'pointer'}}>
                            <div id={"nameDisplay"}>
                                Name&nbsp;<i  className={"arrow " +this.state.nameDisplay} />
                            </div>
                        </th>                        
                        <th style={{cursor: 'pointer'}}>
                            <div id={"quantityDisplay"}>
                                Quantity&nbsp;<i  className={"arrow " +this.state.quantityDisplay} />
                            </div>
                        </th>                        
                        <th style={{cursor: 'pointer'}}>
                            <div  id={"priceDisplay"}>
                                Price&nbsp;<i  className={"arrow " +this.state.priceDisplay} />
                            </div>
                        </th>
                        <th>Add</th>
                   </tr>
                </thead>
                <tbody className="tableBody">
                    {this.getProducts()}
                </tbody>
            </Table>
        </div>
        )
    }
    getRight = () => {
        return (
            <CurrentPantheonProducts 
                updateCurrentProducts={this.updateCurrentProducts}
                updateState ={this.updateState}
                updateProductDisplay={this.updateProductDisplay}
                currentProducts={this.state.currentProducts}
                series={this.props.series}
            />
        )
    }
    render() {
        return (
            <div className="dashboard-body">
                {this.getLeft()}
                {this.getRight()}
            </div>
        )
    }

}
