import React from "react";
import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
import { useFeatureFlags } from "../Contexts/FeatureFlagContexts/FeatureFlagContext";

const Homepage = (props) => {
  var {flags} = useFeatureFlags();
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
      {flags["cards"] && (
        <>
          <Card
            sx={{ maxWidth: "calc(50% - 10px)", maxHeight: 750, margin: 5 }}
            variant="outlined"
            style={{ cursor: "pointer" }}
          >
            <CardActionArea component={Link} to="/dashboard/cards">
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Single Cards Page
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Navigate to the Single Cards spreadsheet
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            sx={{ maxWidth: "calc(50% - 10px)", maxHeight: 750, margin: 5 }}
            variant="outlined"
            style={{ cursor: "pointer" }}
          >
            <CardActionArea component={Link} to="/dashboard/sku/correction">
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Sku Correction Page
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Correct bad skus
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </>
      )}
      {flags["settings"] && (
        <Card
          sx={{ maxWidth: "calc(50% - 10px)", maxHeight: 750, margin: 5 }}
          variant="outlined"
          style={{ cursor: "pointer" }}
        >
          <CardActionArea component={Link} to="/dashboard/settings">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Settings Page
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Navigate to Settings
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
      {flags["repack"] && (
        <Card
          sx={{ maxWidth: "calc(50% - 10px)", maxHeight: 750, margin: 5 }}
          variant="outlined"
          style={{ cursor: "pointer" }}
        >
          <CardActionArea component={Link} to="/dashboard/repack/create">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Repack Creation
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Create Repacks
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
      <Card
        sx={{ maxWidth: "calc(50% - 10px)", maxHeight: 750, margin: 5 }}
        variant="outlined"
        style={{ cursor: "pointer" }}
      >
        {flags["repack"] && (
          <CardActionArea component={Link} to="/dashboard/repack/view">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                View Repacks
              </Typography>
              <Typography variant="body2" color="text.secondary">
                View Repacks
              </Typography>
            </CardContent>
          </CardActionArea>
        )}
      </Card>
      <Card
        sx={{ maxWidth: "calc(50% - 10px)", maxHeight: 750, margin: 5 }}
        variant="outlined"
        style={{ cursor: "pointer" }}
      >
        {flags["barcode"] && (
          <CardActionArea component={Link} to="/dashboard/barcode">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Missing Barcode Search
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Find Missing Barcode
              </Typography>
            </CardContent>
          </CardActionArea>
        )}
      </Card>
      <Card
        sx={{ maxWidth: "calc(50% - 10px)", maxHeight: 750, margin: 5 }}
        variant="outlined"
        style={{ cursor: "pointer" }}
      >
        {flags["showhosts"] && (
          <CardActionArea component={Link} to="/dashboard/showHosts">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Show Host Management Page
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Manage active show hosts
              </Typography>
            </CardContent>
          </CardActionArea>
        )}
      </Card>
    </div>
  );
};

export default Homepage;
