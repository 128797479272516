import React, { Component } from 'react';
import { Button, Input, Table, InputGroup} from 'reactstrap';
import '../Hamburger.css';
import './break.css';
import FetchModule from "../../Modules/FetchModule";
import NewBreak from "./NewBreak"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import DisassemblyModal from './DisassemblyModal';
library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);


const fetchData = new FetchModule();

export default class CreateBreaks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activePage: "breaks",
            currentProducts: {},
            products: {},
            productsToShow: [],
            searchText: "",
            league: "",
            breakType: "TR",
            originalProducts: {},
            isOpen: false,
            saving: false,
            disassembleProduct: null,
            productClicked: null,
            productSerialOptions: {},
        }
    }
 // STUFF FOR PRODUCT UPDATES:

    textChange = (event) => {
        this.updateState("searchText", event.target.value, () => {        
            this.updateProductDisplay();
        })
    }
    clearText = () => {
        this.setState({searchText: ""}, () =>{
            this.updateProductDisplay();
        })
    }
    updateCurrentProducts = (prods) => {
        if (this.props.breakState === "create") {
            localStorage.setItem("newBreakProducts", JSON.stringify(prods))
        }
        this.updateState('currentProducts', prods)
    }
    updateProductDisplay = () => {
        localStorage.setItem('newBreakType', this.state.breakType)
        let text = this.state.searchText
        var league = ''
        if (Object.values(this.state.currentProducts).length > 0) {
            league = Object.values(this.state.currentProducts)[0].product.additionalAttribute4
        }
        this.setState({productsToShow:
            Object.values(this.state.products)
                .filter(product => {
                    var containsAll = true;
                    //if ((this.state.breakType !== "giveaway" && this.state.breakType !== "personals") && !product.additionalAttribute4.toLowerCase().includes(league.toLowerCase())) {
                    //    return
                    //}
                    text.split(' ').forEach(word => {
                        if (!product.name.toLowerCase().includes(word.toLowerCase())) {
                            containsAll = false;
                        }
                    })
                    return containsAll ? product : null;
                })
        })
    }
    // DISASSEMBLY MODAL STUFF :::
    openModal = async (event) => {
        var prod =(event.target.getAttribute("product"))
        var response = await fetchData.fetchResponseAuthed("/Disassembly/get/"+prod, "GET")
        if (response.status === 200) {
            const data = await response.json()
            data["product"] = this.state.products[prod]
            this.updateState("disassembleProduct", data, () => {
                this.updateState("isOpen", true)
            })
        }
    }

    toggle = () => {
        if (!this.state.saving) {
            this.updateState("isOpen", !this.state.isOpen)
        }
    }
    getPrice = (price) => {
        if (price <= 0) {
            return "n/a"
        }
        return "$" + price.toFixed(2)
    }

    getNameFormat = (productName) => {
        //highlighting hobby boxes in an attempt to reduce mistakes
        if (productName.includes("Hobby Box")) {

            return <td class='hobbyHighlight'>{productName}</td>;
        }
        return <td>{productName}</td>;
    }

    // UPDATES PRODUCT DISPLAY ON RENDERS
    displayProduct = (product) => {
        var disabled = false
        if (product.category.toLowerCase().includes("pack") || product.category.toLowerCase().includes("merch")) {
            disabled = true
        }
        return (
            <tr key={product.sku}>
                <td>{product.sku}</td>
                {/*<td>{product.name}</td>*/}
                {this.getNameFormat(product.name)}
                <td>{product.onHand - product.allocated}</td>
                <td>{this.getPrice(product.priceTier3)}</td>
                <td><Button color="primary" onClick={this.addProduct} value={product.id}>+</Button></td>
                <td><Button product={product.id} disabled={disabled} onClick={this.openModal}>Disassemble</Button></td>
            </tr>
        )
    }
    getData = () => {
        return this.state.productsToShow.map(product => this.displayProduct(product))
    }
    addProduct = (event) => {
        var prod = this.state.products[event.target.value]
        var prods = this.state.currentProducts
        if (prod.category !== "Repacks") {
            if (prods[event.target.value] !== undefined) {
                prods[event.target.value].quantity += 1
            } else {
                prods[event.target.value] = {
                    product: prod,
                    quantity: 1,
                }
            }
            if (prods[event.target.value].quantity > prod.onHand-prod.allocated){
                prods[event.target.value].quantity = prod.onHand - prod.allocated
            }
        } else {
            let total = this.state.productSerialOptions[event.target.value].length
            if (prods[event.target.value] !== undefined && prods[event.target.value].quantity < total) {
                prods[event.target.value].quantity += 1
                prods[event.target.value].skus.push("")
            } else if (prods[event.target.value] === undefined){
                prods[event.target.value] = {
                    product: prod,
                    skus: [""],
                    quantity: 1
                }
            }
        }
        this.updateProductDisplay()
        //this.updateState("currentProducts", prods)
        this.updateCurrentProducts(prods)
    }
 // STUFF FOR COMPONENTS
    async componentDidMount() {
        var mySerialOptions
        var seriesResponse = await fetchData.fetchResponseAuthed("/Pantheon/get/all/serials", "GET")
        mySerialOptions = await seriesResponse.json()
        if (this.props.breakState !== "edit") {
            const response = await fetchData.fetchResponseAuthed("/Product/breaks", "GET");
            const data = await response.json()
            var defaultVal = {}
            var currentProds = {}
            //localStorage.setItem('newBreakProducts', JSON.stringify(defaultVal))
            try {
                currentProds = JSON.parse(localStorage.getItem('newBreakProducts'))
                if (currentProds === 'undefined') {
                    localStorage.setItem('newBreakProducts', JSON.stringify(defaultVal))
                    currentProds = defaultVal
                }
            } catch (exc) {
                localStorage.setItem('newBreakProducts', JSON.stringify({}))
            }
            var breakItem = localStorage.getItem('newBreakType')
            if (breakItem === 'null') {
                localStorage.setItem('newBreakType', "TR")
                breakItem = "TR"
            } 
            if (currentProds === null) {
                currentProds = {}
            }

            this.updateState("breakType", breakItem)
            this.updateState("currentProducts", currentProds)
            this.updateState("products", data)
        } else {
            let path = window.location.pathname
            let id = path.split('/')
            let breakId = id[4];
            const response = await fetchData.fetchResponseAuthed('/Break/edit/products/'+breakId, "GET")
            if (response.status === 200) {
                const data = await response.json()
                //Here is where you left off. Add the SKU data into the product skus for editing a product!!
                // Handle when there is a prod id and not a prod id and then add accordingly
                Object.keys(data.skus).forEach(key => {
                    if (mySerialOptions[key] !== null) {
                        mySerialOptions[key] = [...mySerialOptions[key], ...data.skus[key]]
                    } else {
                        mySerialOptions[key] = data.skus[key]
                    }
                })
                this.updateState('editData', data)
                this.updateState("products", data.allProducts)
                this.updateState("currentProducts", data.currentProducts);
                this.updateState("originalProducts", data.originalProducts)
            }
        }
        this.updateState("productSerialOptions", mySerialOptions, () => {
            this.updateProductDisplay();
        })
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }

    onClick = (event) => {
        this.updateState("activePage", event.target.id)
    }
    getActive = (val) => {
        return this.state.activePage === val ? "active" : ""
    }
    updateQuantities = (values) => {
        Object.values(values.assemblyProducts).forEach(prod => {
            var prodToUpdate = this.state.products[prod.ComponentProductID]
            prodToUpdate.onHand = prodToUpdate.onHand - prod.Quantity
        })
    }

    getSearchBody = () => {
        return (
            <div className="main-card searchCard">
                <InputGroup className="whole-search" >
                <div className="fit-search search-bar">
                    <Input className="fit-search" id="searchText" 
                        value={this.state.searchText}
                        onChange={this.textChange}
                        placeholder="Search"
                    />
                    <FontAwesomeIcon onClick={this.clearText} className="search-box-clear" icon="xmark" />
                </div>
                </InputGroup>
                <Table className="fixTable">
                        <thead>
                            <tr className="thead-fix">
                                <th>SKU</th>
                                <th>Name</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Add</th>
                                <th>Disassemble</th>
                           </tr>
                        </thead>
                        <tbody className="tableBody">
                            {this.getData()}
                        </tbody>
                    </Table>
            </div>
        )
    }
    render() {
        return (
        <div className="dashboard-nav-right-flip">
            {this.getSearchBody()}
            <NewBreak 
                session={this.props.session}
                breakType={this.state.breakType}
                updateProductDisplay={this.updateProductDisplay}
                updateQuantities={this.updateQuantities}
                navigate = {this.props.navigate}
                currentProducts={this.state.currentProducts}
                updateState={this.updateState}
                breakState={this.props.breakState}
                originalProducts={this.state.originalProducts}
                goTo={this.props.goTo}
                updateCurrentProducts={this.updateCurrentProducts}
                productSerialOptions={this.state.productSerialOptions}
            />
            <DisassemblyModal
                disassembly={this.state.disassembleProduct}
                toggle={this.toggle}
                isOpen={this.state.isOpen}
            />
        </div>
        )
    }

}
