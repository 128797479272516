import React, { Component } from 'react';
import FetchModule from "../Modules/FetchModule";
import { Button , Spinner} from 'reactstrap';
const fetchData = new FetchModule();

export default class DashMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disabled: false,
            active: "none"
        }
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    refreshCustomers = async () => { 
        this.updateState('disabled', true)
        this.updateState('active', '')
        const response = await fetchData.promise('/ProductBE/customer/update', true, 'POST')
        this.updateState('active', 'none')
        this.updateState('disabled', false)
    }

    refreshDB = async () => {
        this.updateState('disabled', true)
        this.updateState('active', '')
        const response =await fetchData.promise('/Refresh/dear', true, 'POST2')
        this.updateState('disabled', false)
        this.updateState('active', 'none')

    }
    read = async () => {
        this.updateState('disabled', true)
        this.updateState('active', '')
        var url = await fetchData.promise("/PriSync/update/prices", false, 'POST2')
        this.updateState('disabled', false)
        this.updateState('active', 'none')
    }
    render() {
        return (
            <div className={"dashboard-page "}>
                <Button 
                    disabled={this.state.disabled} 
                    color={this.state.color} 
                    onClick={this.refreshDB}>Refresh Dear Items</Button>

                <Button 
                    disabled={this.state.disabled}
                    color={this.state.color} 
                    onClick={this.read}>Refresh PriSync Prices</Button>

                <Spinner color="light" style={{display: this.state.active}}>

                </Spinner>
            </div>
        )
    }

}