import React, { useState } from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import "../singles.css";
import "../BulkCardsCreator/BulkAddCards.css"

interface StatusType {
  value: any;
  label: string;
}

interface SelectCellProps {
  id: number;
  field: string;
  value: { label: string };
  statusTypes: StatusType[];
  isFilter?: boolean;
  item?: { value: string };
  setFilterModel: (model: any) => void;
  filterModel: any;
  width?: number;
}

const SelectCell: React.FC<SelectCellProps> = (props) => {
  const apiRef = useGridApiContext();
  const { id, field, value, statusTypes, width, setFilterModel, filterModel } = props;
  const initialValueLabel = value?.label || props.item?.value || value?.label || "";

  const [selectedValue, setSelectedValue] = useState<string>(initialValueLabel);

  const handleChange = (event: any) => {
    const newValueLabel = event.target.value as string;
    const newVal = statusTypes.find((s) => s.label === newValueLabel);
    setSelectedValue(newValueLabel);
    apiRef.current.setEditCellValue({ id, field, value: newVal });

    if (filterModel && filterModel.items) {
      const updatedFilterModel = {
        ...filterModel,
        items: filterModel.items?.map((item: any) => {
          if (item.field === "status") {
            return {
              ...item,
              value: event.target.value ? event.target.value : "",
            };
          }
          return item;
        }),
      };
      props.setFilterModel(updatedFilterModel);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Tab") {
      event.preventDefault();
      const inputValue = event.currentTarget.value;
      const options = statusTypes;
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );

      if (filtered.length > 0) {
        handleChange({ target: { value: filtered[0].label } });
        event.currentTarget.blur();
      }
    }
  };

  return (
    <div className="select-container">
      <FormControl fullWidth>
        <Select
          size="small"
          fullWidth
          value={selectedValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          style={{
            width: width || 150,
          }}
          className="fourteen"
        >
          {statusTypes.map((status) => (
            <MenuItem
              key={status.value}
              value={status.value}
              className="fourteen"
            >
              {status.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectCell;
