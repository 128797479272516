import React, { Component } from 'react';
import {useNavigate} from "react-router-dom";
import { Button, Table, InputGroup, Dropdown, Input } from 'reactstrap';
import '../Hamburger.css';
import './break.css';
import FetchModule from "../../Modules/FetchModule";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);

const fetchData = new FetchModule();

export default class BreakList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hover: "",
            sessionId: 0,
            price: -1,
            product: null,
            changed: false,
            disabled: true,
        }
        let path = window.location.pathname
        let id = path.split('/')
        this.state.sessionId = id[3]
        this.state.price = this.props.product.price
        this.state.product = this.props.product
        if (this.props.session != undefined && this.props.session.saleId == "") {
            this.state.disabled = false
        }
    }

    openBreak = (event) => {
        this.props.navigate("/dashboard/breaks/" +this.state.sessionId+"/"+this.props.product.breakID+"/slots")
    }
    updateChange = () => {
        if (this.state.price == this.props.product.price) {
            this.setState({changed: false})
        } else {
            this.setState({changed: true})
        }
    }
    onChange = (event) => {
        this.setState({price: event.target.value}, () => {
            this.updateChange();
        })
    }
    cancel = () => {
        this.setState({price: this.props.product.price}, () => {
            this.updateChange();
        })
    }
    save = async () => {
        var product = this.state.product;
        product.price = this.state.price;
        var response = await fetchData.promise("/Break/update", true, "POST", product)
        if (response.status == 200) {
            const data = await response.json()
            this.props.product.price = data.price
            this.setState({product: data});
            this.setState({price: product.price}, () => {
                this.updateChange()
            })
        }
    }
    getPriceInput = () => {
        if (this.state.disabled) {
            return (<div>{this.state.price}</div>)
        }
            return (
                <InputGroup>
                   <Input 
                        onChange={this.onChange} 
                        value={this.state.price}
                        type="number"
                    />
                    {this.getButtons()}
                </InputGroup>
            )

    }
    getButtons = () => { 
        if (this.state.changed) {
            return (
            <div>
                <Button color="danger" onClick={this.cancel}>Cancel</Button>
                    <Button color="primary" onClick={this.save}>Save</Button>
            </div>
            )   
        }
    }
    getEditToolTip = () => {
        return (
            <div className="small-text toolTip">
                <span className="tooltiptext">Profit Margins for how much we are making on this product on average</span>    
            </div>
        )
    }
    render() {
        if (this.props.product.breakType == "giveaway") {
            return (<tr>
                <td>{this.props.product.breakSKU}</td>
                <td>{this.props.product.name}</td>
                <td>{this.props.product.breakType}</td>
                <td>n/a</td>
                <td>{this.props.product.date}</td>
            </tr>)
        }
        return (
            <tr>
                <td>{this.props.product.breakSKU}</td>
                <td>{this.props.product.name}</td>
                <td>{this.props.product.description}</td>
                <td>{this.props.product.breakType}</td>
                <td>{this.props.product.date}</td>
                <td>{this.getPriceInput()}</td>
                <td>
                    <Button onClick={this.openBreak} color="primary" disabled={this.state.disabled}>
                        Edit
                    </Button>
                </td>
            </tr>
        )
    }

}
