import React, { useEffect } from "react";
import { ModalHeader, ModalBody, ModalFooter, Label, Input } from "reactstrap";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import { Modal, Box, Typography, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GridToolbar, DataGridPro } from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";
import "./HistorianModal.css";

interface HistorianModalProps {
  name: string;
  open: boolean;
  onClose: () => void;
  data: {
    field: string;
    value: string;
    modifiedBy: string;
    modifiedDate: string;
  }[];
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  return formattedDate;
};

const HistorianModal: React.FC<HistorianModalProps> = ({
  open,
  onClose,
  data,
  name,
}) => {
  const columns: GridColDef[] = [
    { field: "field", headerName: "Field", flex: 1 },
    { field: "value", headerName: "Value", flex: 2 },
    { field: "modifiedBy", headerName: "Modified By", flex: 1 },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      flex: 1,
      valueFormatter: (params) => formatDate(params.value as string),
    },
  ];

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle>{name}</DialogTitle>
      <DialogContent>
        <DataGridPro
          rows={data.map((row, index) => ({ id: index, ...row }))}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HistorianModal;
