import { Dialog, DialogTitle, DialogContent, DialogContentText, Autocomplete, TextField, DialogActions } from "@mui/material";
import { Spinner, Button } from "reactstrap";
import { FileUploaderProvider, useFileUploader } from "../FileUploader/FileUploaderContext";
import SimpleFileUploader from "../FileUploader/SimpleFileUploader";
import { useState } from "react";

interface CardImportModalContentProps {
  open: boolean;
  setOpen: (name: boolean) => void;
  users: []
  setPurchasedBy: (name: string) => void;
  purchasedBy: string;
}


export default function CardImportModalContent(props: CardImportModalContentProps) {
  
  const {open, setOpen, users,setPurchasedBy,purchasedBy} = props;
  const { selectedFiles,setSelectedFiles,uploadToBackend,uploading } = useFileUploader();
  
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    

    await uploadToBackend();

    
    handleClose();
    
  };


  return(
    <>
      <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Import Cards</DialogTitle>

      <DialogContent>
        
        
            <DialogContentText>
              Please select a file to import. This file must be in the
              following format:
            </DialogContentText>
            
            <SimpleFileUploader 
              supportsText="Supports: .xlsx" 
              subText="Platforms: whatnot, fanatics, tiktok" 
              uploadHandledExternally={true}/>
            <Autocomplete
              options={users}
              renderInput={(params: any) => (
                <TextField {...params} label="Purchased By" />
              )}
              onChange={(event: any, newValue: any) => {
                setPurchasedBy(newValue);
              }}
              style={{ marginTop: 20 }} 
            />
            
          
        
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={!purchasedBy || !selectedFiles || uploading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  </>
  )



}