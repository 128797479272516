import React, { cloneElement, Component} from 'react';
import {useNavigate} from "react-router-dom";
import { Button, Table, InputGroup, Dropdown, Input, ModalBody, ModalHeader, Modal, ModalFooter } from 'reactstrap';
import '../Hamburger.css';
import FetchModule from "../../Modules/FetchModule";
import {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PersonalsItem from './PersonalsItem';
const fetchData = new FetchModule();

const useFetchGet = (url) => {
    const [data, setData] = useState(null)
    useEffect(() => {
        fetchData.fetchResponseAuthed(url, "GET")
            .then((res) => res.json())
            .then((data) => setData(data));

    }, [url])
    return [data, setData]
}

const Personals = (props) => {
    const [searchText, setSearchText] = useState("")
    const [data, setData] = useFetchGet("/Breaking/personals/"+props.session.id)
    const [updating, changeUpdating] = useState(false)
    async function removeItem (index, item) {
        if (!updating) {
            changeUpdating(true)
            await fetchData.promise("/Breaking/remove/personal/"+item.id, true, "DELETE", item)
                .then((res) => {
                    if (res.status === 200) {
                        var data2 = data
                        delete data2[index]
                        setData(data2)
                    }
                })
                
            changeUpdating(false)
        }
    }
    async function saveItem (item, name, customer, pantheon, price) {
        if (!updating) {
            changeUpdating(true)
            item.name = name
            item.pantheonSerial = pantheon
            item.customer = customer
            item.price = price
            await fetchData.promise("/Breaking/personals/update/", true, "PUT", item)
            .then((res) => {
                if (res.status === 200) {
                    return true
                }
            })
            changeUpdating(false)
        }
    }
    return (
        <div className="dashboard-nav-right-flip">
            <div className="main-card" style={{padding: "10px"}}>
            <div style={{height: '95%'}}>
                <InputGroup className="whole-search" >
                <div className="fit-search search-bar">
                    <Input className="fit-search" id="searchText" 
                        value={searchText}
                        onChange={event => {setSearchText(event.target.value)}}
                        placeholder="Search"
                    />
                    <FontAwesomeIcon 
                        onClick={() => {setSearchText("")}} 
                        className="search-box-clear" 
                        icon="xmark" />
                </div>
                </InputGroup>
                <Table className="fixTable">
                        <thead>
                            <tr className="thead-fix">
                                <th>Product</th>
                                <th>Personals Name</th>
                                <th>Customer</th>
                                <th>Price</th>
                                <th>Serial</th>
                                <th>Settings</th>
                                <th>Remove</th>
                           </tr>
                        </thead>
                        <tbody className="tableBody" style={{overflowY: "scroll"}}>
                            {data && 
                                data.map((item, index) => {
                                    return <PersonalsItem 
                                                index={index} 
                                                item={item}
                                                remove={removeItem}
                                                save={saveItem}
                                                updating={updating}
                                            />
                                })
                            }
                        </tbody>
                </Table>
            </div>           
            </div>

        </div>
    )
}
export default Personals
