import { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { AppBar, Avatar, Badge, Box, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Bell as BellIcon } from '../icons/bell';
import { UserCircle as UserCircleIcon } from '../icons/user-circle';
import { Users as UsersIcon } from '../icons/users';
import { DashboardSidebarContext } from '../../Pages/ReactContexts/NavigateContext';
import DashboardSettings from './DashboardSettings';
import { useUser } from '../CommonUtility/UserContext';
import ProfileMenu from './ProfileMenu'
const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = (props) => {
  const settingsRef = useRef(null);
  const onSidebarOpen = props.onSideBarOpen
  const {user} = useUser();
  const {isSidebarOpen, setSidebarOpen} = useContext(DashboardSidebarContext)

  function getPropSize() {
    return isSidebarOpen ? DashboardSettings.drawerWidth : DashboardSettings.drawerMinWidth
  }

  const [showProfileMenu, setShowProfileMenu] = useState(false);


  const handleAvatarClick = () => {
    setShowProfileMenu(true);
  };

  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: {...getPropSize()}
          },
          width: {
            lg: 'calc(100% - ' +getPropSize() +'px)'
          }
        }}
        >
        <Toolbar
          
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
          <IconButton
            onClick={() => setSidebarOpen(!isSidebarOpen)}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'inline-flex'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Typography color="primary">{user["username"]}</Typography>
          <Tooltip title="Notifications">
            <IconButton sx={{ ml: 1 }}>
              <Badge
                badgeContent={0}
                color="primary"
                variant="dot"
              >
                <BellIcon fontSize="small" />
              </Badge>
            </IconButton>
          </Tooltip>
          <ProfileMenu />
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};


