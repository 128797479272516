export default function LocalStorageMigrationProvider (props) {

  return [
    {
      "version": "0.0.0",
      "migrate": () => {
      }
    }
  ]


}