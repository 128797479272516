import DashboardLayout from "../Components/DashboardComponents/DashboardLayout"
import SettingsMenu from "../Dash/Settings/SettingsMenu"

import {Box} from '@mui/material'

const SettingsPage = () => (
    <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <SettingsMenu/>
    </Box>
)

export default SettingsPage;
