import React, { useState, useEffect } from 'react';
import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { DataGridPro, GridApiContext } from '@mui/x-data-grid-pro';
import {AutocompletePlayer} from '../AutocompleteComponents/AutocompletePlayer'
import FetchModule from '../../../Modules/FetchModule';
import { useLocalStorage } from '../../../Utilities/useLocalStorage';
import useFetchPromise from '../../../Components/CommonUtility/useFetchPromise';
import { useGridApiContext } from "@mui/x-data-grid";
import {Card} from '../Types/CardType';

import "../../SinglesPage/singles.css"
import '../../Hamburger.css';
import "./CustomToolbar.css";


interface ValueGetterParams {
    row: Card;
    valueGetter: (params: any) => string | number | boolean | Date | null | undefined;
}

interface BulkChangeStatusProps {
    selectedRows: Card[];
    statusTypes: any
    dataFields: any
    setDataFields: any
    players: any
    changeStatusOnLiveCells: any
}


export const BulkChangeStatus: React.FC<BulkChangeStatusProps> = ({ selectedRows, statusTypes, dataFields, setDataFields, players,changeStatusOnLiveCells }) => {
    const fetchPromise = useFetchPromise();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<string>('');
    const [changeState, setChangeState] = useState<string>('');
    const [open, toggleOpen] = useState(false);
    const [columnWidths, setColumnWidths] = useLocalStorage('cardColumnWidthsModal', {
        inventoryId: 100,
        player: 150,
        status: 150,
    });
    const apiRef = useGridApiContext();

    function changeColumnWidth(event: any) {
        const { field: columnId, width: newWidth } = event.colDef;
        setColumnWidths({
          ...columnWidths,
          [columnId]: newWidth,
        });
      }
    
      function updateNewSku(field: any, item: any) {
        var vals = dataFields;
      }
    
      function addNewSKU(field:any, item:any) {
        var vals = dataFields;
        vals[field]["items"].push(item);
        setDataFields(vals);
      }
    
      const renderSelectCell = (params: any) => {
        return (
          <FormControl>
            <InputLabel>Status</InputLabel>
            <Select
              value={selectedStatus}
              onChange={(event)  => {
                setSelectedStatus(event.target.value)
              }}
              label="Status"
            >
              {statusTypes.map((status: any, index: any) => (
                <MenuItem key={index} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      };
    
      const renderSelectDisplayCell = (params: any) => {
        return (
          <Typography className="select-display-cell">
            {params.row.status.label ? params.row.status.label : ""}
          </Typography>
        );
      };
    
      const renderAutoCompleteCellPlayer = (params: any) => {
        return (
          <AutocompletePlayer
            className="autocomplete-player-cell"
            {...params}
            dataFields={players}
            addNewSKU={addNewSKU}
            updateNewSKU={updateNewSku}
            setModalOpen={setModalOpen}
          />
        );
      };
    
      const renderACDisplayCell = (params: any) => {
        return (
          <Typography className="ac-display-cell">
            {params.row.player.label ? params.row.player.label : ""}
          </Typography>
        );
      };
    
      const [rows, setRows] = React.useState(selectedRows);
      const columns: any = React.useMemo(() => {
    
        const hasAnyMoving = selectedRows.some((row) => (row.status.value === "PendingConfirmation" || row.status.value === "") && changeState === "InInventory");
        const baseColumns = [
          {
            field: "inventoryId",
            headerName: "Inventory Id",
            type: "number",
            width: columnWidths["inventoryId"],
            editable: false,
            valueGetter: (params: any) => params.row.inventoryId
          },
          {
            field: "player",
            headerName: "Player",
            width: columnWidths["player"],
            editable: false,
            renderEditCell: renderAutoCompleteCellPlayer,
            renderCell: renderACDisplayCell,
            type: "text",
            valueGetter: (params: ValueGetterParams) => {}
          },
          {
            field: "status",
            headerName: "Status",
            width: columnWidths["status"],
            editable: false,
            type: "string",
            renderEditCell: renderSelectCell,
            renderCell: renderSelectDisplayCell,
            valueGetter: (params: ValueGetterParams) => {}
          },
        ]
        if(!hasAnyMoving){
          return baseColumns;
        }
        else{
          baseColumns.push({
            field:"moving",
            headerName:"Where is Card Moving?",
            width:columnWidths["moving"],
            editable:false,
            type:"string",
            valueGetter: (params: ValueGetterParams) => {
                if ((params.row.grade?.label === "") && (params.row.status.value === "PendingConfirmation" || params.row.status.value === "")) {
                    return "Raw";
                } else if ((params.row.status.value === "PendingConfirmation" || params.row.status.value === "")) {
                    return "Graded";
                }
                return "";
            }

        })
          return baseColumns
        }
    
      
      return },[changeState]);
    
      const renderStatusTypeOptions = () => {
        if (Array.isArray(statusTypes)) {
          return statusTypes.map((statusType) => (
            <MenuItem key={statusType.publicId} value={statusType.value}>
              {statusType.label}
            </MenuItem>
          ));
        } else {
          return [];
        }
      };
    
    
      const [dialogValue, setDialogValue] = React.useState({
        lower: "",
        upper: "",
      });
    
      const handleClose = () => {
        setDialogValue({
          lower: "",
          upper: "",
        });
    
        toggleOpen(false);
      };
    
      const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
      
        if (changeState != "" && event?.type == "click") {
          var publicIds = selectedRows.map((card) => card.publicId);
          var body = {
            body: {
              cards: JSON.stringify(publicIds),
              status: changeState,
            },
          };
      
          fetchPromise("/Card/update/BulkChangeStatus", "POST", body);
          
          const ids = selectedRows.map(obj => obj.id);
          changeStatusOnLiveCells(ids, statusTypes.find((s: any) => s.label === changeState))
          
          handleClose();
        }
      };
    
      const onChange = (event: any) => {
        setChangeState(event.target.value);
      };
    
    return (
        <React.Fragment>
            <div>
        <Button
          sx={{ fontSize: "0.8125rem", padding: "4px 8px" }}
          disabled={selectedRows.length === 0}
          onClick={() => toggleOpen(true)}
        >
          Bulk Change Status
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Bulk Change Status</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Below are the cards you have selected to change status. Please
                select the new status you would like to change them to.
              </DialogContentText>
              <div className="bulk-change-status-datagrid">
                <DataGridPro
                  rows={selectedRows}
                  columns={columns}
                  onColumnWidthChange={changeColumnWidth}
                />
              </div>
            </DialogContent>
            <div className='bulk-change-status-select-div'>
              <InputLabel
                htmlFor="status-select"
              >
                Select a Status
              </InputLabel>

              <Select
                className='bulk-change-status-select'
                onChange={onChange}
              >
                {renderStatusTypeOptions()}
              </Select>
            </div>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" onClick={handleSubmit}>
                Change Status
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
        </React.Fragment>
    );
};

export default BulkChangeStatus;
