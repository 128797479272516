const compareObjects = (o1: any, o2: any) => {
  // Deep comparison for objects
  return JSON.stringify(o1) === JSON.stringify(o2);
};

const propagateChanges = (newRow: any, oldRow: any, rows: any) => {
  const changedFields: string[] = [];
  const refOldRow = { ...oldRow };
  const refNewRow = { ...newRow };

  for (const field in newRow) {
    if (newRow[field] !== refOldRow[field]) {
      changedFields.push(field);
    }
  }

  const updatedRows = rows.map((row: any) => {
    if (row.id === newRow.id) return { ...row }; 

    let updatedRow = { ...row };

    changedFields.forEach((field) => {
      if (compareObjects(row[field], refOldRow[field])) {
        updatedRow = { ...updatedRow, [field]: refNewRow[field] };
      }
    });

    return updatedRow;
  });

  return updatedRows;
};

export default propagateChanges;
