import React, { Component } from 'react';
import { Table, InputGroup, Input, ModalHeader, Modal, ModalBody } from 'reactstrap';
import "./pantheon.css";
import FetchModule from "../../../Modules/FetchModule";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);

const fetchData = new FetchModule();

export default class BoxesPantheon extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchText: "",
            products: [],
            productsToShow: [],
            modalProducts: {},
            currentBox: {},
            isOpen: false,
        }

    }

    async componentDidMount() {
        const response = await fetchData.fetchResponseAuthed("/Pantheon/get/boxes/"+this.props.series, "GET")
        if (response.status === 200) {
            const data = await response.json()
            this.updateState("products", data, () => {
                this.updateProductDisplay()
            })
        }
    }
    updateProductDisplay = () => {
        this.setState({productsToShow: this.state.products.filter(product => {
            var containsAll = true;
            this.state.searchText.split(' ').forEach(word => {
                if (!product.serialNumber.toLowerCase().includes(word.toLowerCase())) {
                    containsAll = false;
                }
            })
            return containsAll ? product : null;
        })})
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    textChange = (event) => {
        this.updateState('searchText', event.target.value, () => {
            this.updateProductDisplay()
        })
    }
    boxClicked = async (event) => {
        var amt = event.target.getAttribute("value")
        var serial = event.target.getAttribute("id")
        const response = await fetchData.fetchResponseAuthed("/Pantheon/get/box/products/"+amt, "GET")
        if (response.status === 200) {
            const data = await response.json()
            this.updateState("modalProducts", data, () => {
                this.updateState("currentBox", serial, () => {
                    this.toggleModal()
                })
            })
        }
    }
    editPantheon = () => {

    }
    getProduct = (product) => {
        return (
            <tr key={product.id} 
                className="tableHover"
                onClick={this.boxClicked}
            >
                <td value={product.id} 
                    id={product.serialNumber} 
                >    
                    {product.serialNumber}
                </td>
            </tr>
        )
    }
    getBody = () => {
        return this.state.productsToShow.map(product => this.getProduct(product))
    }
    toggleModal = () => {
        this.updateState("isOpen", !this.state.isOpen)
    }
    getModalProduct = (prod) => {
           return (
            <tr id={prod.id} key={prod.id}>
                <td>{prod.name}</td>
                <td>{prod.available}</td>
                <td>${(prod.averageCost * prod.available).toFixed(2)}</td>
            </tr>
        )

    }
    getProds = () => {
        return Object.values(this.state.modalProducts).map(prod => this.getModalProduct(prod))
    }
    getModalBody = () => {
        return (
            <div>
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getProds()}
                    </tbody>
                </Table>
            </div>
        )
    }
    render() {
        return (
            <div className="dashboard-nav-right-flip">
                <Modal isOpen={this.state.isOpen} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        <div>
                            {this.state.currentBox}
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        {this.getModalBody()}
                    </ModalBody>
                </Modal>
                <div className="main-card"  style={{padding: "10px"}}>
                <div style={{height: "95%"}}>

                    <InputGroup className="whole-search" >
                        <div className="fit-search search-bar">
                            <Input className="fit-search" id="searchText" 
                                value={this.state.searchText}
                                onChange={this.textChange}
                                placeholder="Search"
                            />
                            <FontAwesomeIcon onClick={this.clearText} className="search-box-clear" icon="xmark" />
                        </div>
                    </InputGroup>
                    <Table className="fixTable">
                        <thead>
                            <tr className="thead-fix">
                                <th>
                                    Serial Number
                                </th>
                            </tr>
                        </thead>
                        <tbody className="tableBody">
                            {this.getBody()}
                        </tbody>
                    </Table>
                    </div>
                </div>
            </div>
        )
    }

}
