import React from "react";
import "../../Hamburger.css";
import { Label } from "reactstrap";
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { Button, Select, MenuItem, InputLabel } from "@mui/material";
import FormControl from "@material-ui/core/FormControl";
import {Card} from "../Types/CardType"
import { PageState } from "../../../CommonTypes/PageStateType";
import PaginationMutator from "../../../Utilities/PaginationMutator";

interface CustomPaginationProps {
  rowCount: number;
  handlePageSizeChange: (value: number) => void; // Updated type to receive a number directly
  getGradedLastCard: () => void;
  getRawLastCard: () => void;
  GetPendingLastCard: () => void;
  getRetailLastCard: () => void;
  getTotalPrice: () => number;
  getTotalComp: () => number;
  handlePageChange: (val: number)=> void;
  pageState: PageState;
  
  setJumpPage: (page: (page: number) => void) => void;
  selectedForRepack: Card[];
}

function CustomPagination(props: CustomPaginationProps) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector) || 1;

  const {
    rowCount,
    handlePageSizeChange,
    handlePageChange,
    getGradedLastCard,
    getRawLastCard,
    GetPendingLastCard,
    getRetailLastCard,
    getTotalPrice,
    getTotalComp,
    pageState,
    setJumpPage,
    selectedForRepack
  } = props;

  function handlePageJump(page: number) {
    handlePageChange(page)
    apiRef.current.setPage(page);
  }

  setJumpPage(handlePageJump);

  const pageCount = Math.ceil(rowCount / pageState.pageSize);

  return (
    <div className="pagination-box" style={{ maxHeight: '100px', overflowY: 'hidden'}}>
      <div className="row-count">
        Total Rows: {rowCount}
        <Label
          style={{
            paddingLeft: ".5rem",
            paddingTop: ".5rem",
            paddingRight: ".5rem",
            fontSize: "14px",
          }}
        >
          Amount Selected: {getTotalPrice()}
        </Label>
        <Label
          style={{
            paddingTop: ".5rem",
            paddingRight: ".5rem",
            fontSize: "14px",
          }}
        >
          Comp Selected: {getTotalComp()}
        </Label>
        <Label
          style={{
            paddingTop: ".5rem",
            paddingRight: ".5rem",
            fontSize: "14px",
          }}
        >
          Cards Selected For Repack: {selectedForRepack.length}
        </Label>
      </div>
      <Button className="btn" onClick={getGradedLastCard}>
        Jump to Last Graded Card
      </Button>
      <Button className="btn" onClick={getRawLastCard}>
        Jump to Last Raw Card
      </Button>
      <Button className="btn" onClick={GetPendingLastCard}>
        Jump to Last Pending Card
      </Button>
      <Button className="btn" onClick={getRetailLastCard}>
        Jump to Last Retail Card
      </Button>
      <div className="form-control-box">
        <FormControl className="form-control">
          <InputLabel style={{ fontSize: "12px" }} shrink>
            Page Size
          </InputLabel>
          <Select
            value={pageState.pageSize ?? 150}
            onChange={(event) => handlePageSizeChange(event.target.value as number)} // Ensure event value is cast to number
            style={{ fontSize: "12px", paddingLeft: ".25rem", maxWidth: "80px", maxHeight: "30px"}}
          >
            <MenuItem value={150}>150</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={750}>750</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Pagination
        showFirstButton
        showLastButton
        color="primary"
        count={pageCount}
        page={pageState.page}
        siblingCount={0}
        onChange={(event, value) => {
          handlePageChange(value);
          apiRef.current.setPage(value);
        }}
      />
    </div>
  );
}

export default CustomPagination;
