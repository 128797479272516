export const initialGradeState = {
    columns: {
      columnVisibilityModel: {
        "initials:": false,
        gradeSent: false,
        gradeRec: false,
      },
    },
  };
  
  export const initialRawState = {
    columns: {
      columnVisibilityModel: {
        grade: false,
        "initials:": false,
      },
    },
  };
  
  export const initialPendingState = {
    columns: {
      columnVisibilityModel: {
        received: false,
        status: false,
        "initials:": false,
      },
    },
  };
  
  export const initialRetailState = {
    columns: {
      columnVisibilityModel: {
        "initials:": false,
      },
    },
  };
  
  const getInitialState = (type: any) => {
    switch (type) {
      case "grade":
        return initialGradeState;
      case "raw":
        return initialRawState;
      case "pending":
        return initialPendingState;
      case "retail":
        return initialRetailState;
      default:
        return initialGradeState;
    }
  };
  
  export default getInitialState;
  