import { Box, Card, Container, Grid } from '@mui/material';
import { DashboardLayout } from '../Components/DashboardComponents/DashboardLayout';
import PackingPageMain from '../Dash/NewPackingPage/PackingPageMain';

const Packingpage = () => (
        <Box sx={{
            width: '100%', 
            height: '100%', 
            padding: '20px'}}>
            <Card sx={{width: '100%', height: '100%'}}>
                <PackingPageMain/>
            </Card>
                    
        </Box>
  );
  
  
  export default Packingpage;
  