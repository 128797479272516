import * as React from 'react';
import {createContext, useContext} from 'react';
import useFetch from '../../../Components/CommonUtility/useFetch';
import { useSnackbar } from '../../SnackbarContext/SnackbarContext';


const ShowHostsContext = createContext<ShowHostsContextProps | null>(null);
interface ShowHostsContextProps {
    addHost: (host: ShowHost) => void,
    updateHost: (host: ShowHost) => void,
    hosts: Array<ShowHost>,
    setHosts: (hosts: Array<ShowHost>) => void,
    hostsLoading: Boolean,
    hostsError: Boolean,
}

type ShowHostsProviderProps = {
    children?: React.ReactNode;
}

export const ShowHostsProvider = ({children} : ShowHostsProviderProps) => {
    var {newSnackbarMessage} = useSnackbar();

    var {data: hosts, setData: setHosts, loading: hostsLoading, error: hostsError}: 
        { data: Array<ShowHost>, setData: any, loading: Boolean, error: any }
        = useFetch("/show/hosts", true)

    function addHost(host: ShowHost) {
        setHosts([...hosts, host])
    }
    function updateHost(newHost: ShowHost) {
        const newHosts: Array<ShowHost> = hosts.map( (host: ShowHost) => (host.publicId === newHost.publicId) ? newHost : host)
        setHosts(newHosts);
        newSnackbarMessage("Updated new host successfully", 'success');
    }
    const contextValues = {
        addHost,
        updateHost,
        hosts, 
        setHosts, 
        hostsLoading, 
        hostsError,
    }
    return (
        <ShowHostsContext.Provider value={contextValues}>
            {children}
        </ShowHostsContext.Provider>
        )
}

export function useShowHosts() {
    const context = useContext(ShowHostsContext);
    if (!context) {
        throw new Error("Invalid ShowHostsContext, must be wrapped in a ShowHostsProvider")
    }
    return context;
}