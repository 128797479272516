import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const extractInitialValueFromFilter = (model, field) => {
  const filter = model.items.find((item) => item.field === field && item.operator === "isAnyOf");
  if (filter?.value) {
    return filter.value;
  } else {
    return [];
  }
};

const MultiAutocomplete = (props) => {
  const [value, setValue] = useState(
    extractInitialValueFromFilter(props.filterModel, props.field) || []
  );
  const options = props.options;
  const updateFilterModel = (value) => {
    const reducedValue = value.map((item) => {
      if (item.label) {
        return item.label;
      } else {
        return item;
      }
    });

    var updatedItems = null;
    if (props.filterModel.items.some((item) => item.field === props.field && item.operator === "isAnyOf")) {
      updatedItems = [...props.filterModel.items].map((item) => {
        if (item.field === props.field && item.operator === "isAnyOf") {
          return {
            ...item,
            operator: "isAnyOf",
            value: reducedValue,
          };
        }
        return item;
      });
    } else {
      updatedItems = [
        ...props.filterModel.items,
        { field: props.field, operator: "isAnyOf", value: reducedValue },
      ];
    }
    const updatedFilterModel = {
      ...props.filterModel,
      items: updatedItems,
    };

    props.setFilterModel(updatedFilterModel);
  };

  return (
    <Autocomplete
      multiple
      options={options}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        updateFilterModel(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Value"
          variant="standard"
        />
      )}
    />
  );
};

export default MultiAutocomplete;
