
import { AppBar, Avatar, Badge, Box, IconButton, Toolbar, Tooltip,
    Menu, MenuItem
} from '@mui/material';
import React from 'react';
import {useContext, useRef} from 'react'
import { NavigateContext } from "../../Pages/ReactContexts/NavigateContext";
import { UserCircle as UserCircleIcon } from '../icons/user-circle';

export const ProfileMenu = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl);
    const {navigate} = useContext(NavigateContext)
    const settingsRef = useRef(null);


    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleLogout = (e) => {
        localStorage.setItem('user', null)
        localStorage.setItem('token', null)
        localStorage.setItem('userData', null)
        document.cookie = "jwtToken=;path=/"
        navigate("/dashboard/login")
        handleClose()
    }
    return (
      <div>
        <Avatar
        ref={settingsRef}
        sx={{
          cursor: 'pointer',
          height: 40,
          width: 40,
          ml: 1
        }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <UserCircleIcon fontSize="small" />
      </Avatar>
      <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
        >
        <MenuItem
          onClick={handleLogout}
        >Logout</MenuItem>
        </Menu>
      </div>
    )
}

export default ProfileMenu