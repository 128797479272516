import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  Input,
  DialogContentText,
} from "@mui/material";

import useFileUploader from "../../../Components/CommonUtility/useFileUploader";
import useFetch from "../../../Components/CommonUtility/useFetch";

interface UploadChecklistButtonProps {
  // Define any props you might be passing into this component
}

const UploadChecklistButton: React.FC<UploadChecklistButtonProps> = (props) => {
  const { upload,setSelectedFile,selectedFile }: { upload: any,selectedFile:any,setSelectedFile:any } = useFileUploader("/Upload/");
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
    setValue("");
  };

  const handleSubmit = () => {
    
    if (selectedFile !== null && value.length > 0) {
      upload("checklist/" + value);
    }
  };

  const textChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const getFileError = (): boolean => {
    return selectedFile === null;
  };

  const getSelectedError = (): boolean => {
    return value.length === 0;
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Bulk Upload Checklist</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please make sure file content is of format that matches Panini's
            checklist: sport, year, brand, program, cardset, athlete, team,
            position, card#, sequence.
          </DialogContentText>
          <TextField
            label="Enter League"
            error={getSelectedError()}
            onChange={textChange}
            value={value}
          />
          <Input
            error={getFileError()}
            onChange={changeHandler}
            inputProps={{ accept: ".csv", multiple:true }}
            type="file"
            name="file"
            
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Button onClick={handleClickOpen}>Upload Checklist</Button>
    </>
  );
};

export default UploadChecklistButton;
