import SaleJournalMain from './SaleJournalMain';
import ShippingJournalMain from './ShippingJournalMain';
import InnerSidebar from '../../Components/DashboardComponents/InnerSidebar/InnerSidebar';


export default function UplodaerFirstPage(props) {
    
const sidePages = { 
    "sale": {
        "name": "sale",
        "label": "Sale MJ",
        "element": <SaleJournalMain />
    },
    "shipping": {
        "name": "shipping",
        "label": "Shipping",
        "element": <ShippingJournalMain />
    }
}
    return (
        <>
            <InnerSidebar 
                items={sidePages}
                defaultPageValue="sale"
                pageLocalstorageKey="uploader"
            />
        </>
    )

}
