import React, { useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { GridActionsCellItem, GridRowId } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

function RepackStorePreview(props: any) {
  const addToRepackStore = props.addToRepackStore
  const [rows,setRows] = useState(props.rows);
  console.log("RepackStorePreview Props: ",props)
  var removeItemFromRepack = props.removeItemFromRepack
  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "",
      
      renderCell: (params: GridRenderCellParams) => {
        var id = params.id;
        var row = {...params.row};
        return(
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Remove"
          className="textPrimary"
          disabled={false}
          onClick={(event)=>{
            const newRows = rows.filter((item:any) => item.id !== id);
            console.log("FUCK ME: ", newRows)
            setRows(newRows);  
            removeItemFromRepack(id,props.id.id)
            console.log("Adding row to store: ", row)
            addToRepackStore([row])
          }}
          color="inherit"
        />)
      },
    },
    {
      field: 'name',
      headerName: 'Card',
      width: 150,
      editable: false,
    },
    {
      field: 'comp',
      headerName: 'Comp',
      width: 150,
      editable: false,
    },
    
    
  ];

  return (
    
       <div style={props.style} onDrop={(event)=>props.handleDropBoxContents(event, props.id)} onDragOver={(event)=>props.dragOver(event)}>
        <Box sx={{ height: '100%', width: '100%' }}>
          <DataGridPro
            rows={rows}
            columns={columns}
            pagination={false}
            disableColumnReorder={true}
            slotProps={{
              row: {
                draggable: "true",
                onDragStart: (event) => props.dragStart(event, props.id)
              },
            }}
          />
        </Box>
        
      </div>
    
  );
}

export default function StagedRepacks(props: any) {
  
  const columns = [
    {
      field: 'id',
      headerName: 'Box Id',
      width: 150,
      editable: false,
    },
    {
      field: 'comp',
      headerName: 'Comp',
      width: 150,
      editable: false,
      valueGetter: (params: { row: { items: any; }; }) => {
        var cards = params.row.items
        return cards.reduce((accumulator: any, currentObject: any) => accumulator + currentObject.comp, 0);
      }
  
    },
    
    
  ];
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<
    GridRowId[]
  >([]);
  const addToRepackStore = props.addToRepackStore
  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
      );
    },
    [],
  );

  const removeItemFromRepack = (itemId: number,boxId:number) => {
    for(var i = 0; i < props.rows.length; i++){
      if(props.rows[i].id === boxId){
        props.rows[i].items = props.rows[i].items.filter((item:any) => item.id !== itemId);
      }
    }
    props.setRows(props.rows)

  }

  const detailPanelContent = useCallback((row:any)=>{
    return (
      <RepackStorePreview 
        rows={row.row.items} 
        dragStart={props.dragStart} 
        id={row}
        style={undefined} 
        handleDropBoxContents={props.handleDropBoxContents}  
        dragOver={props.dragOver} 
        removeItemFromRepack={removeItemFromRepack}
        addToRepackStore={addToRepackStore}
      />
    )
  }, [props.rows]);
  return (
    
       <div>
        <Box sx={{ height: '100%', width: '100%' }}>
          <DataGridPro
          sx={{
            maxHeight: "700px",
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
          }}
            disableColumnReorder={true}
            rows={props.rows}
            columns={columns}
            pagination={false}
            onDetailPanelExpandedRowIdsChange = {handleDetailPanelExpandedRowIdsChange}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onRowSelectionModelChange={(ids) => {
              const selectedRows = props.rows.filter((row:any) => row.id === ids[0]);
              props.setSelectedBox(selectedRows);
            }}
            getDetailPanelContent={detailPanelContent}
            getDetailPanelHeight={({ row }) => 'auto'} // Height based on the content.
            slotProps={{
              row: {
                onDrop: (event) => props.dragDrop(event, event.currentTarget.dataset.row),
                onDragOver: (event) => props.dragOver(event)
              },
            }}
            
          />
        </Box>
      </div>
    
  );
}