import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import { Button } from "@mui/material";
import "./CustomToolbar.css";

interface AddCardsProps {
  createRecords: (type: "grade" | "raw" | "pending" | "retail", cardsToAdd: number) => void;
  cardsToAdd: number;
  setCards: (value: number) => void;
}


const AddCards: React.FC<AddCardsProps> = ({ createRecords, cardsToAdd, setCards }) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleCreateRecords = (type: "grade" | "raw" | "pending" | "retail") => {
    setCards(0);
    createRecords(type, cardsToAdd);
    toggleModal();
  };  

  return (
    <div>
      <Button color="primary" onClick={toggleModal}>
        Add Card(s)
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="add-cards-modal"
      >
        <ModalHeader toggle={toggleModal}>Add Cards</ModalHeader>
        <ModalBody>
          <Label for="cardsToAdd">
            Select the number of new cards you'd like to add:</Label>
          <Input
            id="cardsToAdd"
            type="number"
            className="cards-to-add-input"
            value={Math.floor(cardsToAdd)}
            min={1}
            step="1"
            onChange={(event) => {
              setCards(parseInt(event.target.value));
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="add-cards-button "
            onClick={() => {
              handleCreateRecords("grade");
            }}
            disabled={cardsToAdd > 0 ? false : true}
          >
            Add Graded Card(s)
          </Button>
          <Button
            color="primary"
            className="add-cards-button "
            onClick={() => {
              handleCreateRecords("raw");
            }}
            disabled={cardsToAdd > 0 ? false : true}
          >
            Add Raw Card(s)
          </Button>
          <Button
            color="primary"
            className="add-cards-button "
            onClick={() => {
              handleCreateRecords("pending");
            }}
            disabled={cardsToAdd > 0 ? false : true}
          >
            Add Pending Reception Card(s)
          </Button>
          <Button
            color="primary"
            className="add-cards-button "
            onClick={() => {
              handleCreateRecords("retail");
            }}
            disabled={cardsToAdd > 0 ? false : true}
          >
            Add Retail Card(s)
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddCards;
