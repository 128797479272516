
import {useContext} from 'react';
import { NavigateContext } from "../../../Pages/ReactContexts/NavigateContext";


export default function InnerSidebarItem(props) {
    
    const {navigate} = useContext(NavigateContext)
    function onClick (event) {
        navigate(event.target.id)
    }
    return (
        <div 
            className={props.getActive(props.id) + " dashboard-nav-left-item "}
            onClick={onClick}
            id={props.id}
            key={props.key}
        >{props.label}
                    
        </div>
    )
}