import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";

interface DetailsProps {
  details: { id: number; name: string; comp: number; totalCost: number }[];
}

const RepackDetails: React.FC<DetailsProps> = ({ details }) => {
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "comp", headerName: "Comp", flex: 1 },
    { field: "totalCost", headerName: "Total Cost", flex: 1 },
  ];

  return (
    <div style={{ height: "auto", width: "100%" }}>
      <DataGridPro rows={details} columns={columns} />
    </div>
  );
};

export default RepackDetails;
