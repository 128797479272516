import RepackStorePreview from "./CartView";
import { useRepackStore } from "../../../Utilities/RepackCardStore";
import React, { useCallback, useRef } from 'react'; // Ensure React is in scope when using JSX
import { useState, useEffect } from "react";
import StagedRepacks from "./StagedRepacks";
import { useLocalStorage } from "../../../Utilities/useLocalStorage";
import DragAndDrop from "./DragToBox";
import FetchModule from "../../../Modules/FetchModule";
import SeriesInfoPanel from "./SeriesInfoPanel";
import { RepackType } from "../Types/RepackTypeType";
import { StagedBox } from "../Types/StagedBoxType";
import Button from '@mui/material/Button';


export default function RepackCreation(props:any) {

  const fetchData = new FetchModule();

  const useFetchGet = (url:string) => {
    const [data, setData] = useState<RepackType[]>([]);
    useEffect(() => {
      fetchData
        .fetchResponseAuthed(url, "GET")
        .then((res) => res.json())
        .then((data) => setData(data));
    }, [url]);
    return [data, setData];
  };
  //
  const [types,setTypes] = useFetchGet("/Repack/get/types")
  

  const repackStorePreviewStyle: React.CSSProperties = {
    width: '46%', // 46% of the parent container's width
    margin: '0 2%', // 2% margin to the left and right
  };

  const blankDivStyle: React.CSSProperties = {
    width: '50%', // Takes up the remaining 50% of the screen
    margin: '0 2%', // Same horizontal margin
    display: 'inline-block', // Ensure it's aligned next to the RepackStorePreview
  };

  const containerStyle: React.CSSProperties = {
    display: 'flex', // Use flexbox for layout
    justifyContent: 'space-between', // Distributes space between children as specified
  };

  const topBlankDivStyle: React.CSSProperties = {
    width: '100%', // Takes up the full width
    height: '15vh', // Takes up 15% of the viewport height
  };

  const [selectedForRepack, addToRepackStoreRepackVersion, clearRepackStore, removeFromRepackStoreRepackVersion] = useRepackStore();
  const[boxes,setBoxes] = useLocalStorage("currentBoxes",[]);
  
  
  const addBox = ()=>{
    var boxToAdd = {"id":boxes.length, "items":[]}
    var boxesCopy = [...boxes]
    boxesCopy.push(boxToAdd)
    setBoxes(boxesCopy)
  }
  const [selectedBox,setSelectedBox] = useState<any>();
  const [selectedItems,setSelectedItems] = useState<any>();
  const addToNewBox = ()=>{
    var boxToAdd = {"id":boxes.length, "items":[...selectedItems]}
    var boxesCopy = [...boxes]
    boxesCopy.push(boxToAdd)
    setBoxes(boxesCopy)
  }

  const addToSelectedBox = () => {
    let converter: { id: number; items: any[] };

    if (typeof selectedBox === 'number') {
        converter = boxes.filter((box:StagedBox) => box.id === selectedBox)[0];
    } else {
        converter = JSON.parse(JSON.stringify(selectedBox[0]));
    }

    // Ensure tempBox.items is a new, extensible array
    var tempBox = { ...converter, items: [...converter.items] };

    selectedItems.forEach((card: any) => {
        tempBox.items.push(card);  
    });

    const updatedObjects = boxes.map((obj: StagedBox) => obj.id === tempBox.id ? tempBox : obj);
    setBoxes(updatedObjects);
    setSelectedBox(tempBox);
};

  
  const dragAndDrop = useRef(new DragAndDrop(selectedForRepack,boxes,setBoxes,addToRepackStoreRepackVersion,removeFromRepackStoreRepackVersion))
  const [selectedType, setSelectedType] = useState<RepackType | string >("");

  useEffect(()=>{
    dragAndDrop.current.resetBoxes(boxes)
    console.log("Boxes was modified: ", boxes)
  },[boxes])
  useEffect(()=>{
    dragAndDrop.current.resetItems(selectedForRepack)
    console.log("SelectedForRepack was modified: ", selectedForRepack)
  },[selectedForRepack])

  const CartView = useCallback(() => {
    return (
      <RepackStorePreview
        key={selectedForRepack.length}
        rows={selectedForRepack}
        clearRepackStore={clearRepackStore}
        deleteRepackFromStore={removeFromRepackStoreRepackVersion}
        setSelectedItems={setSelectedItems}
        dragStart = {dragAndDrop.current.cartItemDrag}
        dragOver={dragAndDrop.current.handleDragOver}
        drop={dragAndDrop.current.boxItemStopDrag}
      />
    );
  }, [selectedForRepack]);

  const RepackView = useCallback(()=>{
    return(
    <StagedRepacks
            rows = {boxes}
            setRows = {setBoxes}
            selectedBox ={selectedBox} 
            setSelectedBox = {setSelectedBox}
            dragDrop={dragAndDrop.current.handleDropOntoBox}
            dragOver={dragAndDrop.current.handleDragOver}
            dragStart={dragAndDrop.current.boxItemStartDrag}
            handleDropBoxContents = {dragAndDrop.current.handleDropBoxContents}
            addToRepackStore= {addToRepackStoreRepackVersion}
          />)
  },[boxes])
  return (
    <>
      <div style={topBlankDivStyle} className="red-outline">
        <SeriesInfoPanel types = {types as RepackType[]} setSelectedType={setSelectedType} selectedType = {selectedType} boxes = {boxes}/>
      </div> {/* Top blank div occupying 15% of the screen height */}
      <div style={containerStyle} className="red-outline" >
        <div style={repackStorePreviewStyle}>
          <Button onClick={addToNewBox}>Add to new box</Button>
          <Button onClick={addToSelectedBox}>Add to selected box</Button>
          <CartView/>
        </div>
        
        <div style={blankDivStyle} className="red-outline" >
          <Button onClick={addBox}>Add Box</Button>
          <RepackView/>
        </div> {/* Blank div next to RepackStorePreview */}
      </div>
    </>
  );
}
