
import React, {useState} from 'react';
import {Button} from '@mui/material';
import saveAs from "file-saver";
import FetchModule from '../../Modules/FetchModule';
const api = require("../../Modules/APIModules")

const fetchData = new FetchModule();

export default function DownloadBarcodes(props) {
    const [disabled, setDisabled] = useState(false)
    const token = String(fetchData.getCookie("jwtToken"));

    function downloadData() {
        setDisabled(true)
        fetch( api.getLink() +'/download/excel/products', {
            method: 'GET',
            headers: new Headers({
                'Authorization': "Bearer " + String(token),
            })
        })
            .then(response => {
                if (response.status == 200)  {
                    response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob );

                    saveAs(url, "barcodes.xlsx")
                
                });
            }
        }).finally(
            setDisabled(false)
        );
        
    }

    return(
        <div>
            <Button disabled={disabled} onClick={downloadData}>Download Barcodes Excel File</Button>
        </div>
    )
}

