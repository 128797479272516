import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import FetchModule from "../../../Modules/FetchModule";
import "../singles.css";
import { matchSorter } from "match-sorter";

const fetchData = new FetchModule();

export function AutocompleteSkuItem(props) {
  const { id, field, isFilter, width, addNewSKU, dataFields, setDataFields } = props;
  const { user } = useUser();
  const skuType = props.colDef.skuType;
  const [hasNoValue, setNewValue] = useState(false);
  const apiRef = useGridApiContext();
  const [value, setValue] = useState(props.value || {});
  const [prevValues, setPrevValues] = useState([]);
  const [open, toggleOpen] = useState(false);
  const setFilterModel = props.setFilterModel;
  const filterModel = props.filterModel;
  const skuOptions = dataFields[skuType]?.items || [];

  const handleClose = () => {
    setDialogValue({
      label: "",
      value: "",
      publicId: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = useState({
    label: "",
    value: "",
    publicId: "",
    rank: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (hasNoValue) {
      const data = dialogValue;
      const sData = {
        UserId: user.id,
        Body: data,
        PublicId: data.publicId,
        rank: data.rank,
      };
      const response = await fetchData.promise("/Card/update/skuitem", true, "PUT", sData);
      if (response.status === 200) {
        data.id = props.id;
        setValue(data);
        handleClose();
        props.updateNewSKU(skuType, data);
      }
    } else {
      const data = {
        label: dialogValue.label,
        value: dialogValue.value,
        cardSkuItemPublicId: dataFields[skuType]?.publicId,
      };
      const response = await fetchData.promise("/Card/create/skuitem", true, "POST", {
        UserId: user.Id,
        Body: data,
      });
      if (response.status === 200) {
        const skuField = skuType === "setVariety" ? "set" : skuType;
        const guid = await response.json();
        data.publicId = guid;
        data.inputValue = data.label;
        await apiRef.current.setEditCellValue({ id, field, value: data });
        data.id = props.id;
        setValue(data);
        addNewSKU(skuType, data);
        handleClose();
      }

    }
  };

  const handleChange = async (event, newValue) => {
    if (filterModel && filterModel.items) {
      const updatedFilterModel = {
        ...filterModel,
        items: filterModel.items.map((item) => {
          if (item.field === field) {
            return {
              ...item,
              value: newValue ? newValue.label : "",
            };
          }
          return item;
        }),
      };
      props.setFilterModel(updatedFilterModel);
    } else {
      if (newValue === null) {
        setValue({});
        const empty = dataFields[skuType].items.find((d) => d.label.trim() === "");
        await apiRef.current.setEditCellValue({ id, field, value: empty });
        return;
      }
      if (typeof newValue === "string") {
        const newVal = {
          label: newValue.inputValue,
          value: "",
          cardSkuItemPublicId: dataFields[skuType]?.publicId,
        };
        setDialogValue(newVal);
        await apiRef.current.setEditCellValue({ id, field, value: newVal });
        toggleOpen(true);
      } else if (newValue && newValue.inputValue) {
        const newVal = {
          label: newValue.inputValue,
          value: "",
          cardSkuItemPublicId: dataFields[skuType]?.publicId,
        };
        //if the value exists in the skuOptions, set the value to the existing value and dont open the modal
        const existingValue = skuOptions.find((d) => d.label === newValue.inputValue);
        if (existingValue) {
          setValue(existingValue);
          await apiRef.current.setEditCellValue({ id, field, value: existingValue });
          return;
        } else { //otherwise, open the modal and do previous work
          setDialogValue(newVal);
          await apiRef.current.setEditCellValue({ id, field, value: newVal });
          toggleOpen(true);
        }
      } else {
        if (newValue.value === null) {
          setNewValue(true);
          setDialogValue({ ...newValue });
          toggleOpen(true);
          await apiRef.current.setEditCellValue({ id, field, value: newValue });
        }
        setValue(newValue);
        await apiRef.current.setEditCellValue({ id, field, value: newValue });
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab" || event.key === "Enter") {
      event.preventDefault();
      const inputValue = event.target.value;
      const options = skuOptions;
      var filtered =  matchSorter(options, inputValue, {keys: ['label']})
      if (filtered.length > 0) {
        handleChange(event, filtered[0]);
        event.target.blur();
      }
    }
  };

  const handleFilterOptions = (options, params) => {
    let valueToFilter = params.inputValue;
    if (value.label !== "" && valueToFilter === "" && value.label !== undefined) {
      valueToFilter = value.label;
    }
    var filtered =  matchSorter(options, valueToFilter, {keys: ['label']}).splice(0, 15);
    if (params.inputValue !== "" && !isFilter) {
      filtered.push({
        inputValue: params.inputValue,
        label: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  };

  const [duplicate, setDuplicate] = useState("");

  const shouldDisable = () => {
    if(dialogValue.value.length > 12){
      return true;
    }

    if (field === "grade") {
      return dialogValue.label === "" || dialogValue.value === "" || !dialogValue.rank || duplicate;
    }
    return dialogValue.label === "" || dialogValue.value === "" || duplicate;
  };

  return (
    <React.Fragment>
      <div className="autocomplete-container">
        <Autocomplete
          sx={{ width: width || "150px" }}
          style={{ width: width || "150px", margin: "0px !important", fontSize: "16px" }}
          value={
            !filterModel
              ? value.label || ""
              : skuOptions.find((d) => d.label === props.value) || null
          }
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          filterOptions={handleFilterOptions}
          id={"autcomplete-entry-skufield" + field}
          options={skuOptions}
          getOptionLabel={(option) => {
            if (!option) return "";
            if (typeof option === "string") return option;
            if (option.inputValue) return option.inputValue;
            return option.label;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          renderOption={(props, option) => (
            <li {...props} style={{ fontSize: "12px", whiteSpace: "nowrap" }} key={option.label}>{option.label}</li>
          )}
          freeSolo
          PaperComponent={({ children }) => (
            <Paper style={{ border: "2px solid #5048E5", top: "-1000px" }}>{children}</Paper>
          )}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              id="grade"
              type="text"
              size="small"
              style={{ fontSize: "14px", width: width || "150px" }}
              placeholder={skuType}
            />
          )}
        />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new attribute and SKU relations</DialogTitle>
          <DialogContent>
            {field !== "grade" && (
              <DialogContentText style={{ marginBottom: "10px" }}>
                Missing any {field}? Please add it with associated SKU.
              </DialogContentText>
            )}
            {field === "grade" && (
              <DialogContentText style={{ marginBottom: "10px" }}>
                Missing a {field}? Please add it with associated SKU and rank.
                Ranks are created in ascending order.
              </DialogContentText>
            )}
            <TextField
              autoFocus
              id="label"
              value={dialogValue.label || ""}
              onChange={(event) => {
                setDialogValue({
                  ...dialogValue,
                  label: event.target.value,
                });
              }}
              label="Name"
              type="text"
              variant="outlined"
              size="small"
              style={{
                fontSize: "0.75rem",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            />
            <TextField
              id="value"
              value={dialogValue.value || ""}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  value: event.target.value,
                })
              }
              onBlur={() => {
                const url = "/Card/test/sku/" + field + "/" + dialogValue.value;
                fetchData.fetchResponseAuthed(url, "GET").then((res) => {
                  if (res.status === 200 || res.status === 204) {
                    res.text().then((data) => {
                      setDuplicate(data);
                    });
                  }
                });
              }}
              label="SKU"
              type="text"
              variant="outlined"
              size="small"
              style={{
                fontSize: "0.75rem",
                marginBottom: "10px",
                marginTop: "10px",
                marginLeft: "10px",
              }}
            />
            {field === "grade" && (
              <TextField
                value={dialogValue.rank}
                onChange={(event) =>
                  setDialogValue({ ...dialogValue, rank: event.target.value })
                }
                label="Rank"
                type="number"
                variant="outlined"
                size="small"
              />
            )}
            {duplicate && <p style={{ color: "red" }}>Duplicate SKU: {duplicate}</p>}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button disabled={shouldDisable()} type="submit">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

export default AutocompleteSkuItem;
