import React from 'react';
import { Button, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Card } from '../Types/CardType';

interface CopyContentProps {
  selectedRows: Card[];
  copyType: 'master' | 'short' | 'all' | 'purchase';
}

export function Copy({ selectedRows, copyType }: CopyContentProps) {
  function canCopy() {
    return selectedRows.length === 0;
  }

  function noNullRow(row: Card, key: keyof Card): string {
    const value = row[key];
    if (value instanceof Date) {
      return value.toISOString();
    } else if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      return String(value);
    } else {
      return '';
    }
  }

  function getCommonRows(row: Card): string {
    return (
      `${row["inventoryId"]}\t` +
      `${new Date(noNullRow(row, "purchase")).toLocaleDateString()}\t` +
      `${new Date(noNullRow(row, "received")).toLocaleDateString()}\t` +
      `${row["player"]["label"]}\t` +
      `${row["year"]["label"]}\t` +
      `${row["manufacturer"]["label"]}\t` +
      `${row["series"]["label"]}\t` +
      `${row["setVariety"]["label"]}\t` +
      `${row["attributes"]}\t` +
      `${row["serial"]}\t` +
      `${row["grade"]["label"]}\t` +
      `${row["cardNumber"]}\t` +
      `${row["league"]["label"]}\t` +
      `${row["cost"]}\t` +
      `${row["fees"]}\t` +
      `${row["gradingFees"]}\t` +
      `${row["totalCost"]}\t` +
      `${row["comp"]}`
    );
  }

  function getAllRows(row: Card): string {
    let copiedText = getCommonRows(row);

    if (copiedText[copiedText.length - 1] !== '\t') {
      copiedText += '\t';
    }
    
    copiedText += (
      `${row["gradeSent"]}\t` +
      `${row["gradeRec"]}\t` +
      `${row["certNumber"]}\t` +
      `${row["vendor"]}\t` +
      `"${row["description"]}"\t` +
      `${row["purchasedBy"]}\t` +
      `${row["initials"]}\t` +
      `${row["status"]["label"]}\t` +
      `${row["isDear"]}\t` +
      `${row["sku"]}\t` +
      `${row["name"]}`
    );
    return copiedText;
  }

  function getMasterRows(row: Card): string {
    let copiedText = getCommonRows(row);

    if (copiedText[copiedText.length - 1] !== '\t') {
      copiedText += '\t';
    }

    copiedText += (
      `${row["vendor"]}\t` +
      `${row["initials"]}\t` +
      `${row["sku"]}\t` +
      `${row["name"]}`
    );
    return copiedText;
  }

  function getPurchaseRows(row: Card): string {
    let copiedText = "";
    copiedText += (
      `${row["player"]["label"]}\t` +
      `${row["year"]["label"]}\t` +
      `${row["manufacturer"]["label"]}\t` +
      `${row["series"]["label"]}\t` +
      `${row["setVariety"]["label"]}\t` +
      `${row["attributes"]}\t` +
      `${row["serial"]}\t` +
      `${row["grade"]["label"]}\t` +
      `${row["cardNumber"]}\t` +
      `${row["league"]["label"]}\t` +
      `${row["comp"]}\t` +
      `${row["totalCost"]}`
    );
    return copiedText;
  }

  function copy() {
    let copiedText = "";
    selectedRows.forEach((row, index) => {
      if (copyType === "all") {
        copiedText += getAllRows(row);
      } else if (copyType === "short") {
        copiedText += getCommonRows(row);
      } else if (copyType === "master") {
        copiedText += getMasterRows(row);
      } else if (copyType === "purchase") {
        copiedText += getPurchaseRows(row);
      }

      // Add newline character if not the last iteration
      if (index !== selectedRows.length - 1) {
        copiedText += '\n';
      }
    });

    navigator.clipboard.writeText(copiedText).then(() => {
      alert("Copied the text: \n" + copiedText);
    })
      .catch(() => {
        alert("Something went wrong, text was not copied");
      });
  }


  return (
    <Tooltip title={canCopy() ? "You must select rows to copy" : "Copy selected elements"}>
      <span>
        <Button onClick={copy} disabled={canCopy()}>
          <ContentCopyIcon />
          {copyType === 'short' ? 'Short Copy' :
            copyType === 'master' ? 'Master Copy' :
              copyType === 'purchase' ? 'Purchase Copy' :
                'Copy All'}
        </Button>
      </span>
    </Tooltip>
  );
}
