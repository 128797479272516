import React, { Component } from 'react';
import Switch from "react-switch";
import Select from 'react-select';
import { Button, Table, InputGroup, Input } from 'reactstrap';
import '../Hamburger.css';
import './break.css';
import FetchModule from "../../Modules/FetchModule";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);

const fetchData = new FetchModule();

export default class NewBreak extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchText: "",
            disabled: false,
            goTo: false,
            breakTypes: [
                {value: 'None', label: 'None'},
                {value: 'TR', label: 'Team Random'},
                {value: 'DR', label: 'Division Random'},
                {value: 'personals', label: 'Personals'},
                {value: 'giveaway', label: 'Giveaway'}
            ],
            pantheonSerials: [],
            breakType: null,
            sessionId: 0,
            goToDisabled: false,
            creating: false,
            //productSerialOptions: {},
        }

        this.state.breakType=this.state.breakTypes[0]
        let path = window.location.pathname
        let id = path.split('/')
        this.state.sessionId = id[3]
    }
    setNewBreakType = (breakItem) => {
        for (var i = 0; i < this.state.breakTypes.length; i++) {
            if (this.state.breakTypes[i].value === breakItem) {
                this.updateState('breakType', this.state.breakTypes[i])
            }
        }
    }
    async componentDidMount() {
        if (this.props.breakState === "edit") {

        } else if (Object.values(this.props.currentProducts).length === 0 && this.state.disabled === false) {
            this.updateState("disabled", true)
        }

    }
    async componentDidUpdate() {
        if (!this.state.creating) {
            if (this.props.breakState !=="edit") {
                if (this.props.currentProducts !== null) {
                    if (Object.values(this.props.currentProducts).length === 0 && this.state.disabled === false) {
                        this.updateState("disabled", true)
                    }  else if (Object.values(this.props.currentProducts).length > 0 && this.state.disabled === true) {
                        this.updateState('disabled', false)
                    }
                }
            } else {
                let isDefault = true
                Object.values(this.props.originalProducts).forEach(prod => {
                    if (this.props.currentProducts[prod.product.id] !== undefined && 
                        parseInt(this.props.currentProducts[prod.product.id].quantity) === parseInt(prod.quantity)) {

                    } else {
                        isDefault = false
                    }
                })
                Object.values(this.props.currentProducts).forEach(prod => {
                    if (this.props.originalProducts[prod.product.id] === undefined) {
                        isDefault = false
                    } else if (prod.product.category == "Repacks") {
                        var ogSkus = this.props.originalProducts[prod.product.id].skus
                        var allExist = true
                        prod.skus.forEach(sku1 => {
                            var exist = false
                            ogSkus.forEach(sku2 => {
                                
                                if(sku1.value == sku2.value) {
                                    exist = true
                                }
                            })
                            if (!exist) {
                                allExist = false
                            }
                        })
                        if(!allExist) {
                            isDefault = false;
                        }
                    }
                })
                if (isDefault && this.state.disabled === false) {
                    this.updateState("disabled", true)
                } else if (!isDefault && this.state.disabled === true) {
                    this.updateState("disabled", false)
                }
            }
        }
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    textChange = (event) => {
        this.updateState('searchText', event.target.value)
        this.updateProductDisplay(event.target.value)
    }
    addNewBreakProduct = () => {
        var prods = this.state.currentProducts
        prods.push({
            product: null,
            quantity: 0,
        })
        this.updateState("currentProducts", prods)
    }
    getTopBar = () => {
        return (
            <InputGroup className="whole-search" >
                <div className="fit-search search-bar">
                    <Input className="fit-search" id="searchText" 
                        value={this.state.searchText}
                        onChange={this.textChange}
                        placeholder="Search"
                    />
                    <FontAwesomeIcon onClick={this.clearText} className="search-box-clear" icon="xmark" />
                </div>
            </InputGroup>
        )
    }
    removeProduct = (event) => {
        
        let prods = this.props.currentProducts
        delete prods[event.target.value]
        this.props.updateState('currentProducts', prods)
        if (this.props.breakState === "create") {
            localStorage.setItem('newBreakProducts', JSON.stringify(prods))
        }
        this.props.updateProductDisplay()
    }
    removeSerialProduct = (event) => {
        let prods = this.props.currentProducts
        var index = Number(event.target.getAttribute("index"))
        prods[event.target.value].skus.splice(index, 1)
        prods[event.target.value].quantity -= 1
        this.props.updateState('currentProducts', prods)
        if (this.props.breakState === "create") {
            localStorage.setItem('newBreakProducts', JSON.stringify(prods))
        }
        this.props.updateProductDisplay()
    }
    quantityChanged = (event) => {
        let prods = this.props.currentProducts
        let prod = prods[event.target.id]
        let diff = prod.product.onHand - prod.product.allocated
        if ( event.target.value > diff) {
            prods[event.target.id].quantity = diff
        } else if (event.target.value > 0) {
            prods[event.target.id].quantity = event.target.value 
        }
        //this.props.updateState("currentProducts", prods)
        this.props.updateCurrentProducts(prods)
    }
    skuChange = (event, index, id) => {
        let prods = this.props.currentProducts
        prods[id].skus[index] = event
        this.props.updateState('currentProducts', prods)
        if (this.props.breakState == "create") {
        localStorage.setItem('newBreakProducts', JSON.stringify(prods))
        }
        this.props.updateProductDisplay()
    }

    displayProduct = (item) => {
        if (item.product.category == "Repacks" && this.props.productSerialOptions !== undefined) {

            var elements = []
            item.skus.forEach((sku, index) => {
                elements.push(
                <tr key={item.id}>
                    <td>{item.product.name}</td>
                    <td>
                        <Select 
                            value={this.props.currentProducts[item.product.id].skus[index]}
                            onChange={(event) => this.skuChange(event, index, item.product.id)}
                            options={this.props.productSerialOptions[item.product.id]}
                        />                    
                        
                    </td>
                    <td>
                        <Button color="danger" 
                            index={index} 
                            onClick={this.removeSerialProduct} 
                            value={item.product.id}
                        >X</Button>
                    </td>
                </tr>
                )
                
            })

            return elements
        }
        return (
            <tr key={item.id}>
                <td>{item.product.name}</td>
                <td>                    
                    <Input className="fit-search" 
                        id={item.product.id} 
                        value={item.quantity}
                        type="number"
                        onChange={this.quantityChanged}
                        placeholder="Enter in a valid quantity"
                    />
                </td>
                <td><Button color="danger" onClick={this.removeProduct} value={item.product.id}>X</Button></td>
            </tr>
        )
    }
    getData = () => {
        if (this.props.currentProducts != null) {
            var prods = Object.values(this.props.currentProducts)
            if (prods) {
                return prods.map(item => this.displayProduct(item))
            }
        }
    }

    createBreak = async () => {
        if (!this.state.disabled) {
            this.updateState("creating", true, () => {
                this.setState({disabled: true})
            })
            let prods = this.props.currentProducts
            var products = Object.values(prods)
            var bodyData = {
                products: [],
                assemblyProducts: []
            }
            products.forEach(val => {
                if (val.product.category == "Repacks") {
                    val.skus.forEach(sku => {
                        bodyData.assemblyProducts.push({
                            ComponentProductID: val.product.id,
                            ProductCode: val.product.sku,
                            PriceTier3: val.product.priceTier3 * val.quantity,
                            Quantity: 1,
                            AdditionalAttribute4: val.product.additionalAttribute4,
                            Name: val.product.name,
                            Category: val.product.category,
                            isPantheon: true,
                            pantheonSerial: sku.value
                        })
                    })
                } else {
                    bodyData.assemblyProducts.push({
                        ComponentProductID: val.product.id,
                        ProductCode: val.product.sku,
                        PriceTier3: val.product.priceTier3 * val.quantity,
                        Quantity: val.quantity,
                        AdditionalAttribute4: val.product.additionalAttribute4,
                        Name: val.product.name,
                        Category: val.product.category,
                        isPantheon: false,
                        pantheonSerial: ""
                    })
                }
                
                bodyData.products.push({
                    product: val.product,
                    quantity: val.quantity
                })
            })
            let breakType = this.state.breakType.value
            let sessionId = this.state.sessionId
            var response = await fetchData.promise("/Breaking/new/"+sessionId +"/"+breakType, true, "POST", bodyData)
            if (response.status === 200) {
                localStorage.setItem("newBreakType", "TR")
                this.setNewBreakType("None");
                if (this.state.goTo) { // navigate to that break page
                    let id = await response.json()
                    this.props.navigate("/dashboard/breaks/"+sessionId+"/"+id+"/slots")
                } else { // clear the columns/remove the values from the quantities
                    this.props.updateState("currentProducts", {})
                }
                this.props.updateCurrentProducts({})
                this.props.updateQuantities(bodyData)
            }
        this.updateState("creating", false)
        this.updateState("disabled", false)
        }
    }
    getBody = () => {
        return(
            <div className="card-body">
                <Table >
                    <thead>
                        <tr className="thead-fix sm-text align">
                            <th>Name</th>
                            <th>Quantity</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="tableBody">
                        {this.getData()}
                    </tbody>
                </Table>
            </div>
        )
    }
    handleSwitchChange = (event) =>{ 
        this.setState({goTo: event})
        
    }
    breakChanged = (val) => { 
        //let exists = false;
        //if (val.value !== "giveaway" || val.value !== "personals") {
        //    var additionalAttribute4 = {}
        //    Object.values(this.props.currentProducts).forEach(prod => {
        //        if (additionalAttribute4[prod.product.additionalAttribute4] === undefined && prod.product.category != "Repacks") {
        //            additionalAttribute4[prod.product.additionalAttribute4] = true
        //        } 
        //    })
        //    if (Object.keys(additionalAttribute4).length > 1) {
        //        exists = true;
        //    }
        //} 
        //if (!exists) {
            localStorage.setItem('newBreakType', val.value)
            
            this.props.updateState("breakType", val.value, () => 
                this.props.updateProductDisplay()
            )
            if (val.value === "giveaway" || val.value ==="personals") {
                this.updateState("goToDisabled", true)
            } else {
                this.updateState("goToDisabled", false)
            }
            this.updateState('breakType', val)
        //}
    }
    cancel = () => {
        this.props.updateCurrentProducts({})
        this.props.updateState("breakType", "TR", () => {
                localStorage.setItem("newBreakType", "TR")
                this.props.updateProductDisplay()
                this.setNewBreakType("None")
            }
        )
    }
    saveBreak = async () => {
        if (!this.state.disabled) {
            this.updateState("creating", true, () => {
                this.setState({disabled: true})
            })
            let prods = this.props.currentProducts
            var products = Object.values(prods)
            var bodyData = {
                products: [],
                assemblyProducts: []
            }
            products.forEach(val => {
                if (val.product.category == "Repacks") {
                    val.skus.forEach(sku => {
                        bodyData.assemblyProducts.push({
                            ComponentProductID: val.product.id,
                            ProductCode: val.product.sku,
                            PriceTier3: val.product.priceTier3 * val.quantity,
                            Quantity: 1,
                            AdditionalAttribute4: val.product.additionalAttribute4,
                            Name: val.product.name,
                            Category: val.product.category,
                            isPantheon: true,
                            pantheonSerial: sku.value
                        })
                    })
                } else {
                    bodyData.assemblyProducts.push({
                        ComponentProductID: val.product.id,
                        ProductCode: val.product.sku,
                        PriceTier3: val.product.priceTier3 * val.quantity,
                        Quantity: val.quantity,
                        AdditionalAttribute4: val.product.additionalAttribute4,
                        Name: val.product.name,
                        Category: val.product.category,
                        isPantheon: false,
                        pantheonSerial: ""
                    })
                }
                bodyData.products.push({
                    product: val.product,
                    quantity: val.quantity
                })
            })
            let path = window.location.pathname
            let id = path.split('/')
            let breakId =  id[4]
            var response = await fetchData.promise("/Breaking/update/"+breakId, true, "PUT", bodyData)
            if (response.status === 200) {
                this.props.goTo()
            } else {
                console.error('failed')
            }
        
        this.updateState("creating", false)
        this.updateState("disabled", false)
        }
    }
    cancelBreak = () => {
        this.props.goTo()
    }
    isCreateDisabled = () => {
        if (this.state.disabled || this.state.breakType.value === "None") {
            return true
        }
        return false;
    }
    getFooter = () => {
        if (this.props.breakState === "edit") {
            return (
                <div className="card-footer">
                    <Button className="foot-spacing"
                        disabled={this.state.creating}
                        color="danger"
                        onClick={this.cancelBreak}
                    >Cancel</Button>
                    <Button className="foot-spacing" 
                        disabled={this.state.disabled}
                        color="primary"
                        onClick={this.saveBreak}
                    >Save</Button>  
                </div>
            )
        } 
        return (
            <div className="card-footer">
                <Button className="foot-spacing"
                    disabled={this.state.disabled}
                    color="danger"
                    onClick={this.cancel}
                >Cancel</Button>
                <Button className="foot-spacing" 
                    disabled={this.isCreateDisabled()}
                    color="primary"
                    onClick={this.createBreak}
                >Create</Button>
                Go To Break: <Switch 
                    onChange={this.handleSwitchChange} 
                    disabled={this.state.goToDisabled}
                    checked={this.state.goTo}
                />
            </div>
        )
    }
    getTop = () => {
        if (this.props.breakState !== "edit") {
            return (
                <div style={{paddingLeft: '10px', display:'flex'}}>
                    Item/Break Type:&nbsp;
                    <Select 
                        value={this.state.breakType}
                        onChange={this.breakChanged}
                        options={this.state.breakTypes}
                        isClearable={false}
                        isSearchable={false}
                    />
                </div>
            )
        }
    }
    getSlotAmount = (price) => {
        var slots = 1
        var prods = Object.values(this.props.currentProducts)
        var leagues = {}
        prods.forEach(val => {
            if (leagues[val.product.additionalAttribute4] == null) {
                if (val.product.additionalAttribute4 != "") {
                    leagues[val.product.additionalAttribute4] = true
                }
            }
        })
        var slots = 1
        if (prods.length > 0) {
            var breakType = this.state.breakType.value
            if (breakType === "TR") {
                var count = Object.values(leagues).length
                slots = count > 1 ? 31 : (leagues["NFL"] !== null || leagues["NHL" !== null]) ? 32 : 30
            } else if (breakType === "DR") {
                if (leagues["NFL"] !== null) {
                    slots = 8
                } else if (leagues["NHL"] !== null) {
                    slots = 4
                } else if (leagues["NBA"] !== null) {
                    slots = 6
                } else if (leagues["MLB"] !== null) {
                    slots = 6
                }
            }
        }
        return "Slots ("+slots+"): $" + (price / slots).toFixed(2)
    }
    getPrices = () => {
        if (this.props.currentProducts != null ) {
            var ourPrice = 0
            var total = 0
            //var averageCost = 0
            Object.values(this.props.currentProducts).forEach(prod => {
                ourPrice += prod.product.priceTier3 * prod.quantity
                total += Number(prod.quantity)
                //averageCost += prod.product.averageCost * prod.quantity
            })
            return (
                <div style={{paddingLeft: '10px', display:'flex', flexDirection: 'column'}}>
                    <div>Total Retail: ${ourPrice.toFixed(2)}</div>
                    <div>{this.getSlotAmount(ourPrice)}</div>
                    <div>Total Quantity: {total}</div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="main-card breakCard">
                <div className="card-header">Items Selected</div>
                {this.getTop()}
                {this.getPrices()}
                {this.getBody()}
                {this.getFooter()}
            </div>
        )
    }

}
