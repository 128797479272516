import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import { Button } from "@mui/material";

interface AddCardsProps {
  cardsToAdd: number;
  setCards: (value: number) => void;
  type: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  rows: any;
  setRows: (value: any) => void;
  cols: any;
}

const BulkAddCards: React.FC<AddCardsProps> = ({
  cardsToAdd,
  setCards,
  type,
  open,
  setOpen,
  cols,
  rows,
  setRows,
}) => {
  const toggleModal = () => {
    setOpen(!open);
  };

  const handleCreateRecords = (
    cardsToAdd: number,
    setRows: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    setRows((prevRows) => {
      const newRows = [...prevRows];
      const firstRow = newRows[0] || {};
      let lastId = newRows.length > 0 ? newRows[newRows.length - 1].id || 0 : 0;

      for (let i = 1; i <= cardsToAdd; i++) {
        const newRow = cols.reduce((row: any, col: any) => {
          row[col.field] = getDefaultValue(col, firstRow);
          return row;
        }, {} as any);

        newRow.id = lastId + i;
        newRows.push(newRow);
      }
      setOpen(false);
      return newRows;
    });
  };

  const getDefaultValue = (col: any, firstRow: any) => {
    if (firstRow.hasOwnProperty(col.field)) {
      return firstRow[col.field];
    }
    switch (col.type) {
      case "date":
        return null;
      case "number":
        return 0;
      case "text":
      default:
        return "";
    }
  };

  return (
    <div>
      <Button color="primary" onClick={toggleModal}>
        Add Card(s)
      </Button>
      <Modal isOpen={open} toggle={toggleModal} className="add-cards-modal">
        <ModalHeader toggle={toggleModal}>Add Cards</ModalHeader>
        <ModalBody>
          <Label for="cardsToAdd">
            Select the number of new cards you'd like to add:
          </Label>
          <Input
            id="cardsToAdd"
            type="number"
            className="cards-to-add-input"
            value={Math.floor(cardsToAdd)}
            min={1}
            step="1"
            onChange={(event) => {
              setCards(parseInt(event.target.value));
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="add-cards-button"
            onClick={() => {
              handleCreateRecords(cardsToAdd, setRows);
            }}
            disabled={cardsToAdd > 0 ? false : true}
          >
            Add Card(s)
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BulkAddCards;
