import React, { Component } from 'react';
import {useNavigate} from "react-router-dom";
import { Button, Input, Table, InputGroup} from 'reactstrap';
import '../Hamburger.css';
import './break.css';
import FetchModule from "../../Modules/FetchModule";
import NewBreak from "./NewBreak"
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import { Dropdown } from 'bootstrap';
library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);


const fetchData = new FetchModule();

export default class IndividualBreakItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            price: 0,
            customer: "",

            selected: {

            },
            changed: true,
            rendered: false,
            selectedValues: {},
        }    
    }
 // STUFF FOR PRODUCT UPDATES:
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    async componentDidMount() {
        let selected = {}
        this.props.slot.teams.forEach(team => {
            selected[team] = {value: team, label: team}
            delete this.props.options[team]
        })
        this.props.updateState("options", this.props.options)
        this.updateState("selected", selected)
        this.updateState("price", this.props.slot.price)
        this.updateState("customer", this.props.slot.customer)
        this.updateState("selectedValues", this.props.slot.teams)
    }
    textChange = (event) => {
        this.updateState("changed", false)
        let name = event.target.getAttribute('name')
        this.updateState(name, event.target.value)
    }
    valChanged = (options, event) => {
        this.updateState("changed", false)
        var selected = this.state.selected
        let myOptions = {}
        let league = ""
        Object.values(options).forEach(option => {
            myOptions[option.label] = option
        })
        // Make sure that our selected items within this object are not in our product
        let selectedObjects = this.getAllOptions(selected, myOptions) // Combine the objects that are selected and not selected
        Object.values(selectedObjects).forEach(option => {
            if(selected[option.label] === undefined) // if this product doesnt exist          
            {
                selected[option.label] = option // add this to our selected objects
                delete this.props.options[option.label] // delete this from all products
            }  else if (myOptions[option.label] === undefined ){ //&& option.value.prodRefSku == event.name) { // It exists so we re-add it to our products 
                this.props.options[option.label] = option
                delete selected[option.label]
            }
        })
        this.props.updateState("options", this.props.options)
        this.props.updateState("selected", selected)
    }
    getAllOptions =(a, b) => {
        return Object.assign({}, a, b)
    }

    getExtraElements = () => {
        let elements = []

        //this.props.order.forEach(league => {
            //elements.push(
         return(       <th>
                    <Select 
                        options={Object.values(this.getAllOptions(this.props.options, this.state.selected))
                                   // .filter(option => option.value.prodRefSku == league)
                        }
                        //name={league}
                        value={Object.values(this.getAllOptions(this.props.options, this.state.selected))
                           // .filter(option => option.value.prodRefSku == league)
                            .filter(option => this.state.selected[option.label] !== undefined 
                        )}
                        isMulti={true}
                        onChange={this.valChanged}
                    />
                </th>
         )
            //)
        //})
        return elements;
    }
    
    getButtonColor = () => {
        return this.state.changed
        if (this.state.price > 0 && this.state.customer != "") {
            return false;
        }
        return true
    }
    getInput = (inputType, stateName) => {
        return (
            <td>
                <Input 
                    value={this.state[stateName]}
                    name={stateName}
                    type={inputType}
                    onChange={this.textChange}
                />
            </td>

        )
    }
    addProduct =  async () => {
        var teams = []
        Object.values(this.state.selected).forEach(item => {
            teams.push(item.value)
        })
        this.props.slot.teams = teams
        this.props.slot.price = this.state.price
        this.props.slot.customer= this.state.customer
        const response = await fetchData.promise("/Breaking/save", true, "POST", this.props.slot);
        if (response.status == 200) {
            this.updateState('changed', true)
        }
    }
    render() {
        
        return (
            <tr key={this.props.slot.id}>
                <td>Slot {this.props.index}</td>
                {this.getInput("", "customer")}
                {this.getExtraElements()}
                {this.getInput("number", "price")}
                <td><Button onClick={this.addProduct} 
                    value={this.props.slot.id}
                    color="primary"
                    disabled={this.getButtonColor()}
                    >Save</Button>
                </td>
            </tr>
        )
    }

}
