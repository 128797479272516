import React, { Component } from 'react';
import {useNavigate} from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import ReportPage from './PriceManager/PricePage';
import './Hamburger.css';
import FetchModule from "../Modules/FetchModule";
const fetchData = new FetchModule();

library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);

class PriSyncMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuChanged: "",
            menuOpened: false,
            currentCard: null,
            bodyType: "PriceManager",
        }
        localStorage.getItem('user')
    }

    async componentDidMount() {
        const response = await fetchData.fetchResponseAuthed('/User/isAuth', 'GET')
        if (await response.status !== 200) {
            window.location.replace("/dashboard/login")
        }
    }
    updateCurrentCard =(name, state, func) => {
        this.setState({currentCard: null}, () => {
            this.updateState(name, state, func);
        })
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    menuClicked = () => {
        if (!this.state.menuOpened) 
            this.updateState("menuChanged", "open") 
        else
            this.updateState("menuChanged", "") 
        this.updateState("menuOpened", !this.state.menuOpened)
    }

    logout = () => {
        this.props.logout()
        this.props.navigate("/dashboard/login")
    }
    redisplay = (body) => {
        this.setState({bodyType: ""})
        this.setState({bodyType: "PriceManager"})
    }

    getBody = () => {
        return(
            <ReportPage updateState={this.updateState}/>
        )
    }
    render() {
        return (
            <>

                {this.getBody()}
            </>
        )
    }

}

export default function(props) {
    const navigate = useNavigate();
    return <PriSyncMenu logout={props.logout} navigate={navigate}/>;
}