import React, { Component } from 'react';
import './product.css';


export default class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: props.product,
            price: props.price,
            name: "",
            preorder: false
        }
        let nameMe = props.product["name"]
        if(nameMe.includes("PREORDER")) {
            nameMe = nameMe.replace("PREORDER", "")
            this.state.preorder = true;
        }
        this.state.name = nameMe;
    }
    getName = () => {
        if(this.state.preorder) {
            return <td className="fixedName" align={'left'} >{this.state.name}<span style={{color:'blue'}}>&nbsp;PREORDER</span>
                </td>;
        }
        return <td className="fixedName" align={'left'}>
                {this.state.name}
                </td>;
    }
    getPrice = () => {
        var num = Number(this.state.product["price"]).toFixed(2)
        return "$" + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        
    }
    render() {
        if (this.state.price == undefined) {
            return (
                <tr className="fixWidth">
                    {this.getName()}
                    <td className="fixedRelease" align={'left'}>{this.state.product["release"]}</td>
                    <td className="fixedPrice" align={'left'}>{this.getPrice()}</td>
                </tr>
            )
        }
        return (
            <tr className="fixWidth">
                <td className="fixedName" align={'left'}>{this.state.name}&nbsp;</td>
                <td className="fixedRelease" align={'left'}>{this.state.product["release"]}</td>
                <td className="fixedPrice" align={'left'}>{this.state.product["price"]}</td>
            </tr>
        )
    }
}