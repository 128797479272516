import React, { Component } from 'react';
import {useNavigate} from "react-router-dom";
import '../Hamburger.css';
import FetchModule from "../../Modules/FetchModule";
import PantheonBuilderMain from './Builder/PantheonBuilderMain';
import PantheonMainSeries from './PantheonMainSeries';
const fetchData = new FetchModule();

class PantheonMainPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuChanged: "",
            menuOpened: false,
        }
        localStorage.getItem('user')
    }

    async componentDidMount() {
        const response = await fetchData.fetchResponseAuthed('/User/isAuth', 'GET')
        if (await response.status != 200) {
            window.location.replace("/dashboard/login")
        }
    }
    updateCurrentCard =(name, state, func) => {
        this.setState({currentCard: null}, () => {
            this.updateState(name, state, func);
        })
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    menuClicked = () => {
        if (!this.state.menuOpened) 
            this.updateState("menuChanged", "open") 
        else
            this.updateState("menuChanged", "") 
        this.updateState("menuOpened", !this.state.menuOpened)
    }

    logout = () => {
        this.props.logout()
        this.props.navigate("/dashboard/login")
    }

    getBody = () => {
        if (this.props.type !== undefined) {
        if (this.props.type == "pantheon") {
            return (
                <PantheonBuilderMain navigate={this.props.navigate}/>
            )
        }
        return(
            <PantheonMainSeries navigate={this.props.navigate}/>
        )
        }
    }
    changeBody = (body) => {
        window.location.replace(body)
    }
    render() {
        if (this.props.type !== undefined) {
            if (this.props.type == "pantheon") {
                return (
                    <PantheonBuilderMain navigate={this.props.navigate}/>
                )
            }
            return(
                <PantheonMainSeries navigate={this.props.navigate}/>
            )
        }
        return(
            <></>
        )
    }

}

export default function(props) {
    const navigate = useNavigate();
    return <PantheonMainPage type={props.type} key={"packingMenu"} logout={props.logout} navigate={navigate}/>;
}