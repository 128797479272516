import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LicenseInfo } from '@mui/x-license-pro';
import { ThemeProvider } from '@mui/material/styles';
import { UserProvider } from './Components/CommonUtility/UserContext';
import {theme} from './Theme/theme'
import { PageRouter } from './Pages/PageRouter';
import { createRoot } from 'react-dom';
import { SnackbarProvider } from './Contexts/SnackbarContext/SnackbarContext';
import { FeatureFlagProvider } from './Contexts/FeatureFlagContexts/FeatureFlagContext';
LicenseInfo.setLicenseKey('ff2e0180f1158aed1e0ad845f600cc33Tz03MjM3NSxFPTE3MjMxNDcxMzYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <div className="main">
    <ThemeProvider theme={theme}>
      <UserProvider>
        <FeatureFlagProvider>
          <SnackbarProvider>
            <PageRouter/>
          </SnackbarProvider>
        </FeatureFlagProvider>
      </UserProvider>
    </ThemeProvider> 
    </div>
  ,
);

reportWebVitals();
