import { DataGridPro, GridActionsCellItem, GridColDef, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowsProp, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid-pro';import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { useShowHosts } from '../../Contexts/ShowContexts/ShowHost/ShowHostsContext';
import ShowHostDatagridToolbar from './ShowHostDatagridToolbar';
import { ShowHostProvider } from '../../Contexts/ShowContexts/ShowHost/ShowHostContext';
import React, { useCallback } from 'react';
import { UpdateHost } from '../../Contexts/ShowContexts/ShowHost/ShowHostAPICalls';
import { useSnackbar } from '../../Contexts/SnackbarContext/SnackbarContext';
import { useUser } from '../CommonUtility/UserContext';
type ShowHostDatagridProps = {
    children?: React.ReactNode;
}
/* 
    ShowHostDatagrid component must be wrapped by a ShowHostsProvider to work properly
*/
const useShowHostMutation = () =>{ return useCallback(
    (newRow: Partial<ShowHost>, user: any) =>
    new Promise<Partial<ShowHost>>((resolve, reject) => {
        UpdateHost(newRow, user).then((response) => {
            if (response.status == 200) {
                resolve(newRow);
            } else {
                reject(new Error('Failed to update'));
            }
        })
    }),
    []
);
}

const ShowHostDatagrid = ({children} : ShowHostDatagridProps)  => {

    var {newSnackbarMessage} = useSnackbar();
    var {user} = useUser();
    const hostMutation = useShowHostMutation()
    var {hosts, addHost, updateHost, setHosts, hostsError, hostsLoading} = useShowHosts();
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
          event.defaultMuiPrevented = true;
        }
      };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
      };
    
      const handleSaveClick = (id: GridRowId) => async () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
      };
    
      const handleDeleteClick = (id: GridRowId) => () => {
        setHosts(hosts.filter((row) => row.publicId !== id));
      };
    
      const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

      };
      


    const processRowUpdate = async (newRow: ShowHost, oldRow: ShowHost) => {
        var response = await UpdateHost(newRow, user);
        if (response.status == 200) {
            setRowModesModel({ ...rowModesModel, [newRow.id]: { mode: GridRowModes.View } });
            newSnackbarMessage("Updated new host successfully", 'success');
            updateHost(newRow)
        } else if (response.status == 403) {
          var result = await response.json();
          newSnackbarMessage("Error: " +result.message, 'error');
          return oldRow
       } 
        return newRow;
      };

      const handleProcessRowUpdateError = React.useCallback((error) => {
        newSnackbarMessage(error.message, 'error');
      }, []);

      const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
      };
    
    const columns: GridColDef[] = [
        //{ field: 'publicId', headerName: 'Public Id', width: 200 },
        { field: 'firstName', headerName: 'First Name', width: 200, editable: true },
        { field: 'lastName', headerName: 'Last Name', width: 200, editable: true },
        { field: 'displayName', headerName: 'Display Name', width: 200, editable: true },
        { field: 'isActive', headerName: 'Active', width: 200, type: 'boolean', editable: true },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        
                if (isInEditMode) {
                  return [
                    <GridActionsCellItem
                      icon={<SaveIcon />}
                      label="Save"
                      sx={{
                        color: 'primary.main',
                      }}
                      onClick={handleSaveClick(id)}
                    />,
                    <GridActionsCellItem
                      icon={<CancelIcon />}
                      label="Cancel"
                      className="textPrimary"
                      onClick={handleCancelClick(id)}
                      color="inherit"
                    />,
                  ];
                }
        
                return [
                  <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                  />,
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    disabled={true}
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                  />,
                ];
              },
        }
    ];

    return(
        <>
        <ShowHostProvider>
           <DataGridPro 
                rows={hosts}
                columns={columns}
                slots={{
                    toolbar: ShowHostDatagridToolbar
                }}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
           />
        </ShowHostProvider>
        </>
    )
}
  
  
  export default ShowHostDatagrid;
  