import FilterAutocomplete from "../AutocompleteComponents/FilterAutocomplete";
import MultiAutocomplete from "../AutocompleteComponents/MultiAutocomplete";
import StringFilter from "../AutocompleteComponents/StringFilter";

interface FilterProps {
  filterModel: any;
  setFilterModel: (model: any) => void;
  options: any[];
  field: string;
  label: string;
}

type FilterOptionsType = {
  [name: string]: any;
};

function Filter(props: FilterProps): FilterOptionsType {
  const { filterModel, setFilterModel, options, field, label } = props;
  const Operators = [
    {
      label: "equals",
      value: "equals",
      InputComponent: FilterAutocomplete,
      InputComponentProps: {
        id: field + "equals-filter",
        filterModel: filterModel,
        setFilterModel: setFilterModel,
        field: field,
        operator: "equals",
        label: label,
        options: options,
      },
    },
    {
      label: "contains",
      value: "contains",
      InputComponent: StringFilter,
      InputComponentProps: {
        id: field + "-contains-filter",
        filterModel: filterModel,
        setFilterModel: setFilterModel,
        field: field,
        operator: "contains",
      },
    },
    {
      label: "starts with",
      value: "startsWith",
      InputComponent: StringFilter,
      InputComponentProps: {
        id: field + "-startswith-filter",
        filterModel: filterModel,
        setFilterModel: setFilterModel,
        field: field,
        operator: "startsWith",
      },
    },
    {
      label: "ends with",
      value: "endsWith",
      InputComponent: StringFilter,
      InputComponentProps: {
        id: field + "-endswith-filter",
        filterModel: filterModel,
        setFilterModel: setFilterModel,
        field: field,
        operator: "endsWith",
      },
    },
    {
      label: "is empty",
      value: "isEmpty",
      InputComponent: null,
    },
    {
      label: "is not empty",
      value: "isNotEmpty",
      InputComponent: null,
    },
    {
      label: "is any of",
      value: "isAnyOf",
      InputComponent: MultiAutocomplete,
      InputComponentProps: {
        id: field + "-is-any-of-filter",
        filterModel: filterModel,
        setFilterModel: setFilterModel,
        field: field,
        options: options,
      },
    },
  ];

  return {
    Filter,
    Operators,
  };
}

export default Filter;
