import React, { Component } from 'react';
import {Button,
    ModalFooter, ModalHeader, ModalBody, Modal, InputGroup, Dropdown,
    DropdownToggle, DropdownMenu, DropdownItem, Input, Table
} from 'reactstrap';
import { Box, Card, Container, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReportProduct from './PriceProduct';
import PriSync from '../ReportManager/PriSync';
import '../ReportManager/ProductList.css';
import '../Hamburger.css';
import FetchModule from "../../Modules/FetchModule";
const fetchData = new FetchModule();
export default class ReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productsToShow: [],
            products: [],
            validPrices: {},
            loading: false,
            currentCard: null,
            newText: "",
            noBarcodes: false, // tbr
            noDimensions: false, // tbr
            noPrice: false, // tbr
            currentProduct: null,
            modalIsOpen: false,
            noSync: false,
            changed: false,
            confirmExit: false,
            prodName: "",
            sortByState: "marketAmt",
            sortByAsc: 'false',
            filterByInHouse: 'both',
            filterByHasPrice: 'both',
            filterByIsSync: 'both',
            disabled: false,
            globalData: {},
            currentProds: "",
            hasChanged: false,
            myProduct: null,
            inProgress: false,
            page: 0,
            rowsPerPage: 10,
        }
        this.state.sortByState = localStorage.getItem('sortByState') || 'marketAmt';
        this.state.sortByAsc = localStorage.getItem('sortByAsc') || 'false';
        this.state.filterByInHouse = localStorage.getItem('filterByInHouse') || 'both';
        this.state.filterByHasPrice = localStorage.getItem('filterByHasPrice') || 'both';
        this.state.filterByIsSync = localStorage.getItem('filterByIsSync') || 'both';

        this.syncRef = React.createRef();
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
      };
    
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    };


    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    async componentDidMount() {
        var response = await fetchData.fetchResponseAuthed('/Report/all', "GET")
        const data = await response.json();
        const prices = await fetchData.fetchResponseAuthed("/PriSync/price/map","GET");
        const priceData = await prices.json();

        var globalAttrib = await fetchData.fetchResponseAuthed("/PriceModifiers/priceTiers/default")
        const globalData = await globalAttrib.json();
        this.setState({globalData: globalData})
        this.setState({validPrices: priceData})
        this.updateState("products", data);
        this.updateProductDisplay("");
    }
    GetNewDisplay = async () => {
        this.updateState("products", [], () => {
            this.updateProductDisplay();
        });
        var response = await fetchData.fetchProducts('/Report/products', "GET", this.state.newText)
        const data = await response.json()
        this.updateState("products", data);
        this.updateProductDisplay();
    }
    updateProductDisplay = () => {

        const { products, page, rowsPerPage } = this.state;
        const startIdx = page * rowsPerPage;
        const endIdx = startIdx + rowsPerPage;
        const productsToShow = products.slice(startIdx, endIdx);

        this.setState({ productsToShow });
        this.setState({productsToShow:
            this.state.products
                .filter(wholeItem => {
                    let product = wholeItem.product
                    if( (this.state.filterByHasPrice === "yes" && product["PriceTier1"] === 0) ||
                    (this.state.filterByHasPrice === "no" && product["PriceTier1"] > 0)) {
                        return null;
                    } 
                    if ((this.state.filterByInHouse === "yes" && product["OnHand"] === 0) ||
                        (this.state.filterByInHouse === "no" && product["OnHand"] > 0) 
                    ) {
                        return null;
                    } 
                    if ((this.state.filterByIsSync === "yes" && !this.state.validPrices[product.ID]) || 
                        (this.state.filterByIsSync === "no" && this.state.validPrices[product.ID])) {
                        return null;
                    }
                    return wholeItem;
                })
                .sort((a, b) => {
                    let price1 = a.product.PriceTier1
                    let price2 = b.product.PriceTier1
                    let x = 0;
                    let y = 0;
                    switch(this.state.sortByState) {
                        case 'margin':
                            x = price1 === 0 ? 0 : (price1 - a.product.AverageCost) / price1
                            y = price2 === 0 ? 0 : (price2 - b.product.AverageCost) / price2
                            break;
                        case 'marginAmt':
                            x = price1 - a.product.AverageCost
                            y = price2 - b.product.AverageCost
                            break;
                        case 'marketAmt': 
                            x = price1 - this.getAverage(a)
                            y = price2 - this.getAverage(b)
                            break;
                        case 'market':
                            x = price1 === 0 ? 0 : (price1 -this.getAverage(a)) / price1
                            y = price2 === 0 ? 0 : (price2 - this.getAverage(b)) / price2
                            break;
                        case 'urls':
                            x = this.getUrlCount(a)
                            y = this.getUrlCount(b)
                            break;
                        case 'name':
                            x = a.product.Name
                            y = b.product.Name
                            break;
                        case 'price':
                            x = price1
                            y = price2
                            break;
                        default:
                            break;
                    }
                    // Handle the switch from asc/descend
                    if (this.state.sortByAsc === 'true') {
                        let y2 = x;
                        x = y;
                        y = y2;
                    }
                    return x === y ? 0 : x < y ? 1 : -1
                })
        })
    }

    getUrlCount = (wholeItem) => {
        let urls = wholeItem.syncUrls;
        let amt = 0;
        if (urls != null) {
            var keys = Object.keys(urls)
            keys.forEach(function(key) {
                if (urls[key] != null) {
                    amt = amt + 1;
                }
            })
        }
        return amt;
    }
    getAverage = (wholeItem) => {
        let total = 0
        let avg = 0
        if(wholeItem.syncUrls != null) {
            if(wholeItem.syncUrls["blowoutcards.com"] != null) {
                let p = wholeItem.syncUrls["blowoutcards.com"].price
                if (p > 0) {
                    avg = avg + p
                    total = total + 1
                }
            } if (wholeItem.syncUrls["steelcitycollectibles.com"] != null) {
                let p = wholeItem.syncUrls["steelcitycollectibles.com"].price
                if (p > 0) {
                    avg = avg + p
                    total = total + 1
                }
            } if (wholeItem.syncUrls["dacardworld.com"] != null) {
                let p = wholeItem.syncUrls["dacardworld.com"].price
                if (p > 0) {
                    avg = avg + p
                    total = total + 1
                }
            }
            if (total > 0) {
                return avg / total;
            }
        }
        return 0
    }
    textChange = (event) => {
        this.setState({newText: event.target.value})
        //this.updateProductDisplay(event.target.value);
    }
    clearText = () => {
        this.setState({newText: ""}, () =>{
            //this.updateProductDisplay(this.state.searchText, this.state.currentSortBy);
        })
    }
    toggle = () => {
        this.setState({dropdownShow: !this.state.dropdownShow})
    }
    displayProduct = (wholeItem) => {
        return <ReportProduct 
            inProgress={this.state.inProgress}
            key={wholeItem.product["id"]}
            updateState={this.updateState} 
            toggleModal={this.toggleModal}
            globalData={this.state.globalData}
            currentCard={this.state.currentCard}
            currentProds={this.state.currentProds}
            hasChanged={this.state.hasChanged}
            wholeItem={wholeItem} 
            id={"A" + wholeItem.product["id"]}
        />
    }
    getData = () => {
        return this.state.productsToShow.map(wholeItem => this.displayProduct(wholeItem))
    }

    toggleModal = (me) => {
        this.updateState("changed", false)
        var product = me.state.wholeItem.product
        product.id = product.ID
        this.updateState("prodName", product.Name)
        this.updateState("currentProduct", product, () => {
            this.updateState("modalIsOpen", true)
            this.updateState("myProduct", me)
        })
    }
    closeModal = () => {
        this.updateState("currentProduct", null)
        this.updateState("modalIsOpen", false)
    }
    save = async () => {
        var item = this.state.myProduct.props.wholeItem
        if (this.state.changed) {
            this.setState({disabled: true})
            let jsonBody = this.syncRef.current.state.urls
            let id = this.syncRef.current.state.id
            let prodID = this.syncRef.current.state.prodId
            var response = await fetchData.promise("/PriSync/addUrl/"+prodID+"/"+id, true, 'POST', jsonBody)
            if (response.status === 200) {
                var data = await response.json()
                item.syncUrls = data.syncUrls
                item.date = data.date
                item.user = data.user
                this.setState({changed: false})
                this.closeModal();
                // Now response.json() has the PriSyncItem that we want to update the date for. 
            }
        }
        this.setState({disabled: false})
    }
    verify = async () => {
        this.setState({disabled: true})
        let prodID = this.syncRef.current.state.prodId
  
        var response = await fetchData.promise("/PriSync/verify/"+prodID, true, 'POST')
        if (response.status === 200) {
            this.closeModal();
        }
        this.setState({disabled: false})
    }
    confirmExit = () => {
        this.closeModal();
        this.updateState("confirmExit", false)
    }
    toggleSafeClose = () => {
        if(this.state.changed) { 
            this.updateState("confirmExit", !this.state.confirmExit)
        } else {
            this.closeModal();
        }
    }
    getHref = () => {
        let priSyncUrl = this.state.prodName.replace("PREORDER ", "")
        let urlString = priSyncUrl.replaceAll(" ", "+")
        return "https://app.prisync.com/panel?search="+urlString
    }
    radioButtonChange = (event) => {
        this.updateState(event.target.name, event.target.value, () => {
            localStorage.setItem(event.target.name, event.target.value);
            this.updateProductDisplay();
        })
    }
    changeSelected = (event) => {
        this.updateState('sortByState', event.target.value, () => {
            localStorage.setItem('sortByState', event.target.value);
            this.updateProductDisplay();
        })
    }
    render() {
        const { productsToShow, page, rowsPerPage, products } = this.state;
        return (
            <>
                <Card 
                    sx={{
                        padding: '5px',
                        height: '100%'
                    }}
                className="wholePage">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <InputGroup className="whole-search" >
                    <Dropdown
                        className="fit-search"
                        isOpen={this.state.dropdownShow}
                        toggle={this.toggle}
                    >
                    <DropdownToggle caret><i className="fa fa-filter"/></DropdownToggle>
                        <DropdownMenu style={{width: '320px'}}>
                            <DropdownItem header>Filters</DropdownItem>
                            <div>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Yes</th>
                                            <th>No</th>
                                            <th>Both</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr key="house">
                                            <td>In House</td>
                                            <td><input type="radio" value="yes" name="filterByInHouse" onChange={this.radioButtonChange} checked={this.state.filterByInHouse === "yes"}/></td>
                                            <td><input type="radio" value="no" name="filterByInHouse" onChange={this.radioButtonChange} checked={this.state.filterByInHouse === "no"}/></td>
                                            <td><input type="radio" value="both" name="filterByInHouse" onChange={this.radioButtonChange} checked={this.state.filterByInHouse === "both"}/></td>
                                        </tr>
                                        <tr key="price">
                                            <td>Has Price</td>
                                            <td><input type="radio" value="yes" name="filterByHasPrice" onChange={this.radioButtonChange} checked={this.state.filterByHasPrice === "yes"}/></td>
                                            <td><input type="radio" value="no" name="filterByHasPrice" onChange={this.radioButtonChange} checked={this.state.filterByHasPrice === "no"}/></td>
                                            <td><input type="radio" value="both" name="filterByHasPrice" onChange={this.radioButtonChange} checked={this.state.filterByHasPrice === "both"}/></td>
                                        </tr>
                                        <tr key="sync">
                                            <td>Is Sync</td>
                                            <td><input type="radio" value="yes" name="filterByIsSync" onChange={this.radioButtonChange} checked={this.state.filterByIsSync === "yes"}/></td>
                                            <td><input type="radio" value="no" name="filterByIsSync" onChange={this.radioButtonChange} checked={this.state.filterByIsSync === "no"}/></td>
                                            <td><input type="radio" value="both" name="filterByIsSync" onChange={this.radioButtonChange} checked={this.state.filterByIsSync === "both"}/></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <DropdownItem header>Sort by</DropdownItem>
                            <Container>
                                <select value={this.state.sortByState} onChange={this.changeSelected}>
                                    <option value="margin">% Margin Difference</option>
                                    <option value="marginAmt"># Margin Difference</option>
                                    <option value="market">% Market Difference</option>
                                    <option value="marketAmt"># Market Difference</option>
                                    <option value="urls">Amount of URLs</option>
                                    <option value="name">Name</option>
                                    <option value="price">Price</option>
                                </select>
                                <div>Ascending <input type="radio" value={'true'} name="sortByAsc" onChange={this.radioButtonChange} checked={this.state.sortByAsc === 'true'}/></div>
                                <div>Descending <input type="radio" value={'false'} name="sortByAsc" onChange={this.radioButtonChange} checked={this.state.sortByAsc === 'false'}/></div>
                            </Container>
                        </DropdownMenu>
                    </Dropdown>
                <div className="fit-search search-bar">
                    <Input className="fit-search" id="newText" 
                        value={this.state.newText}
                        onChange={this.textChange}
                        onFocus={this.changeFocus}
                        onBlur={this.changeFocus}
                        placeholder="Search"
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                this.GetNewDisplay()
                            }
                        }}
                    />
                    <FontAwesomeIcon onClick={this.clearText} className="search-box-clear" icon="xmark" />
                </div>
                <Button color="primary" onClick={this.GetNewDisplay}><i className="fa fa-search"/></Button>
                </InputGroup>
                    <Table className="fixTable">
                        <thead>
                            <tr className="thead-fix sm-text">
                                <th>Name</th>
                                <th>Price</th>
                                <th>Market Avg</th>
                                <th>Blowoutcards</th>
                                <th>Steelcity</th>
                                <th>Dacardworld</th>
                                <th>Midwest</th>
                                <th>Diamond</th>
                                <th>Giant</th>
                                <th>Chicago</th>
                                <th>rbicru7</th>
                                <th>PriSync</th>
                           </tr>
                        </thead>
                        <tbody className="tableBody">
                            {this.getData()}
                        </tbody>
                    </Table>
                </Card>
                <Modal toggle={this.toggleSafeClose} isOpen={this.state.modalIsOpen} size="xl">
                    <ModalHeader toggle={this.toggleSafeClose}>{this.state.prodName}</ModalHeader>
                    <ModalBody>
                        <PriSync 
                            product={this.state.currentProduct} 
                            ref={this.syncRef}
                            updateState={this.updateState}/>
                    </ModalBody>
                    <ModalFooter>
                        <a className="toolTip" href={this.getHref()} target="_blank" rel="noreferrer">Price sync
                            <span className="tooltiptext">Price sync</span>    
                        </a>
                        <Button onClick={this.verify} disabled={this.state.disabled}>Verify</Button>
                        <Button color={'primary'} onClick={this.save} disabled={this.state.disabled}>Save</Button>
                    </ModalFooter>
                    <Modal isOpen={this.state.confirmExit} toggle={this.toggleSafeClose}>
                        <ModalHeader toggle={this.remove}>You have unsaved changes</ModalHeader>
                        <ModalFooter>
                        <Button onClick={this.toggleSafeClose} color='primary'>Cancel</Button>
                        <Button onClick={this.confirmExit}>Close without saving</Button>
                        <Button onClick={this.toggleSafeClose} color='primary'>Save</Button>

                    </ModalFooter>
                </Modal>
                </Modal>
            </>
        )
    }
}