import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Filter } from "@mui/icons-material";

const extractInitialValueFromFilter = (model, field, operator) => {
  const filter = model?.items?.find((item) => item.field === field && item.operator === operator);
  return filter?.value || [];
};

const FilterAutocomplete = (props) => {
  const{filterModel, setFilterModel, field, operator, label, options} = props;
  const [value, setValue] = useState(extractInitialValueFromFilter(filterModel, field, operator) || []);

  const updateFilterModel = (value) => {
    let reducedValue = null;
    if(value){
      reducedValue = value.label ? value.label:value;
    }

    let reducedOperator = null;
    if(filterModel.items.some(item => item.field === field && item.operator === operator)){
      reducedOperator = filterModel.items.find(item => item.field === field && item.operator === operator).operator;
    }

    var updatedItems = null;
    if (filterModel.items.some(item => item.field === field && item.operator === operator)) {


      updatedItems = [...filterModel.items].map(item => {
        if (item.field === field && item.operator === operator) {
          return {
            ...item,
            operator: reducedOperator,
            value: reducedValue,
          };
        }

        return item;
      });
    } else {
      updatedItems = [...filterModel.items, { field: field, operator: reducedOperator, value: reducedValue }];
    }
    const updatedFilterModel = {
      ...filterModel,
      items: updatedItems,
    };  
    setFilterModel(updatedFilterModel);
  };
  return (
    <Autocomplete
      options={options}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        updateFilterModel(newValue);
      }}
      renderInput={(params) => {
        return (
        <TextField
          {...params}
          label={label}
          shrink={true}
          variant="standard"
        />
      )}}
    />
  );
};

export default FilterAutocomplete;
