import * as React from 'react';
import {createContext, useContext, useState, useEffect} from 'react';
import { Alert, AlertColor, AlertProps, Snackbar } from '@mui/material';

export interface SnackbarMessage {
    children: string;
    severity: AlertColor;
    key: number;
}

const SnackbarContext = createContext<SnackbarContextProps | null>(null);
interface SnackbarContextProps {
    newSnackbarMessage: (children: string, severity: AlertColor) => void
}

type SnackbarProviderProps = {
    children?: React.ReactNode;
}

export const SnackbarProvider = ({children} : SnackbarProviderProps) => {
    var [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
    var [duration, setDuration] = useState(5000);
    var [snackbarOpen, setSnackbarOpen] = useState(false);
      const [snackInfo, setSnackInfo] = React.useState<Pick<
          AlertProps,
          'children' | 'severity'
      > | undefined>({ children: '', severity: 'success'});

    React.useEffect(() => {
        if (snackPack.length > 0) {
          // Set a new snack when we don't have an active one
          setSnackInfo({ children: snackPack[0].children, severity: snackPack[0].severity });
          setSnackPack((prev) => prev.slice(1));
          setSnackbarOpen(true);
        } else if (snackPack.length && snackInfo && snackbarOpen) {
          // Close an active snack when a new one is added
          setSnackbarOpen(false);
        }
      }, [snackPack, snackInfo, snackbarOpen]);

    function newSnackbarMessage(children: string, severity: AlertColor) {
        setSnackPack((prev) => [...prev, { children, severity, key: new Date().getTime() }])
    }
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };
      const handleExited = () => {
        setSnackInfo(undefined);
      };

    const contextValues = {
        newSnackbarMessage
    }

    return (
        <SnackbarContext.Provider value={contextValues}>

            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleClose}
                TransitionProps={{ onExited: handleExited }}
                autoHideDuration={duration}
            >
                <Alert {...snackInfo} onClose={handleClose}/>
            </Snackbar>
            
            {children}
        </SnackbarContext.Provider>
        )
}

export function useSnackbar() {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error("Invalid SnackbarContext, must be wrapped in SnackbarProvider")
    }
    return context;
}