import React from 'react';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import ShowHostAddHostButton from './ShowHostAddHostButton';

interface ShowHostDatagridToolbarProps {
    onAddRecord: () => void;
}

const ShowHostDatagridToolbar: React.FC<ShowHostDatagridToolbarProps> = ({ onAddRecord }) => {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <ShowHostAddHostButton />
        </GridToolbarContainer>
    );
};

export default ShowHostDatagridToolbar;