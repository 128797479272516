import { Button } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import {Card} from "../Types/CardType"

interface PurchaseOrderButtonProps {
  setRows: Function;
  setSelectedRows: Function;
  setOpen: Function;
  open: boolean;
  fetchPromise: Function;
  setSnackbar: Function;
  selectedRows: Card[];
  rows: Card[]; 
  deselect: Function;
}

export default function CreatePurchaseOrderButton(props: PurchaseOrderButtonProps) {
  const { setRows, setSelectedRows, setOpen, open, fetchPromise, setSnackbar, selectedRows, rows, deselect } = props;

  async function createPurchaseOrder() {
    if (!open) {
      setOpen(true);
      const response = await fetchPromise("/SingleCard/new/purchaseOrder", "POST", selectedRows);

      if (response.status === 200) {
        setSnackbar({ children: "Successfully created purchase order", severity: 'success' });
        const data = await response.json();
        setRows(rows.map((row) => (data[row.id] ? data[row.id] : row)));
        setSelectedRows(Object.values(data));
        deselect();
      }

      setOpen(false);
    } else {
      setSnackbar({ children: "Failed to create purchase order", severity: 'error' });
    }
  }

  function verifyPurchaseDisabled(): boolean {
    let disabled = false;
    selectedRows.forEach((row) => {
      if (row.purchaseOrder !== "" || row.productId === 0) {
        disabled = true;
        return true;
      }
    });

    disabled = !disabled && selectedRows.length > 0 ? false : true;
    return disabled;
  }

  return (
    <Tooltip title={"This functionality is currently disabled"}>
      <span>
        <Button disabled={verifyPurchaseDisabled()} color="primary" onClick={createPurchaseOrder}>
          Create Purchase Order
        </Button>
      </span>
    </Tooltip>
  );
}
