import FilterList from "../FilterComponents/FilterList";
import BulkChangeStatus from "../ToolbarComponents/BulkChangeStatus";
import { FilterBetweenFields } from "../FilterComponents/Between";
import "../../Hamburger.css";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridFilterModel,
} from "@mui/x-data-grid";
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import "../singles.css";
import { Card } from "../Types/CardType";
import CardExport from "../CardExport";

interface CustomToolbarProps {
  filterModel: GridFilterModel;
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  snackbar: any;
  setSnackbar: React.Dispatch<React.SetStateAction<any>>;
  onFilterChange: (filter: any) => void;
  premadeFilter: GridFilterModel;
  selectedRows: Card[];
  statusTypes: any;
  dataFields: any;
  setDataFields: any;
  players: any;
  colOrder: string[];
  colVis: any;
  changeStatusOnLiveCells: any;
  filterList: any;
  setFilterList: any;
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  filterModel,
  setFilterModel,
  refresh,
  setRefresh,
  snackbar,
  setSnackbar,
  onFilterChange,
  premadeFilter,
  selectedRows,
  statusTypes,
  dataFields,
  setDataFields,
  players,
  colOrder,
  colVis,
  changeStatusOnLiveCells,
  filterList,
  setFilterList,
}) => {
  const handleClearFilters = () => {
    setFilterModel({
      items: [],
      quickFilterValues: [],
    });
    setRefresh(!refresh);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
      <Button
        startIcon={<DeleteIcon />}
        onClick={handleClearFilters}
      >
        Clear Filters
      </Button>
      <FilterList
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        refresh={refresh}
        setRefresh={setRefresh}
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        filterList={filterList}
        setFilterList={setFilterList}
      />
      <BulkChangeStatus 
        selectedRows={selectedRows} 
        statusTypes={statusTypes}
        dataFields={dataFields}
        setDataFields={setDataFields}
        players={players}
        changeStatusOnLiveCells={changeStatusOnLiveCells}
      />
      <CardExport filterModel={filterModel} colOrder={colOrder} colVis={colVis} />
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
