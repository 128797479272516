import { DashboardLayout } from '../Components/DashboardComponents/DashboardLayout';
import { Box, Button, Container, Divider, Typography } from '@mui/material';
import CardsHome from '../Dash/SinglesPage/CardsHome';
import ShowHostDatagrid from '../Components/ShowHostComponents/ShowHostDatagrid';
import {ShowHostsProvider} from '../Contexts/ShowContexts/ShowHost/ShowHostsContext';
type ShowHostsPageProps = {
    children?: React.ReactNode;
}

const ShowHostsPage = ({children} : ShowHostsPageProps)  => {
    
    
    return(
    <Box
        sx={{
            width: '100%',
            height: '100%',
            padding: '5px'
        }}
        >
        <Typography variant="h4" sx={{marginBottom: '3px'}}>Hosts Manager Page</Typography>
        <Divider sx={{borderBottomWidth: 3}}/>
        <ShowHostsProvider>
            <ShowHostDatagrid/>
        </ShowHostsProvider>
    </Box>)
}
  
  
  export default ShowHostsPage;
  