import { useLocalStorage } from "./useLocalStorage";

export const useRepackStore = () => {
    const [selectedForRepack, setSelectedForRepack] = useLocalStorage("selectedForRepack", []);

    const addToRepackStoreRepackVersion = (rows) => {
      
      setSelectedForRepack((selectedForRepack)=>{
        var tempStore = [...selectedForRepack]
        rows.forEach(card=>{
          
          if(!tempStore.some(e=>e.id===card.id)){
            tempStore.push(card)
          }
        })
        return tempStore
      })
    };
    
    const removeFromRepackStoreRepackVersion = (id)=>{
      setSelectedForRepack((selectedForRepack)=> {return (selectedForRepack.filter(e=>e.id!==id))})
    }

    const addToRepackStore = (rows) => {
      var tempStore = [...selectedForRepack]
      rows.forEach(card=>{
        
        if(!tempStore.some(e=>e.id===card.id)){
          tempStore.push(card)
        }
      })
      setSelectedForRepack(tempStore)
    };
    
    const clearRepackStore = ()=>{
      setSelectedForRepack([])
    }
    const removeFromRepackStore = (id)=>{
      var filtererdList = selectedForRepack.filter(e=>e.id!==id)
      setSelectedForRepack(filtererdList)
        
    }


    return [selectedForRepack, addToRepackStoreRepackVersion,clearRepackStore,removeFromRepackStoreRepackVersion,addToRepackStore,removeFromRepackStore];
};
