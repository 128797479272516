import React, { Component } from 'react';
import { Button, Table, InputGroup, Input } from 'reactstrap';

import FetchModule from "../../../Modules/FetchModule";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);

const fetchData = new FetchModule();

export default class CurrentPantheonProducts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            total: 0
        }

    }

    async componentDidMount() {
 
    }
    async componentDidUpdate() {
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    getAverageAmt = () => {
        if (this.props.currentProducts !== undefined && this.props.currentProducts !== null) {
            var total = 0
            Object.values(this.props.currentProducts).forEach(prod => {
                total += prod.quantity * prod.product.averageCost
            })
            return "$"+total.toFixed(2)
        }
        return 0
    }
    textChange = (event) => {
        this.updateState('searchText', event.target.value)
        this.updateProductDisplay(event.target.value)
    }
    addNewBreakProduct = () => {
        var prods = this.state.currentProducts
        prods.push({
            product: null,
            quantity: 0,
        })
        this.updateState("currentProducts", prods)
    }
    getTopBar = () => {
        return (
            <InputGroup className="whole-search" >
                <div className="fit-search search-bar">
                    <Input className="fit-search" id="searchText" 
                        value={this.state.searchText}
                        onChange={this.textChange}
                        placeholder="Search"
                    />
                    <FontAwesomeIcon onClick={this.clearText} className="search-box-clear" icon="xmark" />
                </div>
            </InputGroup>
        )
    }
    removeProduct = (event) => {
        let prods = this.props.currentProducts
        delete prods[event.target.value]
        this.props.updateState('currentProducts', prods)
        localStorage.setItem('pantheonProducts', JSON.stringify(prods))
        this.props.updateProductDisplay()
    }
    quantityChanged = (event) => {
        var currentAmount = 0
        Object.values(this.props.currentProducts).forEach(prod => {
            currentAmount += prod.quantity;
        })
        let prods = this.props.currentProducts
        let prod = prods[event.target.id]
        let diff = prod.product.onHand - prod.product.allocated
        if (currentAmount < 5 || event.target.value < prod.quantity) {
            if ( event.target.value > diff) {
                prods[event.target.id].quantity = diff
            } else if (event.target.value > 0){
                prods[event.target.id].quantity = parseInt( event.target.value) 
            }
            this.props.updateCurrentProducts(prods)
        }
    }
    displayProduct = (item) => {
        return (
            <tr key={item.product.id}>
                <td>{item.product.name}</td>
                <td>                    
                    <Input className="fit-search" 
                        id={item.product.id} 
                        value={item.quantity}
                        type="number"
                        onChange={this.quantityChanged}
                        placeholder="Enter in a valid quantity"
                    />
                </td>
                <td>
                    <Button color="danger" onClick={this.removeProduct} value={item.product.id}>X</Button>
                </td>
            </tr>
        )
    }
    getData = () => {
        if (this.props.currentProducts != null) {
            var prods = Object.values(this.props.currentProducts)
            if (prods) {
                return prods.map(item => this.displayProduct(item))
            }
        }
    }

    createPantheon = async () => {
        if (!this.state.disabled) {
            this.updateState("disabled", true)
            var bodyData = []
            Object.values(this.props.currentProducts).forEach(prod => {
                bodyData.push({
                    ProductID: prod.product.id,
                    quantity: prod.quantity
                })
            })
            const response = await fetchData.promise("/Pantheon/new/build/"+this.props.series, true, "POST", bodyData)
            if (response.status === 200) {
                //const data = response.json()
                this.props.updateCurrentProducts({})
            }
            this.updateState("disabled", false)
        }
    }
    getBody = () => {
        return(
            <div className="card-body">
                <Table >
                    <thead>
                        <tr className="thead-fix sm-text align">
                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody className="tableBody">
                        {this.getData()}
                    </tbody>
                </Table>
            </div>
        )
    }
    cancel = () => {
        this.props.updateCurrentProducts({})
    }
    isCreateDisabled = () => {
        if (this.state.disabled) {
            return true
        }
        var currentAmount = 0
        Object.values(this.props.currentProducts).forEach(prod => {
            currentAmount += prod.quantity;
        })
        if (currentAmount !== 5 ) {
            return true
        }
        return false
    }
    
    getFooter = () => {
        return (
            
            <div className="card-footer">
                <div>
                    Total: {this.getAverageAmt()}
                </div>
                <Button className="foot-spacing"
                    disabled={this.state.disabled}
                    color="danger"
                    onClick={this.cancel}
                >Cancel</Button>
                <Button className="foot-spacing" 
                    disabled={this.isCreateDisabled()}
                    color="primary"
                    onClick={this.createPantheon}
                >Create</Button>
            </div>
        )
    }

    render() {
        return (
            <div className="main-card breakCard">
                <div className="card-header">
                    Cards selected for Pantheon
                </div>
                {this.getBody()}
                {this.getFooter()}
            </div>
        )
    }

}
