import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Checkbox, FormControlLabel, Typography, CircularProgress } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import useFetchPromise from "../../../Components/CommonUtility/useFetchPromise";
import BulkAddCardsSuccessModal from "./BulkAddCardsSuccessModal";
import FetchModule from "../../../Modules/FetchModule";

const BulkCardsSubmitModal: React.FC<any> = ({
  cols,
  rows = [],
  pinnedRows = { top: [] },
  type,
  setRows,
  label,
  updatedRowsJson,
  guid,
  typeTitle
}) => {

  const fetchData = new FetchModule();
  const { user } = useUser();
  const fetchPromise = useFetchPromise();
  const [open, setOpen] = useState(false);
  const [responseOpen, setResponseOpen] = useState(false);
  const [response, setResponse] = useState<any>(null);
  const [clearRecordsChecked, setClearRecordsChecked] = useState(true);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    setOpen(!open);
    if (!open) {
      setError(false);
      setErrorText("");
    }
  };

  const filteredRows = rows.filter(
    (row: any) => !pinnedRows.top.some((pinnedRow: any) => pinnedRow.id === row.id)
  );

  const toggleResponseModal = () => {
    setResponseOpen(!responseOpen);
  };

  const updateBody = {
    body: {
      UserId: user["id"],
      label: label,
      data: updatedRowsJson,
      isDeleted: clearRecordsChecked,
    },
    guid,
  };

  const handleCreateRecords = async () => {
    setLoading(true);
    try {
      const response = await fetchData.promise(
        "/Card/update/cardlist",
        true,
        "POST",
        updateBody
      );

      await fetchPromise("/Card/new/bulkcards", "POST", {
        UserId: user["id"],
        Type: type,
        body: filteredRows
      }).then(async (res: any) => {
        setOpen(false);
        const data = await res.json();
        setResponse(data);
        if (clearRecordsChecked) {
          handleClearRecords();
        }
        toggleResponseModal();
      }).catch((error: any) => {
        setError(true);
        setErrorText(error.toString().replace("Error: ", "") + ".");
        console.error("Error while fetching:", error);
      
      });
      ;
    } catch (error: any) {
      setError(true);
      setErrorText(error.toString().replace("Error: ", "") + ".");
      console.error("Error while fetching:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearRecords = () => {
    localStorage.removeItem("bulkCardRows");
    setRows([]);
    setClearRecordsChecked(true); // Re-check the checkbox after clearing
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setClearRecordsChecked(isChecked);
  };

  const headerString = `Add ${typeTitle} Cards from '${label}' to Single Cards Page.`;

  const deleteString = `Delete '${label}' after adding cards to Single Cards Page.`;

  return (
    <div>
      <Button color="primary" onClick={toggleModal}>
        Save Cards to Single Cards Page
      </Button>
      <Modal isOpen={open} toggle={toggleModal} className="add-cards-modal">
        <ModalHeader toggle={toggleModal}>{headerString}</ModalHeader>
        <ModalBody className="add-cards-modal-body">
          {loading ? (
            <div className="spinner-container">
              <CircularProgress />
            </div>
          ) : (
            <>
              <DataGridPro density="compact" rows={filteredRows} columns={cols} />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clearRecordsChecked}
                    onChange={handleCheckboxChange}
                    name="clearRecords"
                    color="primary"
                  />
                }
                label={deleteString}
              />
              {error && (
                <Typography color="error" variant="body2">
                  {errorText}
                </Typography>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {error ? (
            <Button color="primary" onClick={toggleModal}>
              Back
            </Button>
          ) : (
            <Button
              color="primary"
              className="add-cards-button"
              onClick={handleCreateRecords}
              disabled={loading}
            >
              Add {typeTitle} Card(s)
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <BulkAddCardsSuccessModal
        open={responseOpen}
        setOpen={setResponseOpen}
        response={response}
        type={type}
        number={filteredRows.length}
      />
    </div>
  );
};

export default BulkCardsSubmitModal;
