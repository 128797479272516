import React, { Component } from 'react';
import {useNavigate} from "react-router-dom";
import { Button, Input, Table, InputGroup} from 'reactstrap';
import '../Hamburger.css';
import './break.css';
import FetchModule from "../../Modules/FetchModule";
import NewBreak from "./NewBreak"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import IndividualBreakItem from './IndividualBreakItem';
import CreateBreaks from './CreateBreaks';
library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);


const fetchData = new FetchModule();

export default class IndividualBreakProducts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editState: false,
            editData: {},
        }
        let path = window.location.pathname
        let id = path.split('/')
        this.state.breakId = id[4];
        
    }

    // UPDATES PRODUCT DISPLAY ON RENDERS
    displayProduct = (product) => {
        return (
            <tr key={product.id}>
                <td>{product.prodRefSku}</td>
                <td>{product.name}</td>
                <td>${product.price}</td>
                <td>{product.quantity}</td>
            </tr>
                
        )
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    getData = () => {
        return this.props.products.map(product => this.displayProduct(product));
    }
    changeState = () => {
        this.updateState("editState", true)
    }
    noEdit = async () => {
        const response = await fetchData.fetchResponseAuthed("/Breaking/get/"+this.state.breakId, "GET");
        const data = await response.json()
        this.props.updateState('product', data)
        this.updateState('editState', false)
    }
    getEditBody = () => {
        return (
            <CreateBreaks
                breakType="create"
                breakState="edit"
                navigate={this.props.navigate}
                goTo={this.noEdit}
            />
        )
    }
    render() {
        if (this.state.editState) {
            return this.getEditBody()
        }
        return (
            <div className={"dashboard-body"}>
                <div className="card" style={{height: '90%'}}>
                    <div className="card-header">{this.props.breakItem.name}</div>
                    <div className="card-body" style={{overflow: 'hidden'}}>
                        <Table className="fixTable">
                            <thead>
                                <tr className="thead-fix">
                                    <th>SKU</th>
                                    <th>Name</th>
                                    <th>Retail Price</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody className="tableBody">
                                {this.getData()}
                            </tbody>
                        </Table>
                    </div>
                    <div className="card-footer">
                        <Button color="primary" onClick={this.changeState}>Edit</Button>
                    </div>
                </div>
            </div>
        )
    }

}
