import { Box, Button, Divider, Dialog, DialogContent, DialogTitle, DialogActions, TextField, FormControlLabel, Checkbox, FormControl, Grid } from '@mui/material';
import { useShowHost } from '../../Contexts/ShowContexts/ShowHost/ShowHostContext';

type ShowHostModalProps = {
    children?: React.ReactNode;
}
/* 
    ShowHostDatagrid component must be wrapped by a ShowHostsProvider to work properly
*/
const columns = [
    { field: 'PublicId', headerName: 'Public Id', width: 200 },
    { field: 'FirstName', headerName: 'First Name', width: 200 },
    { field: 'LastName', headerName: 'Last Name', width: 200 },
    { field: 'DisplayName', headerName: 'Display Name', width: 200 },
    { field: 'IsActive', headerName: 'Active', width: 200 },
    { field: 'DefaultComission', headerName: 'Comission', width: 200 }
];


const ShowHostModal = ({children} : ShowHostModalProps)  => {
    //const { saveTodo } = React.useContext(TodoContext) as TodoContextType;

    var {
        firstName, setFirstName, firstNameValid,
        lastName, setLastName, lastNameValid,
        displayName, setDisplayName, displayNameValid,
        isActive, setIsActive,
        canCreate,
        createHost,
        clearData,
        createHostModalOpen, setCreateHostModalOpen, closeCreateHostModal,
    } = useShowHost();
    return(
        <>
           <Dialog
                open={createHostModalOpen}
                onClose={closeCreateHostModal}
           >
                <DialogTitle>Create new Host</DialogTitle>
                <Divider />
                <DialogContent >
                    <FormControl
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                          }}
                          noValidate
                          autoComplete="off"
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField id="first-name" 
                                    label="First Name" 
                                    value={firstName} 
                                    error={!firstNameValid()}
                                    onChange={(e: any) => {setFirstName(e.target.value)}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField id="last-name" label="Last Name" value={lastName} error={!lastNameValid()} onChange={(e: any) => {setLastName(e.target.value)}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField id="display-name" label="Display Name" value={displayName} error={!displayNameValid()} onChange={(e: any) => {setDisplayName(e.target.value)}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel control={<Checkbox checked={isActive} onChange={(e: any) => {setIsActive(e.target.checked)}}/>} label="Active Host" />
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => {closeCreateHostModal(false)}}>Cancel</Button>
                    
                    <Button onClick={() => {createHost()}} disabled={!canCreate}>Create</Button>
                    
                </DialogActions>
           </Dialog>
        </>
    )
}
  
  
  export default ShowHostModal;
  