import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DataGridPro, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { GridActionsCellItem, GridRowId } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { style } from "@mui/system";
import { Card } from "../../SinglesPage/Types/CardType";
import { StagedBox } from "../Types/StagedBoxType";

export default function RepackStorePreview(props: {
  deleteRepackFromStore: (arg0: GridRowId) => void;
  drop: (event: React.DragEvent<HTMLDivElement>) => void;
  dragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  rows: readonly any[];
  setSelectedItems: (arg0: any) => void;
  dragStart: (event: React.DragEvent<HTMLDivElement>) => void;
  clearRepackStore: () => void;
}) {
  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "",

      renderCell: (params: GridRenderCellParams) => {
        var id = params.id;
        return (
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Remove"
            className="textPrimary"
            onClick={(event) => {
              props.deleteRepackFromStore(id);
            }}
            color="inherit"
          />
        );
      },
    },
    {
      field: "inventoryId",
      headerName: "Inv Id",
      editable: false,
    },
    {
      field: "name",
      headerName: "Card",
      editable: false,
    },
    {
      field: "comp",
      headerName: "Comp",
      editable: false,
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      editable: false,
    },
  ];
  const [selectionModel, setSelectionModel] = useState<any[]>([]);

  return (
    <div onDrop={props.drop} onDragOver={props.dragOver}>
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGridPro
          sx={{
            maxHeight: "700px",
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
          density="compact"
          rows={props.rows}
          columns={columns}
          pagination={false}
          disableColumnReorder={true}
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRows = props.rows.filter((row: Card) =>
              selectedIDs.has(row.id)
            );
            props.setSelectedItems(selectedRows);
            setSelectionModel(ids);
          }}
          rowSelectionModel={selectionModel}
          slotProps={{
            row: {
              draggable: "true",
              onDragStart: (event) => props.dragStart(event),
              onDragOver: (event) => props.dragOver(event),
            },
          }}
        />
      </Box>
      <button className="btn" onClick={(event) => props.clearRepackStore()}>
        Clear Selection
      </button>
    </div>
  );
}
