import React, { useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Paper from "@mui/material/Paper";
import FetchModule from '../../../Modules/FetchModule';
import { useUser } from '../../../Components/CommonUtility/UserContext';
import { useGridApiContext } from '@mui/x-data-grid';
import { matchSorter } from "match-sorter";

interface SimpleAutocompleteProps {
    id: any;
    field: any;
    options: string[];
    setOptions: any;
    value: string;
    width: any;
}

const filter = createFilterOptions<string>();

const SimpleAutocomplete: React.FC<SimpleAutocompleteProps> = ({ id, field, options, setOptions, value, width }) => {
    const [dialogValue, setDialogValue] = useState<string>("");
    const [val, setVal] = useState<string>(value || "");
    const [open, setOpen] = useState(false);
    const [currentOptions, setCurrentOptions] = useState<string[]>(options);
    const fetchData = new FetchModule();
    const apiRef = useGridApiContext();
    const { user } = useUser() as any;

    const handleChange = async (event: any, newValue: any) => {
        let valueToSet = newValue;
        if (typeof newValue === "string") {
            if (newValue.startsWith("Add ")) {
                valueToSet = newValue.substring(4).replace(/"/g, '');
                setDialogValue(valueToSet);
                setOptions((prevOptions: any) => [...prevOptions, valueToSet]);
                await apiRef.current.setEditCellValue({ id, field, value: valueToSet });
                setOpen(true);
            } else {
                setDialogValue(valueToSet);
                await apiRef.current.setEditCellValue({ id, field, value: valueToSet });
                setVal(valueToSet);
                setOpen(false);
            }
        } else if (newValue && newValue.inputValue) {
            if (newValue.inputValue.startsWith("Add ")) {
                valueToSet = newValue.inputValue.substring(4).replace(/"/g, '');
                setDialogValue(valueToSet);
                setOptions((prevOptions: any) => [...prevOptions, valueToSet]);
                await apiRef.current.setEditCellValue({ id, field, value: valueToSet });
                setOpen(true);
            } else {
                setDialogValue(valueToSet.inputValue);
                await apiRef.current.setEditCellValue({ id, field, value: valueToSet.inputValue });
                setVal(valueToSet.inputValue);
                setOpen(false);
            }
        } else {
            valueToSet = valueToSet || "";
            setVal(valueToSet);
            await apiRef.current.setEditCellValue({ id, field, value: valueToSet });
        }
    };

    const handleFilterOptions = (options: string[], params: any) => {
        const { inputValue } = params;
        var filtered = matchSorter(options, inputValue, {}).splice(0, 15);
        if (inputValue !== "") {
            filtered.push(`Add "${inputValue}"`);
        }
        return filtered;
    }

    const handleKeyDown = (event:any) => {
        if (event.key === "Tab") {
          event.preventDefault();
          const inputValue = event.target.value;
          var filtered = matchSorter(options, inputValue, {}).splice(0, 15);
          console.log(filtered);
    
          if(filtered.length > 0) {
            handleChange(event, filtered[0]);
            event.target.blur();
          }

        } else if (event.key === "Enter") {
          event.stopPropagation();
          event.preventDefault();
    
          const inputValue = event.target.value;
          var filtered =  matchSorter(options, inputValue).splice(0, 15);
    
          if(filtered.length > 0) {
            handleChange(event, filtered[0]);
            event.target.blur();
          }
    
          else if (filtered.length > 0) {
            handleChange(event, filtered[0]);
            event.target.blur();
          }
        }
      };

    return (
        <Autocomplete
            sx={{ width: width }}
            value={val}
            onChange={handleChange}
            filterOptions={(options, params) => handleFilterOptions(currentOptions, params)}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={options}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            freeSolo
            onKeyDown={handleKeyDown}
            renderInput={(params) => (
                <TextField
                    {...params}
                    value={val}
                    label="Select an option"
                    variant="outlined"
                />
            )}
            PaperComponent={({ children }) => (
                <Paper style={{ border: "2px solid #5048E5", top: "-1000px" }}>
                    {children}
                </Paper>
            )}
        />
    );
};

export default SimpleAutocomplete;
