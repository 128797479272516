import React, { Component } from 'react';
import {useNavigate} from "react-router-dom";
import { Button, Table, InputGroup, Dropdown, Input, ModalBody, ModalHeader, Modal, ModalFooter } from 'reactstrap';
import '../Hamburger.css';
import './break.css';
import GiveawayListItem from "./GiveawayListItem";
import FetchModule from "../../Modules/FetchModule";
import BreakListItem from './BreakListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);

const fetchData = new FetchModule();

export default class GiveawayList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activePage: "breaks",
            currentProducts: {},
            products: [],
            productsToShow: [],
            searchText: "",
            sessionId: 0,
            modalOpen: false,
            itemToRemove: null,
            saving: false,
        }
        let path = window.location.pathname
        let id = path.split('/')
        this.state.sessionId = id[3]
    }

    async componentDidMount() {
        const response = await fetchData.fetchResponseAuthed("/Breaking/giveaways/"+this.state.sessionId, "GET");
        const data = await response.json()
        this.updateState("products", data)
        this.updateProductDisplay(this.state.searchText);
    }
    updateProductDisplay = (text) => {
        this.setState({productsToShow:
            this.state.products
                .sort((a, b) => {
                    let x = a.breakID
                    let y = b.breakID
                    return x == y ? 0 : x < y ? 1 : -1;
                })
                .filter(product => {
                    var containsAll = true;
                    text.split(' ').forEach(word => {
                        if (!product.name.toLowerCase().includes(word.toLowerCase())) {
                            containsAll = false;
                        }
                    })
                    return containsAll ? product : null;
                })
        })
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    textChange = (event) => {
        this.updateState('searchText', event.target.value)
        this.updateProductDisplay(event.target.value)
    }
    removeModal = (item) => {
        this.updateState("itemToRemove", item, () => {
            this.updateState("modalOpen", true)
        })
    }
    displayProduct = (item) => {
        return (
            <GiveawayListItem 
                removeModal={this.removeModal}
                item={item} 
                key={item.id}/>
        )
    }

    getData = () => {
        return this.state.productsToShow.map(product => this.displayProduct(product))
    }
    toggleModal = () => {
        if (!this.state.saving) {
            this.updateState("modalOpen", !this.state.modalOpen)
        }
    }
    createBreak = async () => {
        let prods = this.props.currentProducts
        var products = Object.values(prods)
        var bodyData = []
        products.forEach(val => {
            bodyData.push({
                ProductCode: val.product.sku,
                PriceTier1: val.product.priceTier1 * val.quantity,
                Quantity: val.quantity
            })
        })
        var response = await fetchData.promise("/Breaking/new", true, "POST", bodyData)
    }
    getModalText = () => {
        return this.state.itemToRemove == null ? "no" : this.state.itemToRemove.productName
    }
    deleteItem =async  () => {
        if (!this.state.saving) {
            this.updateState('saving', true)
            let id = this.state.itemToRemove.id
            const response = await fetchData.promise("/Breaking/remove/giveaway/"+id, true, "DELETE", "")
            if (response.status == 200) {
                const data = await response.json()
                this.updateState("products", data)
                this.updateProductDisplay(this.state.searchText)
                this.updateState("modalOpen", false)
            }
            this.updateState('saving', false)
        }
    }
    getSearchBody = () => {
        return (
            <div style={{height: '95%'}}>
                <InputGroup className="whole-search" >
                <div className="fit-search search-bar">
                    <Input className="fit-search" id="searchText" 
                        value={this.state.searchText}
                        onChange={this.textChange}
                        placeholder="Search"
                    />
                    <FontAwesomeIcon onClick={this.clearText} className="search-box-clear" icon="xmark" />
                </div>
                </InputGroup>
                <Table className="fixTable">
                        <thead>
                            <tr className="thead-fix">
                                <th>Product</th>
                                <th>Giveaway Name</th>
                                <th>Customer</th>
                                <th>Date Created</th>
                                <th>Settings</th>
                                <th>Remove</th>
                           </tr>
                        </thead>
                        <tbody className="tableBody" style={{overflowY: "scroll"}}>
                            {this.getData()}
                        </tbody>
                </Table>
            </div>
        )
    }
    render() {
        return (
            <div className="dashboard-nav-right-flip">
                <div className="main-card" style={{padding: "10px"}}>
                    {this.getSearchBody()}
                </div>
                <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}></ModalHeader>
                    <ModalBody>
                        Would you like to delete {this.getModalText()}?
                    </ModalBody>
                    <ModalFooter>
                        <Button color='danger' disabled={this.state.saving} onClick={this.toggleModal}>Cancel</Button>
                        <Button color='primary' disabled={this.state.saving}  onClick={this.deleteItem}>Confirm</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

}
