import React, { Component } from 'react';
import { Button
} from 'reactstrap';
import RefreshDB from '../../MangementPage/RefreshDB';
import '../ReportManager/ProductList.css';
import '../Hamburger.css';

import FetchModule from "../../Modules/FetchModule";
import ToggleSwitch from './ToggleSwitch';
import DownloadBarcodes from './DownloadBarcodes';
const fetchData = new FetchModule();

class SettingsMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            priceSet: null,
            isDisabled: true,
        }

        this.syncRef = React.createRef();
    }

    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    async componentDidMount() {
        var response = await fetchData.fetchResponseAuthed('/Settings/all', 'GET')
        if (response.status === 200) {
            var data = await response.json()
            this.setState({priceSet: data[0]})
            this.updateState("isDisabled", false)
        }
    }

    logout = () => {
        this.props.logout()
        this.props.navigate("/dashboard/login")
    }
    priceSetting = async (value) => {
        var response = await fetchData.fetchResponseAuthed('/Settings/update/prices/'+value, 'POST')
        if (response.status === 200) {
            this.setState({priceSet: value})
            return true;
        }
        return false;
    }
    getPriceSetting = () => {
        if (this.state.priceSet != null) {
            return (
                <ToggleSwitch label="Prices changed from dear "
                    isDisabled={true}
                    fetchSetting={this.priceSetting} 
                    value={this.state.priceSet.enabled}/>
            )
        }
    }

    updatePendingNames = ()=>{
        fetchData.fetchResponseAuthed('/Settings/update/pendingNames', 'POST')
    } 

    render() {
        return (
            <div>
                <div className="card" style={{width: '99%', height: '100%'}}>
                    <div className="card-header"><h4>Settings</h4></div>
                    <div className="card-body">
                        {this.getPriceSetting()}
                        <DownloadBarcodes/>
                    </div>
                    <div className="card-footer">
                        <RefreshDB/>
                    </div>
                    <div>
                        <Button color="danger" onClick={this.updatePendingNames}>Fix Pending Reception Names/Skus</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default function(props) {
    return <SettingsMenu />;
}