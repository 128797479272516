import "../../Hamburger.css";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import BulkAddCards from "./BulkAddCardsModal";
import useFetchPromise from "../../../Components/CommonUtility/useFetchPromise";
import "../singles.css";
import React from "react";
import BulkClearCards from "./BulkClearCardsModal";

interface ToolbarProps {
  type: string;
  user: any;
  rows: any;
  setRows: any;
  cols: any;
}

const BulkCardsToolbar: React.FC<ToolbarProps> = (props: any) => {
  const [cardsToAdd, setCards] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [deleteCardsModal, setDeleteCardsModalOpen] = React.useState(false);
  const { user, setSnackbar, type, rows, setRows, cols } = props;

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <BulkAddCards
        cardsToAdd={cardsToAdd}
        setCards={setCards}
        type={type}
        open={open}
        setOpen={setOpen}
        rows={rows}
        setRows={setRows}
        cols={cols}
      />
      {/* <BulkClearCards
        cardsToAdd={cardsToAdd}
        setCards={setCards}
        type={type}
        open={deleteCardsModal}
        setOpen={setDeleteCardsModalOpen}
        rows={rows}
        setRows={setRows}
        cols={cols}
        deleteCardsModal={deleteCardsModal}
        setDeleteCardsModalOpen={setDeleteCardsModalOpen}
      /> */}
    </GridToolbarContainer>
  );
};

export default BulkCardsToolbar;
