
import React, {useState, useEffect} from 'react'; 
import {Button} from 'reactstrap';
import FetchModule from "../../Modules/FetchModule";
import Box from '@mui/system/Box';
import styled from '@mui/system/styled';
import { DataGrid } from '@mui/x-data-grid';
const fetchData = new FetchModule();

const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    border: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
    padding: theme.spacing(1),
    borderRadius: '4px',
    textAlign: 'center',
  }));
  const useFetchGet = (url, type) => {
    const [data, setData] = useState(null)
    useEffect(() => {
        fetchData.fetchResponseAuthed(url, type)
            .then((res) => res.json())
            .then((data) => setData(data));
  
    }, [url])
    return [data, setData]
  }
  const useFetchPromise = () => {
    return React.useCallback(
      (link, type, data) =>
        new Promise((resolve, reject) => {
          fetchData.muiFetchFormData(link, type, data).then((response) => {
            if (response.status == 200) {
              resolve(response)
            } else {
              reject(new Error("Error when saving"))
            }
          })
        })
    )
  }
export default function JournalItem(props) {
    const columns = [
        {field: "date",
         headerName: "Date",
         renderCell: ((params) => {
            var id = params.row.saleId
            if (id.length > 0) {
                return (
                    <a target="_blank" href={"https://inventory.dearsystems.com/Sale#"+id}>{params.row.date} </a>
                )
            } else {
            return (
                <div>{params.row.date} </div>
            )
            }
         })
        }, 
        { field: "total",
            headerName: "Total"
        },
        { field: "fees",
            headerName: "Fees"
        }, 
        { field: "resolve",
            headerName: "Resolved",
            renderCell: ((params) => {
              if (params.row.resolved) {
                return <div>Yes</div>
              }
                return (
                    <Button disabled={params.row.resolve} onClick={() => props.resolve(params)}>Resolve</Button>
                )
            })
        }
    ]

    return (
        <Item sx={{gridRow: '1', height: 1}}>
            <Box sx={{ color: 'text.primary', fontSize: 20, fontWeight: 'medium', borderBottom: 1 }}>
                {props.title}
            </Box>
            <DataGrid
                sx={{
                    height: .95
                }}
                rowHeight={35}
                columns={columns}
                rows={props.rows}
            />
        </Item>
    )   
}
