import { DashboardLayout } from '../Components/DashboardComponents/DashboardLayout';
import { Box, Button, Container, Typography } from '@mui/material';
import CardsHome from '../Dash/SinglesPage/CardsHome';
// here
const CardsPage = (props) => (
    <Box
        sx={{
            width: '100%',
            height: '100%',
            padding: '5px'
        }}
        >
        <CardsHome {...props}/>

    </Box>
  );
  
  
  export default CardsPage;
  